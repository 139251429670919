import axios from "axios";
import config from '../utils/consts.config.json';

const login = (username, password) => {
    return axios
        .post(config.API_URL + "auth/login", {
            "type": "ldap",
            username,
            password,
        })
        .then((response) => {
            if (response.data) {
                localStorage.setItem("token", JSON.stringify(response.data.token));
                localStorage.setItem("user", JSON.stringify(response.data.user));
                localStorage.setItem("permission", JSON.stringify(response.data.permission));
            }
            return response.data;
        });
};
const logout = () => {
    localStorage.removeItem("token");
};
const auth = {
    login,
    logout,
}
export default auth;
