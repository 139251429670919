/*
*
* DATA ANALYSIS COMPONENT ARE STATIC AND NOT COMPLETED YET
* TO_DO_: Get dynamic data from the backend REST API
*
*/
import Chart from 'chart.js/auto'; //required for chart rendering

import {Line} from 'react-chartjs-2';
import TopFilter from "components/DataAnalysis/TopFilter";
import {Col, Row, Card, Form, Spin, Button, Select, Modal, Input} from "antd";
import LeftFilter from "components/DataAnalysis/LeftFilter";
import BottomFilter from "components/DataAnalysis/BottomFilter";
import LevelsFilter from "components/DataAnalysis/LevelsFilter";
import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {FILTER_REF} from "utils/consts";
import {deleteFilter, getAllFilter, patchFilter, postFilter} from "redux/actions/DataAnalysis.action";
import {swalDelete} from "utils/swal";
import {DeleteOutlined} from "@ant-design/icons";
import moment from "moment";
import {Trans} from "react-i18next";

const {Option} = Select;

const Distribution = () => {
    const [form] = Form.useForm();
    const [saveAsForm] = Form.useForm();
    const [modalSaveAsVisible, setModalSaveAsVisible] = useState(false);
    const [chart, setChart] = useState({});
    const [btnSaveDisabled, setBtnSaveDisabled] = useState(!form.getFieldValue('filter_name'));
    const [loading, setLoading] = useState(true);
    const [selectedFilter, setSelectedFilter] = useState();
    const dispatch = useDispatch();
    const filters = useSelector(state => state.dataAnalysis);
    const [historicalValue, setHistoricalValue] = useState(false);
    const filterRef = FILTER_REF.DISTRIBUTION;

    useEffect(() => {
        // GET Filter list
        dispatch(getAllFilter(filterRef));
        renderFilterOptions();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        // GET Chart data
        async function getChartData() {
            setChart({
                    labels: [
                        'LG01-Chiang Kai-Shek Memorial Hall Station',
                        'LG02-Taipei Botanical Garden Station',
                        'LG03-Xiaan Station',
                        'LG04-Jiala Station',
                        'LG05-Yonghe Station',
                        'LG06-Zhonghe Station',
                        'LG07-Shuang Ho Hospital Station',
                        'LG08-Zhonghe Senior High School Station',
                        'Depot-Jincheng Depot',
                        'LG08A-Juguang Station',
                    ],
                    datasets: [
                        {
                            label: 'Data 1',
                            fill: false,
                            lineTension: 0.5,
                            backgroundColor: 'rgb(222, 66, 91)',
                            borderWidth: 0,
                            data: [65, 59, 80, 81, 56, 11, 12, 60, 58, 32]
                        },
                        {
                            label: 'Data 2',
                            fill: false,
                            lineTension: 0.5,
                            backgroundColor: 'rgb(66,134,222)',
                            borderWidth: 0,
                            data: [60, 54, 75, 76, 51, 6, 7, 22, 46, 23]
                        },
                        {
                            label: 'Data 3',
                            fill: false,
                            lineTension: 0.5,
                            backgroundColor: 'rgb(160,66,222)',
                            borderWidth: 0,
                            data: [55, 50, 70, 71, 45, 1, 2, 6, 59, 27]
                        },
                        {
                            label: 'Data 4',
                            fill: false,
                            lineTension: 0.5,
                            backgroundColor: 'rgb(222,178,66)',
                            borderWidth: 0,
                            data: [45, 52, 63, 61, 40, 21, 32, 89, 26, 45]
                        },
                        {
                            label: 'Data 5',
                            fill: false,
                            lineTension: 0.5,
                            backgroundColor: 'rgb(123,222,66)',
                            borderWidth: 0,
                            data: [40, 44, 55, 55, 30, 29, 39, 36, 89, 55]
                        }
                    ]
                }
            );
        }

        // TO#DO: GET chart value from API with filter query params
        setTimeout(() => {getChartData().then(setLoading(false))}, 2000);
    }, []);

    const showModalSaveAs = () => {
        setModalSaveAsVisible(true);
    }

    function handleDeleteFilter(event, id) {
        event.stopPropagation();
        swalDelete("Do you want to delete this filter ?").fire({})
            .then((result) => {
                if (result.isConfirmed) {
                    setSelectedFilter(null);
                    form.resetFields();
                    dispatch(deleteFilter(id, filterRef));
                }
            })

    }

    const renderFilterOptions = () => {
        return filters.listFilter.map((filter) => {
                // Convert filter string data to object
                const filterData = JSON.parse(filter.filterData);
                if (filterData.filter_name)
                    return (<Option key={filter.id} value={filterData.id}>
                        <Row>
                            <Col xs={21} sm={21} md={21} lg={21} xl={21}>
                                {filterData.filter_name}
                            </Col>
                            <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                <DeleteOutlined className="deleted-filter-icon"
                                                onClick={(event) => handleDeleteFilter(event, filter.id)}/>
                            </Col>
                        </Row>
                    </Option>);
                else return <></>
            }
        );
    }

    const closeModalSaveAs = () => {
        setModalSaveAsVisible(false);
        saveAsForm.resetFields();
    }

    function setSelectedFilterData(filterId) {
        form.resetFields();
        const selectedFilter = filters.listFilter.find(filter => filter.id.toString() === filterId);
        const filterData = JSON.parse(selectedFilter.filterData);

        // convert strings dates into moment
        for (const [key, value] of Object.entries(filterData)) {
            const timeToBeConverted = [
                'short_time_frame_start',
                'short_time_frame_end',
                'start_time_frame',
                'end_time_frame',
            ];
            if (key.includes('date') || timeToBeConverted.includes(`${key}`)) {
                filterData[`${key}`] = moment(value);
            }
        }
        form.setFieldsValue(filterData);
        setHistoricalValue(form.getFieldValue('historical_values'));
    }

    function handleSelectFilter(selectedFilter) {
        // set filter data into form and get new Chart values
        setSelectedFilter(selectedFilter);
        if (selectedFilter) {
            setBtnSaveDisabled(false);
            setSelectedFilterData(selectedFilter);
        } else {
            setBtnSaveDisabled(true);
            form.resetFields();
        }
        // TO#DO: get new chart data with new filter properties
        // getChartData();
    }

    function handleResetFilter() {
        setBtnSaveDisabled(true);
        form.resetFields();
    }

    function getCurrentUser() {
        return JSON.parse(localStorage.getItem('user'));
    }

    const onSubmitFormSaveAs = useCallback(() => {
        // POST filter form and sync filter list
        const data = {};
        form.setFieldsValue({
            filter_name: saveAsForm.getFieldValue('filter_name')
        });
        const currentUser = getCurrentUser();
        data.userName = currentUser.username;
        data.filterData = JSON.stringify(form.getFieldsValue());
        data.filterRef = filterRef;
        dispatch(postFilter(data)).then((res) => setSelectedFilter(res.payload.success.id));
        setBtnSaveDisabled(false);
        closeModalSaveAs();
        // eslint-disable-next-line
    },[]);

    function handleSaveFilter() {
        // PATCH filter form
        const data = {};
        const currentUser = getCurrentUser();
        data.userName = currentUser.username;
        data.filterData = JSON.stringify(form.getFieldsValue());
        console.log(form.getFieldValue('filter_name'))
        dispatch(patchFilter(selectedFilter, data, filterRef));
    }

    return (
        <div className='measurement-data'>

            {/* save new filter modal */}
            <Modal
                open={modalSaveAsVisible}
                title={<Trans i18nKey="dataAnalysis.saveAsModalTitle"/>}
                okText="Save"
                onOk={saveAsForm.submit}
                onCancel={closeModalSaveAs}>

                <Form
                    form={saveAsForm}
                    onFinish={onSubmitFormSaveAs}
                    labelCol={{
                        xs: {span: 8},
                        sm: {span: 8},
                        md: {span: 8},
                        lg: {span: 8},
                        xl: {span: 8},
                    }}
                    wrapperCol={{
                        xs: {span: 16},
                        sm: {span: 16},
                        md: {span: 16},
                        lg: {span: 16},
                        xl: {span: 16},
                    }}>
                    <Form.Item
                        name='filter_name'
                        label='Filter name'
                        labelAlign="left"
                        rules={[{required: true, message: 'Please input the filter name!'}]}
                    >
                        <Input size='small'/>
                    </Form.Item>
                </Form>
            </Modal>

            {/* Data Analysis - Distribution html */}
            <h1>Data Analysis - Distribution</h1>
            <div className='measurement-data'>
                <Form
                    labelCol={{
                        xs: {span: 24},
                        sm: {span: 24},
                        md: {span: 24},
                        lg: {span: 24},
                        xl: {span: 24},
                    }}
                    wrapperCol={{
                        xs: {span: 24},
                        sm: {span: 24},
                        md: {span: 24},
                        lg: {span: 24},
                        xl: {span: 24},
                    }}
                    form={form}
                >
                    <Row>
                        <Col xs={24} sm={24} md={4} lg={4} xl={4}>

                            <Form.Item
                                label="Filter"
                                labelCol={{span: 24}}
                                wrapperCol={{span: 22}}
                                name={'filter_name'}
                            >
                                <Select allowClear size='small' onChange={(value) => handleSelectFilter(value)}>
                                    {renderFilterOptions()}
                                </Select>
                            </Form.Item>

                        </Col>

                        <Col xs={24} sm={24} md={6} lg={6} xl={6} className="top-filter-container-col">
                            <Row justify="space-around" style={{marginTop: "15%"}}>
                                <Col>
                                    <Button onClick={() => handleResetFilter()} type={'danger'} size='small'>
                                        Reset
                                    </Button>
                                </Col>
                                <Col>
                                    <Button disabled={btnSaveDisabled}
                                            onClick={() => handleSaveFilter()}
                                            size='small' type='primary'>
                                        Save
                                    </Button>
                                </Col>
                                <Col>
                                    <Button onClick={() => showModalSaveAs()} size='small' type='primary'>
                                        Save as ..
                                    </Button>
                                </Col>
                                <Col>
                                    <Button size='small' type='ghost'>
                                        Apply
                                    </Button>
                                </Col>
                            </Row>

                        </Col>
                        <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                            <TopFilter/>
                        </Col>

                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={10} lg={4} xl={4}>
                            <LeftFilter historicalValue={historicalValue} setHistoricalValue={setHistoricalValue}/>
                        </Col>
                        <Col xs={24} sm={24} md={16} lg={16} xl={16}>

                            <Card>
                                <Row>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{height: '60vh'}}>
                                        {!loading ? <Line

                                            data={chart}
                                            options={{
                                                maintainAspectRatio: false,
                                                scales: {
                                                    x: {
                                                        offset: false,
                                                        title: 'station'
                                                    }
                                                },
                                                plugins: {
                                                    legend: {
                                                        display: true,
                                                        position: "bottom"
                                                    },

                                                },

                                            }}
                                        /> : <Spin className="spin-position" size="large"/>}
                                    </Col>
                                </Row>
                            </Card>

                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <BottomFilter/>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                            <LevelsFilter/>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    );
};

export default Distribution;
