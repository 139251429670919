import Swal from 'sweetalert2';

export function swalDelete(message) {
    return Swal.mixin({
        icon: 'warning',
        text: message,
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it !',
        cancelButtonText: 'No, cancel !',
        customClass: {
            confirmButton: 'ant-btn ant-btn-default ant-btn-dangerous',
            cancelButton: 'ant-btn ant-btn-default'
        },
        buttonsStyling: false

    });
}

export function swalSuccess(message) {
    return Swal.mixin({
        icon: 'success',
        text: message,
        customClass: {
            confirmButton: 'ant-btn ant-btn-primary swal2-btn-ok',
        },
        buttonsStyling: false

    });
}

export function swalError(message) {
    return Swal.mixin({
        icon: 'error',
        text: message,
        customClass: {
            confirmButton: 'ant-btn ant-btn-primary swal2-btn-ok',
        },
        buttonsStyling: false

    });
}