import {Card, Col, Typography, Row, Tag} from "antd";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import {getSoftwareInfo} from "redux/actions/SoftwareInfo.action";
import {Trans} from "react-i18next";

const {Text} = Typography;

const SoftwareInfo = () => {

    /**
     * Initial variable
     */
    const dispatch = useDispatch();
    const {softwareInfo} = useSelector(state => state.softwareInfo);
    /**
     * Functions
     */
    useEffect(() => {
            const initPage = () => {
                dispatch(getSoftwareInfo());
            };
            initPage();
        },
        // eslint-disable-next-line
        []
    );
    /**
     * DOM of this component
     */
    return (
        <div className="software">
            <h1><Trans i18nKey="setup.software.title"/></h1>
            <Card title={<Trans i18nKey="setup.software.License"/>}>
                <Row className="software-form">
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} className="ant-col-padded">
                        <Row className='software-form-row'>
                            <Col xs={11} sm={11} md={10} lg={10} xl={10}>
                                <label><Trans i18nKey="setup.software.LizenzNO"/> :</label>
                            </Col>
                            <Col>
                                <Text>{softwareInfo.LizenzNO}</Text>
                            </Col>
                        </Row>

                        <Row className='software-form-row'>
                            <Col xs={11} sm={11} md={10} lg={10} xl={10}>
                                <label><Trans i18nKey="setup.software.Lizenz"/> :</label>
                            </Col>
                            <Col>
                                <Text>{softwareInfo.Lizenz}</Text>
                            </Col>
                        </Row>

                        <Row className='software-form-row'>
                            <Col xs={11} sm={11} md={10} lg={10} xl={10}>
                                <label><Trans i18nKey="setup.software.Device"/> :</label>
                            </Col>
                            <Col>
                                <Tag color="default">{softwareInfo.Device}</Tag>
                            </Col>
                        </Row>

                        <Row className='software-form-row'>
                            <Col xs={11} sm={11} md={10} lg={10} xl={10}>
                                <label><Trans i18nKey="setup.software.User"/> :</label>
                            </Col>
                            <Col>
                                <Tag color="default">{softwareInfo.User}</Tag>
                            </Col>
                        </Row>

                        <Row className='software-form-row'>
                            <Col xs={11} sm={11} md={10} lg={10} xl={10}>
                                <label><Trans i18nKey="setup.software.Version"/> :</label>
                            </Col>
                            <Col>
                                <Tag color="default">{softwareInfo.Version}</Tag>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
        </div>
    );
};
export default SoftwareInfo;
