import { Col, Row, Card,Form, Input, Button, Radio} from "antd";
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {getSetupUser, updateSetupUser} from 'redux/actions/SetupUser.action';
import { Trans } from "react-i18next";

const SetupUser = () => {
    const dispatch = useDispatch();
    // eslint-disable-next-line
    const { setupUser } = useSelector(state => state.setupUser);
    const [form] = Form.useForm();

// eslint-disable-next-line
    useEffect(() => {
        const initPage = () => {
            dispatch(getSetupUser());
        };
        initPage();
        // eslint-disable-next-line
    },[]);
    useEffect(() => {
        if (setupUser){
            form.setFieldsValue(setupUser);
        }
        // eslint-disable-next-line
    },[setupUser]);
    const onFinishForm_SetupUser = () => {
        dispatch(updateSetupUser(form.getFieldValue()));
    };

    return (
        <div className="setup-user">
            <h1>Setup User</h1>
            <Card title='User administration'>
                <Row className="setup-user-form">
                    <Col xs={20} sm={20} md={14} lg={14} xl={14} className="ant-col-padded">

                        <Form
                            labelCol={{
                                xs: { span: 8 },
                                sm: { span: 8 },
                                md: { span: 8 },
                                lg: { span: 8 },
                                xl: { span: 8 },
                            }}
                            wrapperCol={{
                                xs: { span: 12 },
                                sm: { span: 12 },
                                md: { span: 12 },
                                lg: { span: 12 },
                                xl: { span: 12 },
                            }}
                            form={form}
                            onFinish={() => onFinishForm_SetupUser()}
                            initialValues={setupUser}>
                            <Form.Item
                                label="System" labelAlign="left">
                                <Radio.Group>
                                    <Radio.Button checked={true} disabled={true} value="type 2">LDAP</Radio.Button>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item
                                label="Domain"
                                labelAlign="left"
                                name="url"
                                rules={[{ required: true,  whitespace:true,message: 'Please input your domain!' }]}>
                                <Input placeholder="Domain" />
                            </Form.Item>

                            <Form.Item
                                label="Distinguished name (dn)"
                                labelAlign="left"
                                name="dn"
                                rules={[{ required: true,  whitespace:true,message: 'Please input distinguished name!' }]}>
                                <Input placeholder="Distinguished name (dn)" />
                            </Form.Item>

                            <Form.Item
                                label="Password: "
                                labelAlign="left"
                                name="password"
                                rules={[{ required: true,  whitespace:true, message: 'Please input your password!' }]}>
                                <Input.Password placeholder="Password" />
                            </Form.Item>

                            <Form.Item
                                label="Base distinguished name (dc)"
                                labelAlign="left"
                                name="baseDN"
                                rules={[{ required: true,  whitespace:true,message: 'Please input base distinguished name!' }]}>
                                <Input placeholder="Base Distinguished name (dc)" />
                            </Form.Item>

                            <Form.Item
                                label="Base group (ou)"
                                labelAlign="left"
                                name="baseDNGroup"
                                rules={[{ required: true,  whitespace:true, message: 'Please input base group!' }]}>
                                <Input placeholder="Base group (ou)" />
                            </Form.Item>

                            <Form.Item>
                                <Button htmlType="submit" type="primary">
                                    <Trans i18nKey="button.applyAndSave" />
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Card>
        </div>
    );
}
export default SetupUser;
