// LogReport const
export const MEASURING_POINT_GET_LIST = 'MEASURING_POINT_GET_LIST';
export const MEASURING_POINT_POST_SUCCESS = 'LOG_REPORT_POST_SUCCESS';
export const MEASURING_POINT_POST_FAILURE = 'LOG_REPORT_POST_FAILURE';
export const MEASURING_POINT_GET_REFERENCE_SCMS = 'MEASURING_POINT_GET_REFERENCE_SCMS';
export const MEASURING_POINT_GET_REFERENCE_PRAS = 'MEASURING_POINT_GET_REFERENCE_PRAS';
export const MEASURING_POINT_GET_READING = 'MEASURING_POINT_GET_READING';
export const MEASURING_POINT_GET_THRESHOLD = 'MEASURING_POINT_GET_THRESHOLD';
export const MEASURING_POINT_GET_OPERATING_LOG = 'MEASURING_POINT_GET_OPERATING_LOG';
export const MEASURING_POINT_DELETE_SUCCESS = 'MEASURING_POINT_DELETE_SUCCESS';
export const MEASURING_POINT_DELETE_FAILURE = 'MEASURING_POINT_POST_FAILURE';
export const MEASURING_POINT_GET_BY_ID = 'MEASURING_POINT_GET_BY_ID';
export const MEASURING_POINT_EDIT_SUCCESS = 'MEASURING_POINT_EDIT_SUCCESS';
export const MEASURING_POINT_EDIT_FAILURE = 'MEASURING_POINT_EDIT_FAILURE';
export const MEASURING_POINT_GET_MINIMAL_LIST = 'MEASURING_POINT_GET_MINIMAL_LIST';
export const MEASURING_POINT_GET_RECTIFIERS_SUBSTATIONS_LIST = 'MEASURING_POINT_GET_RECTIFIERS_SUBSTATIONS_LIST';
export const MEASURING_POINT_GET_DEVICE_TYPES = "MEASURING_POINT_GET_DEVICE_TYPES";
export const MEASURING_POINT_GET_DEVICE_PROTOCOLS = "MEASURING_POINT_GET_DEVICE_PROTOCOLS";
export const MEASURING_POINT_GET_DEVICE_BY_NAME = "MEASURING_POINT_GET_DEVICE_BY_NAME";
export const MEASURING_POINT_GET_PROTOCOL_BY_NAME= "MEASURING_POINT_GET_PROTOCOL_BY_NAME";
