import {REQUEST} from "redux/constants/ActionTypes";
import dataAnalysisService from "services/DataAnalysis.service";
import {
    FILTER_POST_FAILURE,
    FILTER_POST_SUCCESS,
    RECEIVE_FILTER,
    FILTER_PATCH_SUCCESS,
    FILTER_PATCH_FAILURE,
    FILTER_DELETE_SUCCESS,
    FILTER_DELETE_FAILURE,
    GET_LINES_FAILURE,
    GET_LINES_SUCCESS,
    GET_STATIONS_FAILURE,
    GET_STATIONS_SUCCESS,
    GET_RAIL_SECTION_FAILURE,
    GET_RAIL_SECTION_SUCCESS,
    GET_MEASUREMENT_POINTS_SUCCESS,
    GET_MEASUREMENT_POINTS_FAILURE,
} from "../constants/DataAnalysisType";
import {swalError, swalSuccess} from "utils/swal";

/**
 * Returns created Filter.
 *
 * @param {object} data The data of filter to create.
 * @return {json} created filter.
 */
export const postFilter = data => dispatch => {
    return dataAnalysisService.addFilterService(data)
        .then((res) => dispatch(filterPostSuccess(res), dispatch(getAllFilter(data.filterRef))))
        .catch((error) => dispatch(filterPostFailure(error)));
}

/**
 * Returns created Filter success - Redux Action.
 *
 * @param {boolean} success
 * @return {json} created filter.
 */
export const filterPostSuccess = (success) => {
    swalSuccess('The filter is successfully created').fire({});
        return {
            type: FILTER_POST_SUCCESS,
            payload: {success}
        };}

/**
 * Returns created Filter error - Redux Action.
 *
 * @param {boolean} error
 * @return {json} created filter.
 */
export const filterPostFailure = (error) => ({
    type: FILTER_POST_FAILURE,
    payload: {error}
});

export const request = () => ({
    type: REQUEST
});

/**
 * Returns All Filters by page reference.
 *
 * @param {string} filterRef The reference of filters.
 * @return {json}  array of Filters.
 */
export const getAllFilter = (filterRef) => dispatch => {
    dispatch(request());
    return dataAnalysisService.getAllFilterService(filterRef)
        .then((json) => dispatch(receiveFilter(json)));
}

/**
 * Returns All Filters by page reference success - Redux Action..
 *
 * @param {json} json
 * @return {json}  array of Filters.
 */
export const receiveFilter = (json) => ({
    type: RECEIVE_FILTER,
    payload: {data: json}
});


/**
 * edit filter by id
 *
 * @param {number} id Id of filter to patch
 * @param {json} data filter data
 * @param {string} filterRef filter data
 * @return {json}  array of Lines.
 */
export const patchFilter = (id, data, filterRef) => dispatch => {
    return dataAnalysisService.patchFilterService(id, data)
        .then(() => dispatch(patchFilterSuccess(true), dispatch(getAllFilter(filterRef))))
        .catch((error) => dispatch(patchFilterFailure(error)))
}

/**
 * edit filter by id success - Redux Action.
 *
 * @param {boolean} success Id of filter to patch
 * @return {json}  array of Lines.
 */
export const patchFilterSuccess = (success) => {
    // eslint-disable-next-line
    swalSuccess('The filter is successfully edited').fire({});
        return  {
            type: FILTER_PATCH_SUCCESS,
            payload: {success}
        };}

/**
 * edit filter by id error - Redux Action.
 *
 * @param {boolean} error Id of filter to patch
 * @return {json}  array of Lines.
 */
export const patchFilterFailure = (error) => {
    // eslint-disable-next-line
    swalError('An error occurred while saving the filter').fire({});
        return {
            type: FILTER_PATCH_FAILURE,
            payload: {error}
        };}

/**
 * Delete filter by name
 *
 * @param {number} id Id of filter to delete
 * @param {string} filterRef
 */
export const deleteFilter = (id, filterRef) => dispatch => {
    dispatch(request());
    return dataAnalysisService.deleteFilterService(id)
        .then(() => dispatch(filterDeleteSuccess(true), dispatch(getAllFilter(filterRef))))
        .catch((error) => dispatch(filterDeleteFailure(error)));
}

/**
 * Delete filter by name success - Redux Action.
 *
 * @param {boolean} success
 */
export const filterDeleteSuccess = (success) => {
    // eslint-disable-next-line
    swalSuccess('The filter is successfully deleted').fire({});
        return {
            type: FILTER_DELETE_SUCCESS,
            payload: {success}
        };}

/**
 * Delete filter by name error - Redux Action.
 *
 * @param {boolean} error
 */
export const filterDeleteFailure = (error) => {
    // eslint-disable-next-line
    swalError('An error occured while deleting the filter').fire({});
    return {
        type: FILTER_DELETE_FAILURE,
        payload: {error}
    };}

/**
 * Returns All Lines.
 *
 * @return {json}  array of Lines.
 */
export const getAllLines = () => dispatch => {
    dispatch(request());
    return dataAnalysisService.getAllLinesService()
        .then((json) => dispatch(receiveLineSuccess(json)))
        .catch((error) => dispatch(receiveLineFail(error)));
}

/**
 * Returns All Lines success - Redux Action.
 *
 * @param {json} json
 */
export const receiveLineSuccess = (json) => ({
    type: GET_LINES_SUCCESS,
    payload: {data: json}
});

/**
 * Returns All Lines error - Redux Action.
 *
 * @param {json} json
 */
export const receiveLineFail = (json) => ({
    type: GET_LINES_FAILURE,
    payload: {data: json}
});

/**
 * Returns All Stations.
 *
 * @return {json}  array of Stations.
 */
export const getAllStations = () => dispatch => {
    dispatch(request());
    return dataAnalysisService.getAllStationsService()
        .then((json) => dispatch(receiveStationsSuccess(json)))
        .catch((error) => dispatch(receiveStationsFail(error)));
}

/**
 * Returns All Stations success - Redux Action.
 *
 * @param {json}  json
 */
export const receiveStationsSuccess = (json) => ({
    type: GET_STATIONS_SUCCESS,
    payload: {data: json}
});

/**
 * Returns All Stations error - Redux Action.
 *
 * @param {json} json
 */
export const receiveStationsFail = (json) => ({
    type: GET_STATIONS_FAILURE,
    payload: {data: json}
});

/**
 * Returns All Rail Sections.
 *
 * @return {json}  array of Rail Sections.
 */
export const getAllRailSections = () => dispatch => {
    dispatch(request());
    return dataAnalysisService.getAllRailSectionsService()
        .then((json) => dispatch(receiveRailSectionsSuccess(json)))
        .catch((error) => dispatch(receiveRailSectionsFail(error)));
}

/**
 * Returns All Rail Sections success - Redux Action.
 *
 * @return {json}  array of Rail Sections.
 */
export const receiveRailSectionsSuccess = (json) => ({
    type: GET_RAIL_SECTION_SUCCESS,
    payload: {data: json}
});

/**
 * Returns All Rail Sections error - Redux Action.
 *
 * @return {json}
 */
export const receiveRailSectionsFail = (json) => ({
    type: GET_RAIL_SECTION_FAILURE,
    payload: {data: json}
});

/**
 * Returns All Measurement Points.
 *
 * @return {json}  array of Measurement Points.
 */
export const getAllMeasurementPoints = () => dispatch => {
    dispatch(request());
    return dataAnalysisService.getAllMeasurementPointsService()
        .then((json) => dispatch(receiveMeasurementPointsSuccess(json)))
        .catch((error) => dispatch(receiveMeasurementPointsFail(error)));
}

/**
 * Returns All Measurement Points success - Redux Action.
 *
 * @return {json}
 */
export const receiveMeasurementPointsSuccess = (json) => ({
    type: GET_MEASUREMENT_POINTS_SUCCESS,
    payload: {data: json}
});

/**
 * Returns All Measurement Points error - Redux Action.
 *
 * @return {json}
 */
export const receiveMeasurementPointsFail = (json) => ({
    type: GET_MEASUREMENT_POINTS_FAILURE,
    payload: {data: json}
});

