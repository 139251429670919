import React from "react";
import { Link } from "react-router-dom";

// Function to check if a route is present in the permissions list
function getRoute(permission, route) {
  return permission.find((r) => r === route);
}

// eslint-disable-next-line
export default {
  // Function to get the menu items based on user permissions
  getMenu: () => {
    let permission = [];
    // Retrieve permissions from local storage
    const storagePermission = localStorage.getItem("permission");
    if (storagePermission) {
      permission = JSON.parse(storagePermission);
    }
    const items = [];
    const allItems = [
      {
        key: "/overview-map",
        label: <Link to="/overview-map">Overview Map</Link>,
      },
      {
        key: "SCADA",
        label: "SCADA",
        children: [
          {
            key: "/scada/measuring-points",
            label: <Link to="/scada/measuring-points">Measuring Points</Link>,
          },
          /*{ key: 'vide', label: <Link to="/vide">Operation Log</Link> }*/
        ],
      },
      {
        key: "Data Analysis",
        label: "Data Analysis",
        children: [
          {
            key: "/analysis/measurement-data",
            label: (
              <Link to="/analysis/measurement-data">Measurement data</Link>
            ),
          },
          {
            key: "/analysis/scms",
            label: (
              <Link
                to="/analysis/scms"
                style={{ pointerEvents: "none", color: "grey" }}
              >
                SCMS
              </Link>
            ),
          },
          {
            key: "/analysis/pras",
            label: <Link to="/analysis/pras">PRAS</Link>,
          },
          {
            key: "/analysis/distribution",
            label: (
              <Link
                to="/analysis/distribution"
                style={{ pointerEvents: "none", color: "grey" }}
              >
                Distribution
              </Link>
            ),
          },
        ],
      },
      {
        key: "System",
        label: "System",
        children: [
          {
            key: "/system/log-report",
            label: <Link to="/system/log-report">Log Report</Link>,
          },
          {
            key: "Setup",
            label: "Setup",
            children: [
              { key: "/setup/user", label: <Link to="/setup/user">User</Link> },
              {
                key: "/setup/scada-infra",
                label: <Link to="/setup/scada-infra">SCADA-Infra</Link>,
              },
              {
                key: "/setup/scada-energy",
                label: <Link to="/setup/scada-energy">SCADA-Energy</Link>,
              },
              {
                key: "/software-info",
                label: <Link to="/software-info">Software</Link>,
              },
              {
                key: "/setup/workflow",
                label: <Link to="/setup/workflow">Workflow</Link>,
              },
            ],
          },
        ],
      },
    ];

    // Loop through all items and filter based on permissions
    for (let item of allItems) {
      if (item.children) {
        const itemTwo = [];
        for (let childrenTwo of item.children) {
          if (childrenTwo.children) {
            const itemThree = [];
            for (let childrenThree of childrenTwo.children) {
              const route = getRoute(permission, childrenThree.key);
              if (route) {
                itemThree.push(childrenThree);
              }
            }
            if (itemThree.length > 0) {
              itemTwo.push({
                key: item.key,
                label: item.label,
                children: itemThree,
              });
            }
          } else {
            const route = getRoute(permission, childrenTwo.key);
            if (route) {
              itemTwo.push(childrenTwo);
            }
          }
        }
        if (itemTwo.length > 0) {
          items.push({
            key: item.key,
            label: item.label,
            children: itemTwo,
          });
        }
      } else {
        const route = getRoute(permission, item.key);
        if (route) {
          items.push(item);
        }
      }
    }
    return items;
  },
};
