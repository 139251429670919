export const RECEIVE_SUBSTATION = 'RECEIVE_SUBSTATION';
export const SUBSTATION_POST_SUCCESS = 'SUBSTATION_POST_SUCCESS';
export const SUBSTATION_POST_FAILURE = 'SUBSTATION_POST_FAILURE';
export const RAIL_SECTION_POST_SUCCESS = 'RAIL_SECTION_POST_SUCCESS';
export const RAIL_SECTION_POST_FAILURE = 'RAIL_SECTION_POST_FAILURE';
export const SUBSTATION_EDIT_SUCCESS = 'SUBSTATION_EDIT_SUCCESS';
export const SUBSTATION_EDIT_FAILURE = 'SUBSTATION_EDIT_FAILURE';
export const RAIL_SECTION_EDIT_SUCCESS = 'RAIL_SECTION_EDIT_SUCCESS';
export const RAIL_SECTION_EDIT_FAILURE = 'RAIL_SECTION_EDIT_FAILURE';
export const SUBSTATION_DELETE_SUCCESS = 'SUBSTATION_DELETE_SUCCESS';
export const SUBSTATION_DELETE_FAILURE = 'SUBSTATION_DELETE_FAILURE';
export const RAIL_SECTION_DELETE_SUCCESS = 'RAIL_SECTION_DELETE_SUCCESS';
export const RAIL_SECTION_DELETE_FAILURE = 'RAIL_SECTION_DELETE_FAILURE';
export const RECEIVE_RAILS_SUBSTATION_ID = 'RECEIVE_RAILS_SUBSTATION_ID';
export const RECEIVE_MP_RAIL_ID = 'RECEIVE_MP_RAIL_ID';
export const RECEIVE_RAIL_SUCCESS = 'RECEIVE_RAIL_SUCCESS';
export const RECEIVE_RAIL_FAILURE = 'RECEIVE_RAIL_FAILURE';
export const RSS_DETAILS_FAILURE = 'RSS_DETAILS_FAILURE';
export const RSS_DETAILS_SUCCESS = 'RSS_DETAILS_SUCCESS';
export const PATCH_RS_VLD = 'PATCH_RS_VLD';
export const RAIL_DETAILS_SUCCESS = 'RAIL_DETAILS_SUCCESS';
export const RAIL_DETAILS_FAILURE = 'RAIL_DETAILS_FAILURE';
export const PATCH_RAIL_MP = 'PATCH_RAIL_MP';
