import {
    PUT_USER_SETUP_FAIL,
    GET_USER_SETUP_SUCCESS,
    PUT_USER_SETUP_SUCCESS,
    GET_USER_SETUP_FAIL,
} from '../constants/SetupUserType'; // Importing action type constants

// Initial state for the setupUser reducer
const initialState = {
    setupUser: null, // User setup data
    error: "" // Error message
};

// Reducer function for setupUser
// eslint-disable-next-line
export default function setupUser(state = initialState, action) {
    const {type, payload} = action; // Destructuring action object
    const handleSuccess=(state, payload) => ({
        ...state, // Copying the current state
        setupUser: payload.setupUser // Updating setupUser with the new data from the action payload

    })
    const handleFailure=(state, payload) => ({
        ...state, // Copying the current state
        error: payload.error // Updating error with the error message from the action payload
    })
    switch (type) {
        case GET_USER_SETUP_SUCCESS: { // Handling the success action of getting user setup data
            return handleSuccess(state,payload)
        }
        case GET_USER_SETUP_FAIL: { // Handling the fail action of getting user setup data
            return handleFailure(state,payload)
        }
        case PUT_USER_SETUP_SUCCESS: { // Handling the success action of updating user setup data
            return handleSuccess(state,payload)
        }
        case PUT_USER_SETUP_FAIL: { // Handling the fail action of updating user setup data
            return handleFailure(state,payload)
        }
        default:
            return state; // Returning the current state if action type doesn't match
    }
}
