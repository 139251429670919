import axios from "axios";
import config from "../utils/consts.config.json";
import authHeader from "./AuthHeader";

const API_URL = config.API_URL;

/**
 * ////////////////////////////////////////////////////////////
 *                  Substation Services
 * ///////////////////////////////////////////////////////////
 **/

/**
 * Returns List Of All Substations .
 *
 * @return {json}  array of Substations.
 */
const getAllSubstationsService = () => {
    return axios
        // .get(API_URL + "substations/?filter=%7B%22include%22%3A%5B%7B%22relation%22%3A%22railSections%22%2C%22scope%22%3A%7B%22include%22%3A%5B%7B%22relation%22%3A%22measuringPoint%22%2C%22scope%22%3A%7B%22include%22%3A%5B%7B%22relation%22%3A%22device%22%7D%2C%7B%22relation%22%3A%22station%22%7D%2C%7B%22relation%22%3A%22station%22%7D%5D%7D%7D%5D%7D%7D%5D%7D")
        .get(API_URL + "substations", {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * Returns List Of All Rail Sections.
 *
 * @return {json}  array of Rail Sections.
 */
const getAllRailsService = () => {
    return axios
        // .get(API_URL + "substations/?filter=%7B%22include%22%3A%5B%7B%22relation%22%3A%22railSections%22%2C%22scope%22%3A%7B%22include%22%3A%5B%7B%22relation%22%3A%22measuringPoint%22%2C%22scope%22%3A%7B%22include%22%3A%5B%7B%22relation%22%3A%22device%22%7D%2C%7B%22relation%22%3A%22station%22%7D%2C%7B%22relation%22%3A%22station%22%7D%5D%7D%7D%5D%7D%7D%5D%7D")
        .get(API_URL + "rail-sections", {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * Add New Substation.
 *
 * @param {string} data substation data.
 * @return {json}  added substation data.
 */
const addSubstationService = (data) => {
    return axios
        .post(API_URL + "substations", data, {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * Edit Substation
 * @param {number} id
 * @param {json} data
 * @return
 */
const editSubStationService = (id, data) => {
    return axios
        .put(API_URL + `substations/${id}`, data, {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * Delete association between substation and MP
 *
 * @param {number} id The substations id.
 * @return
 */
const deleteSubstationService = (id) => {
    return axios
        .delete(API_URL + `substations/${id}/measuring-point`)
        .then((response) => {
            if (response.data) {

                return response.data;
            }
            return response;
        });
};

/**
 * ////////////////////////////////////////////////////////////
 *                  Rail Section Services
 * ///////////////////////////////////////////////////////////
 **/

/**
 * Add New Rail Section.
 *
 * @param {json} data.
 * @return
 */
const addRailSectionService = (data) => {
    return axios
        .post(API_URL + "rail-sections", data)
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * Edit Rail Section by ID.
 *
 * @param {number} id id of The Rail Section.
 * @param {json} data
 * @return
 */
const editRailSectionService = (id, data) => {
    return axios
        .put(API_URL + `rail-sections/${id}`, data)
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};


/**
 * Delete relation between rail section and measuring point by ID rail-section.
 *
 * @param {number} id
 * @return {json}
 */
const deleteRailSectionService = (id) => {
    return axios
        .delete(API_URL + `rail-sections/${id}/measuring-points`, {headers: authHeader()})
        .then((response) => {
            if (response.data) {

                return response.data;
            }
            return response;
        });
};

/**
 * Delete rail section by ID
 *
 * @param {string} id
 * @return
 */
const deleteRailSectionEnergyService = (id) => {
    return axios
        .delete(API_URL + `rail-sections/${id}`, {headers: authHeader()})
        .then((response) => {
            if (response.data) {

                return response.data;
            }
            return response;
        });
};



/**
 * Returns list of All rail-sections with their relations .
 *
 * @return {array} Array of rail sections
 */
const getAllRailsSectionService = () => {
    return axios
        // .get(API_URL + "substations/?filter=%7B%22include%22%3A%5B%7B%22relation%22%3A%22railSections%22%2C%22scope%22%3A%7B%22include%22%3A%5B%7B%22relation%22%3A%22measuringPoint%22%2C%22scope%22%3A%7B%22include%22%3A%5B%7B%22relation%22%3A%22device%22%7D%2C%7B%22relation%22%3A%22station%22%7D%2C%7B%22relation%22%3A%22station%22%7D%5D%7D%7D%5D%7D%7D%5D%7D")
        // eslint-disable-next-line
        .get(API_URL + "rail-sections" + "?filter=%7B%0A%22include%22%3A%20%5B%0A%20%20%20%20%7B%0A%20%20%20%20%20%20%22relation%22%3A%20%22measuringPoints%22%20%20%20%20%20%20%20%20%0A%20%20%20%20%7D%2C%0A%20%20%20%20%7B%0A%20%20%20%20%20%20%22relation%22%3A%20%22stations%22%20%20%20%20%20%20%20%20%0A%20%20%20%20%7D%2C%0A%20%20%20%20%7B%0A%20%20%20%20%20%20%22relation%22%3A%20%22line%22%20%20%20%20%20%20%20%20%0A%20%20%20%20%7D%0A%20%20%5D%0A%7D",{headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * ////////////////////////////////////////////////////////////
 *                  Measuring points Services
 * ///////////////////////////////////////////////////////////
 **/

/**
 * Edit association between MP of type RSS and type VLD
 *
 * @param {number} id  ID of MP.
 * @param {json} data
 * @return
 */
const patchRectifierSubstationService = (id, data) => {
    return axios
        .patch(API_URL + `measuring-points/rss/${id}/vld`, data,{headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * Edit association between rail section and MP
 *
 * @param {number} id  ID of rail section.
 * @param {json} data
 * @return
 */
const editRailsMeasuringPointService = (id, data) => {
    return axios
        .patch(API_URL + `rail-sections/${id}/measuringPoint`, data, {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * Returns list of Rail sections by rail-section-id including their MP relation.
 *
 * @param {string} railId
 * @return {json}  array of Rail sections.
 */
const getRailsMeasuringPointsService = (railId) => {
    return axios
        .get(API_URL + `rail-sections/${railId}?filter=%7B%22include%22%3A%20%5B%0A%20%20%20%20%7B%0A%20%20%20%20%20%20%22relation%22%3A%20%22measuringPoints%22%20%20%20%20%20%20%20%20%0A%20%20%20%20%7D%0A%20%20%5D%0A%7D`,{headers: authHeader()})
        //.get(API_URL + `rail-sections/${railid}/measuring-points`)
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * Returns measuring point of by rail section_id
 * @param {number} railId
 * @return {json}  array of Rail sections.
 */
const getMpByRailIdService = (railId) => {
    return axios
        // .get(API_URL + "substations/?filter=%7B%22include%22%3A%5B%7B%22relation%22%3A%22railSections%22%2C%22scope%22%3A%7B%22include%22%3A%5B%7B%22relation%22%3A%22measuringPoint%22%2C%22scope%22%3A%7B%22include%22%3A%5B%7B%22relation%22%3A%22device%22%7D%2C%7B%22relation%22%3A%22station%22%7D%2C%7B%22relation%22%3A%22station%22%7D%5D%7D%7D%5D%7D%7D%5D%7D")
        .get(API_URL + `rail-sections/${railId}/measuring-points`,{headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

const scadaEnergy = {
    getAllSubstationsService,
    addSubstationService,
    addRailSectionService,
    editSubStationService,
    editRailSectionService,
    deleteSubstationService,
    deleteRailSectionService,
    getMpByRailIdService,
    deleteRailSectionEnergyService,
    getAllRailsService,
    getRailsMeasuringPointsService,
    editRailsMeasuringPointService,
    patchRectifierSubstationService,
    getAllRailsSectionService,
}
export default scadaEnergy;
