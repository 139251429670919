import { SET_MESSAGE, CLEAR_MESSAGE } from "../constants/AuthType"; // Importing action type constants for setting and clearing messages

const initialState = {}; // Initial state object

// Reducer function for authentication messages
// eslint-disable-next-line
export default function authMessage(state = initialState, action) {
    const { type, payload } = action; // Destructuring action object
    switch (type) {
        // Handling the action to set a message
        case SET_MESSAGE:
            return { message: payload }; // Setting the message in the state based on the payload
        // Handling the action to clear a message
        case CLEAR_MESSAGE:
            return { message: "" }; // Clearing the message in the state
        default:
            return state; // Returning the current state for unhandled actions
    }
}
