import { Form, Input, Button, Checkbox, Alert, Row, Col } from 'antd';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "redux/constants/Auth";
import { Navigate, useNavigate } from "react-router-dom";
import './login.scss';
import logo from 'assets/images/WITTlogogroß.png'; 
const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false); // State to manage loading state
    const { isLoggedIn } = useSelector(state => state.auth);
    const { message } = useSelector(state => state.authMessage);
    const dispatch = useDispatch();
    const [form] = Form.useForm(); // Form instance
    let navigate = useNavigate();

    // Handle username change
    const onChangeUsername = (e) => {
        const username = e.target.value;
        setUsername(username);
    };
    
    // Handle password change
    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    // Handle login form submission
    const handleLogin = (e) => {
        setLoading(true); // Set loading to true
        dispatch(login(username, password))
            .then(() => {
                navigate("/"); // Redirect to homepage on successful login
                setLoading(false); // Reset loading state
            })
            .catch(() => {
                setLoading(false); // Reset loading state
                form.resetFields(); // Reset form fields
            });
    };

    // Redirect if already logged in
    if (isLoggedIn) {
        return <Navigate to="/"/>;
    }

    return (
        <div className="login-page">
            <div className="login-box">
                <Form
                    name="login-form"
                    initialValues={{remember: true}}
                    onFinish={handleLogin} // Handle form submission
                >
                    <div>
                        <img className="logo-login" src={logo} alt='logo WiTT'/> {/* Logo */}
                        <p>Login </p>
                    </div>
                    <Form.Item
                        name="username"
                        rules={[{required: true,  whitespace:true, message: 'Please input your username!'}]}
                    >
                        <Input
                            onChange={onChangeUsername}
                            placeholder="Username"
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[{required: true, message: 'Please input your password!'}]}
                    >
                        <Input.Password
                            onChange={onChangePassword}
                            placeholder="Password"
                        />
                    </Form.Item>

                    <Form.Item name="remember" valuePropName="checked">
                        <Checkbox>Remember me</Checkbox>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            LOGIN
                        </Button>
                    </Form.Item>
                </Form>
            </div>
            {message && (
                <div className="alert-login">
                    <Row colon="true">
                        <Col span={8} push={8}>
                            <Alert
                                closable="true"
                                message={message}
                                type="error" showIcon
                            />
                        </Col>
                    </Row>
                </div>
            )}
        </div>
    );
};

export default Login;
