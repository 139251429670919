import { Button, Space, Table, Modal, Form, Input, Row, Col, Tooltip } from 'antd';
import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { swalDelete } from 'utils/swal';
import {
    deleteLine, deleteMultipleLines, editLine,
    getAllLine, getLinebyId, postsLine
} from 'redux/actions/SCADAInfra.action';
import { useDispatch, useSelector } from 'react-redux';

const LinesList = () => {
    const { TextArea } = Input;
    ///////////////////////////////////////////////////////////////////////
    // Line LIST TABLE
    ///////////////////////////////////////////////////////////////////////

    const [selectLine, setSelectLine] = useState();
    const [visibleModal_NewLine, setVisibleModal_NewLine] = useState(false);
    const [visibleModal_EditLine, setVisibleModal_EditLine] = useState(false);
    const [formAddLine] = Form.useForm();
    const [formEditLine] = Form.useForm();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAllLine());
        // eslint-disable-next-line
    }, []);
    //Lines DATA

    const lines = useSelector(state => state.scadaInfra.listLine);

    ///////////////////////////////////////////////////////////////////////
    //        LINE LIST COLUMN
    ///////////////////////////////////////////////////////////////////////

    const LineColumnsSection = [
        {
            title: <Trans i18nKey="setup.ScadaInfra.LineNumberColumn" />,
            dataIndex: 'number',
            width: 120,
        },
        {
            title: <Trans i18nKey="setup.ScadaInfra.LineNameColumn" />,
            dataIndex: 'name',
            width: 180,
        },
        {
            title: <Trans i18nKey="setup.ScadaInfra.LineNoteColumn" />,
            dataIndex: 'note',
            width: 180,
        },
        {
            title: <Trans i18nKey="setup.ScadaInfra.actionColumn" />,
            key: 'action',
            width: 100,
            render: (_, record) => {

                let disabled = false;
                if (record.railSections) {
                    // eslint-disable-next-line
                    record.railSections.map((rail) => {

                        if (rail.measuringPoints) {
                            disabled = true
                        }

                    })

                }
                return (
                    <Space size="middle">
                        <Button onClick={() => showModal_EditLine(record)} size={'small'}><Trans i18nKey="button.Edit" /></Button>
                        {disabled ?
                            <Tooltip overlay={<p>Can't Delete because some devices are associated <br />with some Rails of this line</p>} placement="top">
                                <Button disabled={disabled} size={'small'}><Trans i18nKey="button.del" /></Button>
                            </Tooltip>
                            : <Button disabled={disabled} onClick={() => (deleteLineAction(record))} size={'small'}><Trans i18nKey="button.del" /></Button>}
                    </Space>
                )

            },
        },
    ];

    ///////////////////////////////////////////////////////////////////////
    //           LINE LIST CHECKBOX
    ///////////////////////////////////////////////////////////////////////

    const rowSelectionSectionLine = {
        onChange: (selectedRowKeys, selectedRows) => {
            if (selectedRows.length > 0) {
                setSelectLine(selectedRows);
            }
            else {
                setSelectLine();
            }
        },
        getCheckboxProps: (record) => {
            let disabledcheckbox = false;
            if (record.railSections) {
                // eslint-disable-next-line
                record.railSections.map((rail) => {
                    if (rail.measuringPoints) {
                        disabledcheckbox = true
                    }

                })
                return {
                    disabled: disabledcheckbox,
                    // Column configuration not to be checked
                    name: record.name,
                }
            }


        },
    };

    ///////////////////////////////////////////////////////////////////////
    //           Line MODAL
    ///////////////////////////////////////////////////////////////////////
    const showModal_NewLine = () => {
        setVisibleModal_NewLine(true);
    };

    const handleCancelModal_NewLine = () => {
        setVisibleModal_NewLine(false);
        formAddLine.resetFields();
    };

    const onFinishForm_NewLine = () => {
        const data = formAddLine.getFieldsValue();
        dispatch(postsLine(data));
        setVisibleModal_NewLine(false);
        formAddLine.resetFields();
    }
    let lineDetail = useSelector(state => state.scadaInfra.lineById);
    useEffect(() => {
        formEditLine.setFieldsValue(lineDetail);
        // eslint-disable-next-line
    }, [lineDetail]);


    const showModal_EditLine = (data) => {
        dispatch(getLinebyId(data.id))
            .then(setVisibleModal_EditLine(true))

    };

    const handleCancelModal_EditLine = () => {
        formEditLine.resetFields();
        setVisibleModal_EditLine(false);
    };

    const onFinishForm_EditLine = (id) => {
        dispatch(editLine(formEditLine.getFieldValue().id, formEditLine.getFieldsValue()));
        setVisibleModal_EditLine(false);
        formEditLine.resetFields();
    }

    const deleteLineAction = (id) => {
        swalDelete("Are you sure you want to delete this Line ?").fire({})
            .then((result) => {
                if (result.isConfirmed) {
                    dispatch(deleteLine(id.id));
                }
            })
    }
    const deleteMultipleLinesAction = () => {

        if (selectLine) {
            let linesIds = selectLine.map((lines) => {
                return (lines.id);
            })
            let linesDeleteData = {
                "lines": linesIds
            }
            if (linesIds.length > 1) {
                swalDelete("Are you sure you want to delete these lines ?").fire({})
                    .then((result) => {
                        if (result.isConfirmed) {
                            dispatch(deleteMultipleLines(linesDeleteData));
                            setSelectLine();
                        }
                    })
            }
            else {
                swalDelete("Are you sure you want to delete this line ?").fire({})
                    .then((result) => {
                        if (result.isConfirmed) {
                            dispatch(deleteMultipleLines(linesDeleteData));
                            setSelectLine();
                        }
                    })
            }


        }


    }

    return (
        <div className='lines-list'>
            <h2>Lines List</h2>
            <Row className='lines-list-top-bar'>
                <Col>  <Button data-testid='button-new' onClick={() => (showModal_NewLine())}><Trans i18nKey="button.new" /></Button></Col>
                <Col>{selectLine ?
                    <Button data-testid={'button-delete'} onClick={() => (deleteMultipleLinesAction())}><Trans i18nKey="button.del" /></Button>
                    : <Button data-testid={'button-delete'} disabled={true}><Trans i18nKey="button.del" /></Button>}
                </Col>
            </Row>
            <Table
                rowSelection={{
                    type: 'checkbox',
                    ...rowSelectionSectionLine,
                }}
                bordered
                columns={LineColumnsSection}
                dataSource={lines}
                pagination={true}
                scroll={{ y: 500 }}
                className="mp-location-rails-table"
                rowKey="id"
                data-testid={'scadaInfraLieTable'}
            />

            {/************* Modal : New Line *************/}

            <Modal
                title="New Line"
                open={visibleModal_NewLine}
                onOk={formAddLine.submit}
                okText="Save"
                onCancel={handleCancelModal_NewLine}
                footer={[
                    <Button key="back" data-testid={'buttonCancel'}  onClick={handleCancelModal_NewLine}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={formAddLine.submit}>
                        Save
                    </Button>,
                ]}
            >
                <Form
                    labelCol={{
                        xs: { span: 24 },
                        sm: { span: 24 },
                        md: { span: 6 },
                        lg: { span: 6 },
                        xl: { span: 6 },
                    }}
                    wrapperCol={{
                        xs: { span: 24 },
                        sm: { span: 24 },
                        md: { span: 18 },
                        lg: { span: 18 },
                        xl: { span: 18 },
                    }}
                    layout="horizontal"
                    form={formAddLine}
                    labelAlign='left'
                    onFinish={() => onFinishForm_NewLine()}
                >
                    <Form.Item
                        name="name"
                        label="Line Name"
                        rules={[
                            {
                                required: true,
                                whitespace: true,
                                message: 'Please input the line name ',
                            },
                        ]}
                    >
                        <Input placeholder="" />
                    </Form.Item>

                    <Form.Item
                        name="number"
                        label="Line Number"
                        rules={[
                            {
                                required: true,
                                whitespace: true,
                                message: 'Please input the line number',
                            },
                        ]}
                    >
                        <Input placeholder="" />
                    </Form.Item>
                    <Form.Item
                        name="note"
                        label="Note"
                    >
                        <TextArea rows={4} />
                    </Form.Item>
                </Form>
            </Modal>

            {/************* Modal : Edit Line *************/}

            <Modal
                title="Edit Line"
                open={visibleModal_EditLine}
                onOk={formEditLine.submit}
                okText="Save"
                onCancel={handleCancelModal_EditLine}
            >
                <Form
                    labelCol={{
                        xs: { span: 24 },
                        sm: { span: 24 },
                        md: { span: 8 },
                        lg: { span: 8 },
                        xl: { span: 8 },
                    }}
                    wrapperCol={{
                        xs: { span: 24 },
                        sm: { span: 24 },
                        md: { span: 16 },
                        lg: { span: 16 },
                        xl: { span: 16 },
                    }}
                    layout="horizontal"
                    form={formEditLine}
                    labelAlign='left'
                    onFinish={() => onFinishForm_EditLine()}
                >
                    <Form.Item
                        name="name"
                        label="Line Name"
                        rules={[
                            {
                                required: true,
                                whitespace: true,
                                message: 'Please input the line name',
                            },
                        ]}
                    >
                        <Input placeholder="" />
                    </Form.Item>
                    <Form.Item
                        name="number"
                        label="Line Number"
                        rules={[
                            {
                                required: true,
                                whitespace: true,
                                message: 'Please input the line number',
                            },
                        ]}
                    >
                        <Input placeholder="" />
                    </Form.Item>
                    <Form.Item
                        name="note"
                        label="Note"
                    >
                        <TextArea rows={4} />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}

export default LinesList;
