import {
  RECEIVE_LINE,
  LINE_POST_SUCCESS,
  LINE_POST_FAILURE,
  RECEIVE_STATION,
  STATION_POST_SUCCESS,
  STATION_POST_FAILURE,
  LINE_DELETE_SUCCESS,
  LINE_DELETE_FAILURE,
  STATION_DELETE_SUCCESS,
  STATION_DELETE_FAILURE,
  RECEIVE_RAIL,
  RAIL_SECTION_POST_SUCCESS,
  RAIL_SECTION_POST_FAILURE,
  RECEIVE_LINE_BY_ID,
  RECEIVE_STATION_BY_ID,
  RECEIVE_RAIL_BY_ID,
  LINE_MULTIPLE_DELETE_SUCCESS,
  LINE_MULTIPLE_DELETE_FAILURE,
  RAIL_DELETE_SUCCESS,
  RAIL_DELETE_FAILURE,
  RAIL_MULTIPLE_DELETE_SUCCESS,
  RAIL_MULTIPLE_DELETE_FAILURE,
  STATION_MULTIPLE_DELETE_SUCCESS,
  STATION_MULTIPLE_DELETE_FAILURE,
} from "../constants/SCADAInfraType"; // Importing action type constants
import { REQUEST } from "redux/constants/ActionTypes"; // Importing additional action type constant

const initialState = {
  listLine: [], // List of lines
  listStation: [], // List of stations
  railSectionList: [], // List of rail sections
  lineById: {}, // Line data by ID
  stationById: {}, // Station data by ID
  railById: {}, // Rail data by ID
  success: false, // Success flag
  error: "", // Error message
};

// Reducer function for SCADA infrastructure
// eslint-disable-next-line
export default function scadaInfra(state = initialState, action) {
  const { type, payload } = action; // Destructuring action object
  const handleSuccess = (state, payload) => ({
    ...state,
    success: payload.success,
  });

  const handleFailure = (state, payload) => ({
    ...state,
    error: payload.error,
  });
  switch (type) {
    // Lines
    case RECEIVE_LINE: {
      return {
        ...state,
        listLine: payload.data, // Updating list of lines with data from the action payload
      };
    }
    case RECEIVE_LINE_BY_ID: {
      return {
        ...state,
        lineById: payload.data, // Updating line data by ID with data from the action payload
      };
    }
    case LINE_POST_SUCCESS: {
      return {
        ...state,
        success: payload.success, // Updating success flag with data from the action payload
      };
    }
    case LINE_POST_FAILURE: {
      return {
        ...state,
        error: payload.error, // Updating error message with data from the action payload
      };
    }
    // Handling line deletion actions
    case LINE_DELETE_SUCCESS:
    case LINE_MULTIPLE_DELETE_SUCCESS: {
      return handleSuccess(state,payload); // Updating success flag with data from the action payload
    }
    case LINE_DELETE_FAILURE:
    case LINE_MULTIPLE_DELETE_FAILURE: {
      return handleFailure(state, payload);// Updating error message with data from the action payload
    }
    // Stations
    case RECEIVE_STATION: {
      return {
        ...state,
        listStation: payload.data, // Updating list of stations with data from the action payload
      };
    }
    case RECEIVE_STATION_BY_ID: {
      return {
        ...state,
        stationById: payload.data, // Updating station data by ID with data from the action payload
      };
    }
    case STATION_POST_SUCCESS: {
      return handleSuccess(state,payload); // Updating success flag with data from the action payload

    }
    case STATION_POST_FAILURE: {
      return handleFailure(state, payload);// Updating error message with data from the action payload

    }
    // Handling station deletion actions
    case STATION_DELETE_SUCCESS:
    case STATION_MULTIPLE_DELETE_SUCCESS: {
      return handleSuccess(state,payload); // Updating success flag with data from the action payload

    }
    case STATION_DELETE_FAILURE:
    case STATION_MULTIPLE_DELETE_FAILURE: {
      return handleFailure(state, payload);// Updating error message with data from the action payload

    }
    // Rail sections
    case RECEIVE_RAIL: {
      return {
        ...state,
        railSectionList: payload.data, // Updating list of rail sections with data from the action payload
      };
    }
    case RECEIVE_RAIL_BY_ID: {
      return {
        ...state,
        railById: payload.data, // Updating rail data by ID with data from the action payload
      };
    }
    case RAIL_SECTION_POST_SUCCESS: {
      return handleSuccess(state,payload); // Updating success flag with data from the action payload

    }
    case RAIL_SECTION_POST_FAILURE: {
      return handleFailure(state, payload);// Updating error message with data from the action payload

    }
    // Handling rail section deletion actions
    case RAIL_DELETE_SUCCESS:
    case RAIL_MULTIPLE_DELETE_SUCCESS: {
      return handleSuccess(state,payload); // Updating success flag with data from the action payload

    }
    case RAIL_DELETE_FAILURE:
    case RAIL_MULTIPLE_DELETE_FAILURE: {
      return handleFailure(state, payload);// Updating error message with data from the action payload
    }
    case REQUEST: {
      return {
        ...state,
        loading: true, // Setting loading flag to true
      };
    }
    default:
      return state; // Returning the current state if action type doesn't match
  }
}
