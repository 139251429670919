/*
*
* DATA ANALYSIS COMPONENT ARE STATIC AND NOT COMPLETED YET
* TO_DO_: Get dynamic data from the backend REST API
*
*/
import {Line} from 'react-chartjs-2';
import TopFilter from "components/DataAnalysis/TopFilter";
import {Form, Col, Row, Card, Select, Button, Modal, Input, Spin} from "antd";
import LeftFilter from "components/DataAnalysis/LeftFilter";
import BottomFilter from "components/DataAnalysis/BottomFilter";
import React, {useCallback, useEffect, useState} from "react";
import {Trans} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getAllFilter, postFilter, patchFilter, deleteFilter} from "redux/actions/DataAnalysis.action"
import moment from "moment";
import {DeleteOutlined} from "@ant-design/icons";
import {swalDelete} from "utils/swal";
import {FILTER_REF} from "utils/consts";

const {Option} = Select;

const MeasurementData = () => {
    const [form] = Form.useForm();
    const [saveAsForm] = Form.useForm();
    const [modalSaveAsVisible, setModalSaveAsVisible] = useState(false);
    const [chart, setChart] = useState({});
    const [btnSaveDisabled, setBtnSaveDisabled] = useState(!form.getFieldValue('filter_name'));
    const [loading, setLoading] = useState(true);
    const [selectedFilter, setSelectedFilter] = useState();
    const dispatch = useDispatch();
    const filters = useSelector(state => state.dataAnalysis);
    const [historicalValue, setHistoricalValue] = useState(false);
    const filterRef = FILTER_REF.SCMS;

    useEffect(() => {
        // GET Filter list
        dispatch(getAllFilter(filterRef));
        renderFilterOptions();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {

        // GET Chart data
        async function getChartData() {
            setChart({
                labels: [
                    new Date().getTime(),
                    new Date().getTime() + 1,
                    new Date().getTime() + 2,
                    new Date().getTime() + 3,
                    new Date().getTime() + 4,
                ],
                datasets: [
                    {
                        label: 'Chiang Kai-Shek Memorial Hall Station',
                        borderColor: 'rgb(225,112,82)',
                        backgroundColor: 'rgb(225,112,82)',
                        borderWidth: 1,
                        data: [65, 50, 80, 81, 56]
                    },
                    {
                        label: 'Taipei Botanical Garden Station',
                        borderColor: 'rgb(160,66,222)',
                        backgroundColor: 'rgb(160,66,222)',
                        borderWidth: 1,
                        data: [9, 59, 55, 22, 1],

                    },
                    {
                        label: 'Xiaan Station',
                        borderColor: 'rgb(81,166,73)',
                        backgroundColor: 'rgb(81,166,73)',
                        borderWidth: 1,
                        scaleBeginAtZero: false,
                        data: [93, 21, 42, 56, 22]
                    },
                    {
                        label: 'Jiala Station',
                        borderColor: 'rgb(213,3,3)',
                        backgroundColor: 'rgb(213,3,3)',
                        borderWidth: 1,
                        data: [19, 9, 70, 4, 11]
                    },
                    {
                        label: 'Yonghe Station',
                        borderColor: 'rgb(4,79,146)',
                        backgroundColor: 'rgb(4,79,146)',
                        borderWidth: 1,
                        data: [26, 20, 50, 36, 82]
                    },
                    {
                        label: 'Zhonghe Station',
                        borderColor: 'rgb(224, 20, 95)',
                        backgroundColor: 'rgb(224, 20, 95)',
                        borderWidth: 1,
                        data: [9, 25, 50, 20, 67]
                    },
                    {
                        label: 'Shuang Ho Hospital Station',
                        borderColor: 'rgb(156, 96, 118)',
                        backgroundColor: 'rgb(156, 96, 118)',
                        borderWidth: 1,
                        data: [32, 30, 25, 88, 35]
                    },
                    {
                        label: 'Zhonghe Senior High School Station',
                        borderColor: 'rgb(62, 214, 141)',
                        backgroundColor: 'rgb(62, 214, 141)',
                        borderWidth: 1,
                        data: [42, 25, 36, 30, 25]
                    },
                    {
                        label: 'Jincheng Depot',
                        borderColor: 'rgb(181, 199, 190)',
                        backgroundColor: 'rgb(181, 199, 190)',
                        borderWidth: 1,
                        data: [24, 30, 56, 90, 31]
                    },
                    {
                        label: 'Juguang Station',
                        borderColor: 'rgb(111, 20, 201)',
                        backgroundColor: 'rgb(111, 20, 201)',
                        borderWidth: 1,
                        data: [44, 30, 36, 70, 34]
                    }
                ]
            });
        }

        // TO_DO: GET chart value from API with filter query params
        setTimeout(() => {
            getChartData().then(setLoading(false))
        }, 2000);
        // eslint-disable-next-line
    }, []);

    const showModalSaveAs = () => {
        setModalSaveAsVisible(true);
    }

    function handleDeleteFilter(event, id) {
        event.stopPropagation();
        swalDelete("Do you want to delete this filter ?").fire({})
            .then((result) => {
                if (result.isConfirmed) {
                    setSelectedFilter(null);
                    form.resetFields();
                    dispatch(deleteFilter(id, filterRef));
                }
            })

    }

    const renderFilterOptions = () => {
        return filters.listFilter.map((filter) => {
                // Convert filter string data to object
                const filterData = JSON.parse(filter.filterData);
                if (filterData.filter_name)
                    return (<Option key={filter.id} value={filterData.id}>
                        <Row>
                            <Col xs={21} sm={21} md={21} lg={21} xl={21}>
                                {filterData.filter_name}
                            </Col>
                            <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                <DeleteOutlined className="deleted-filter-icon"
                                                onClick={(event) => handleDeleteFilter(event, filter.id)}/>
                            </Col>
                        </Row>
                    </Option>);
                else return <></>
            }
        );
    }

    const closeModalSaveAs = () => {
        setModalSaveAsVisible(false);
        saveAsForm.resetFields();
    }

    function setSelectedFilterData(filterId) {
        form.resetFields();
        const selectedFilter = filters.listFilter.find(filter => filter.id.toString() === filterId);
        const filterData = JSON.parse(selectedFilter.filterData);

        // convert strings dates into moment
        for (const [key, value] of Object.entries(filterData)) {
            const timeToBeConverted = [
                'short_time_frame_start',
                'short_time_frame_end',
                'start_time_frame',
                'end_time_frame',
            ];
            if (key.includes('date') || timeToBeConverted.includes(`${key}`)) {
                filterData[`${key}`] = moment(value);
            }
        }
        form.setFieldsValue(filterData);
        setHistoricalValue(form.getFieldValue('historical_values'));
    }

    function handleSelectFilter(selectedFilter) {
        // set filter data into form and get new Chart values
        setSelectedFilter(selectedFilter);
        if (selectedFilter) {
            setBtnSaveDisabled(false);
            setSelectedFilterData(selectedFilter);
        } else {
            setBtnSaveDisabled(true);
            form.resetFields();
        }
        // TO_DO: get new chart data with new filter properties
        // getChartData();
    }

    function handleResetFilter() {
        setBtnSaveDisabled(true);
        form.resetFields();
    }

    function getCurrentUser() {
        return JSON.parse(localStorage.getItem('user'));
    }

    const onSubmitFormSaveAs = useCallback(() => {
        // POST filter form and sync filter list
        const data = {};
        form.setFieldsValue({
            filter_name: saveAsForm.getFieldValue('filter_name')
        });
        const currentUser = getCurrentUser();
        data.userName = currentUser.username;
        data.filterData = JSON.stringify(form.getFieldsValue());
        data.filterRef = filterRef;
        dispatch(postFilter(data)).then((res) => setSelectedFilter(res.payload.success.id));
        setBtnSaveDisabled(false);
        closeModalSaveAs();
        // eslint-disable-next-line
    }, []);

    function handleSaveFilter() {
        // PATCH filter form
        const data = {};
        const currentUser = getCurrentUser();
        data.userName = currentUser.username;
        data.filterData = JSON.stringify(form.getFieldsValue());
        console.log(form.getFieldValue('filter_name'))
        dispatch(patchFilter(selectedFilter, data, filterRef));
    }

    return (
        <div className='measurement-data'>
            <Form
                labelCol={{
                    xs: {span: 24},
                    sm: {span: 24},
                    md: {span: 24},
                    lg: {span: 24},
                    xl: {span: 24},
                }}
                wrapperCol={{
                    xs: {span: 24},
                    sm: {span: 24},
                    md: {span: 24},
                    lg: {span: 24},
                    xl: {span: 24},
                }}
                form={form}
            >
                <h1>Data Analysis - SCMS</h1>
                <Row>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4}>

                        <Form.Item
                            label="Filter"
                            labelCol={{span: 24}}
                            wrapperCol={{span: 22}}
                            name={'filter_name'}
                        >
                            <Select allowClear size='small' onChange={(value) => handleSelectFilter(value)}>
                                {renderFilterOptions()}
                            </Select>
                        </Form.Item>

                    </Col>

                    <Col xs={24} sm={24} md={6} lg={6} xl={6} className="top-filter-container-col">
                        <Row justify="space-around" style={{marginTop: "15%"}}>
                            <Col>
                                <Button onClick={() => handleResetFilter()} type={'danger'} size='small'>
                                    Reset
                                </Button>
                            </Col>
                            <Col>
                                <Button disabled={btnSaveDisabled}
                                        onClick={() => handleSaveFilter()}
                                        size='small' type='primary'>
                                    Save
                                </Button>
                            </Col>
                            <Col>
                                <Button onClick={() => showModalSaveAs()} size='small' type='primary'>
                                    Save as ..
                                </Button>
                            </Col>
                            <Col>
                                <Button size='small' type='ghost'>
                                    Apply
                                </Button>
                            </Col>
                        </Row>

                    </Col>
                    <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                        <TopFilter/>
                    </Col>


                </Row>
                <Row>
                    <Col xs={24} sm={24} md={10} lg={4} xl={4}>
                        <LeftFilter historicalValue={historicalValue} setHistoricalValue={setHistoricalValue}/>
                    </Col>
                    <Col xs={24} sm={24} md={14} lg={20} xl={20}>

                        <Card>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{height: '60vh'}}>
                                    {!loading ? <Line
                                        data={chart}
                                        options={{
                                            maintainAspectRatio: false,
                                            scales: {
                                                x: {
                                                    offset: false,
                                                }
                                            },
                                            plugins: {

                                                legend: {
                                                    display: true,
                                                    position: "bottom",

                                                },
                                            },
                                        }}
                                    /> : <Spin className="spin-position" size="large"/>}
                                </Col>
                            </Row>
                        </Card>

                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <BottomFilter/>
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </Form>

            {/* save new filter modal */}
            <Modal
                open={modalSaveAsVisible}
                title={<Trans i18nKey="dataAnalysis.saveAsModalTitle"/>}
                okText="Save"
                onOk={saveAsForm.submit}
                onCancel={closeModalSaveAs}>

                <Form
                    form={saveAsForm}
                    onFinish={onSubmitFormSaveAs}
                    labelCol={{
                        xs: {span: 8},
                        sm: {span: 8},
                        md: {span: 8},
                        lg: {span: 8},
                        xl: {span: 8},
                    }}
                    wrapperCol={{
                        xs: {span: 16},
                        sm: {span: 16},
                        md: {span: 16},
                        lg: {span: 16},
                        xl: {span: 16},
                    }}>
                    <Form.Item
                        name='filter_name'
                        label='Filter name'
                        labelAlign="left"
                        rules={[{required: true, message: 'Please input the filter name!'}]}
                    >
                        <Input size='small'/>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default MeasurementData;
