import axios from 'axios';
import {swalError} from "./swal";

// eslint-disable-next-line
export default {
    setupInterceptors: () => {
        // Add a response interceptor
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            //catches if the session ended!
            if (error.response.status === 401 || error.response.status === 403) {
                console.info("EXPIRED TOKEN!");
                swalError("Session has expired!").fire({}).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result) {
                        localStorage.clear();
                        window.location = '/login';
                    }
                });
            }
            return Promise.reject(error);
        });
    }
};
