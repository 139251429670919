import { Layout } from 'antd';
import MainMenu from "../Navigation/MainMenu";

const { Sider } = Layout;

function TopBar() {

    return (
        <Sider>
            <MainMenu />
        </Sider>
    );
}

export default TopBar;