import { Button, Space, Table, Modal, Form, Input, Select, Transfer, Row, Col, Tooltip } from 'antd';

import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { swalDelete } from 'utils/swal';
import {
    deleteMultipleRails,
    deleteRail,
    editRail,
    getAllLine,
    getAllRails,
    getAllStations,
    getRailbyId,
    postRailSection
} from 'redux/actions/SCADAInfra.action';
import { useDispatch, useSelector } from 'react-redux';

const RailSectionList = () => {
    const { TextArea } = Input;
    ///////////////////////////////////////////////////////////////////////
    // Rail  LIST TABLE
    ///////////////////////////////////////////////////////////////////////

    const [selectRail, setSelectRail] = useState();
    const [visibleModal_NewRailSection, setVisibleModal_NewRailSection] = useState(false);
    const [visibleModal_EditRail, setVisibleModal_EditRail] = useState(false);
    const [formAddRail] = Form.useForm();
    const [formEditRail] = Form.useForm();
    const dispatch = useDispatch();
    useEffect(() => {
        const initPage = () => {
            dispatch(getAllLine());
            dispatch(getAllStations());
            dispatch(getAllRails());
        };
        initPage();
        // eslint-disable-next-line
    }, []);
    //Lines DATA

    let lines = useSelector(state => state.scadaInfra.listLine);
    let stationList = useSelector(state => state.scadaInfra.listStation);
    let RailsList = useSelector(state => state.scadaInfra.railSectionList);
    let Linesoptions = lines.map((element) => {
        return {
            label: element.name,
            id: element.id,
            value: element.id,
        }
    })
    const [targetKeys, setTargetKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const onChange = (nextTargetKeys, direction, moveKeys) => {
        setTargetKeys(nextTargetKeys);
    };
    const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };

    const onScroll = (direction, e) => {
    };

    ///////////////////////////////////////////////////////////////////////
    //        LINE LIST COLUMN
    ///////////////////////////////////////////////////////////////////////

    const RailsColumnsSection = [
        {
            title: <Trans i18nKey="setup.ScadaInfra.LineNumberColumn" />,
            dataIndex: 'number',
            width: 120,
        },
        {
            title: <Trans i18nKey="setup.ScadaInfra.LineNameColumn" />,
            dataIndex: 'name',
            width: 180,
        },
        {
            title: <Trans i18nKey="setup.ScadaInfra.actionColumn" />,
            key: 'action',
            width: 100,
            render: (_, record) => {
                let disabled = false;

                // eslint-disable-next-line


                if (record.measuringPoints) {
                    disabled = true
                }
                return (<Space size="middle">
                    <Button onClick={() => showModal_EditRailSection(record)} size={'small'}><Trans i18nKey="button.Edit" /></Button>
                    {disabled ? <Tooltip overlay={<p>Can't Delete because some devices are associated <br />with this rail</p>} placement="top"><Button disabled={disabled} size={'small'}><Trans i18nKey="button.del" /></Button></Tooltip> : <Button onClick={() => (deleteRailAction(record))} size={'small'}><Trans i18nKey="button.del" /></Button>}
                </Space>)

            },
        },
    ];

    ///////////////////////////////////////////////////////////////////////
    //           RailSection LIST CHECKBOX
    ///////////////////////////////////////////////////////////////////////

    const rowSelectionSectionLine = {
        onChange: (selectedRowKeys, selectedRows) => {
            if (selectedRows.length > 0) {
                setSelectRail(selectedRows);
            } else {
                setSelectRail();
            }
        },
        getCheckboxProps: (record) => {
            let disabledcheckbox = false;
            // eslint-disable-next-line
            if (record.measuringPoints) {
                disabledcheckbox = true
            }
            return {
                disabled: disabledcheckbox,
                // Column configuration not to be checked
                name: record.name,
            }

        },
    };
    ///////////////////////////////////////////////////////////////////////
    //           Rail SECTION  MODAL
    ///////////////////////////////////////////////////////////////////////
    const showModal_NewRailSection = () => {
        setTargetKeys([]);
        setVisibleModal_NewRailSection(true);
    };

    const handleCancelModal_NewRailSection = () => {
        setVisibleModal_NewRailSection(false);
        formAddRail.resetFields();
    };

    const onFinishForm_NewRailSection = () => {
        const data = formAddRail.getFieldsValue();
        const RailSectionDataAdd = {
            railSection: {
                name: data.name,
                number: data.number,
                note: data.note,
                lineId: data.lineId
            },
            stations: data.stations
        }
        dispatch(postRailSection(RailSectionDataAdd));
        setVisibleModal_NewRailSection(false);
        formAddRail.resetFields();
    }
    const railDetail = useSelector(state => state.scadaInfra.railById);
    useEffect(() => {
        setTargetKeys([])
        if (railDetail.stations) {
            let stationIdArray = railDetail.stations.map((stationid) => {

                return (stationid.id);
            })
            setTargetKeys(stationIdArray);
            formEditRail.setFieldsValue(railDetail);
        } else {
            setTargetKeys([]);
            formEditRail.setFieldsValue(railDetail);
        }
        // eslint-disable-next-line
    }, [railDetail]);

    const showModal_EditRailSection = (data) => {
        dispatch(getRailbyId(data.id));
        setVisibleModal_EditRail(true);
    };

    const handleCancelModal_EditRail = () => {
        formEditRail.resetFields();
        setVisibleModal_EditRail(false);
    };

    const onFinishForm_EditRail = (id) => {
        const railFormDataEdit = formEditRail.getFieldsValue();

        const RailSectionDataEdit = {
            railSection: {
                name: railFormDataEdit.name,
                number: railFormDataEdit.number,
                note: railFormDataEdit.note,
                lineId: railFormDataEdit.lineId
            },
            stations: targetKeys
        }

        dispatch(editRail(formEditRail.getFieldValue().id, RailSectionDataEdit));
        setVisibleModal_EditRail(false);
        formEditRail.resetFields();
    }

    const deleteRailAction = (id) => {

        swalDelete("Are you sure you want to delete this rail ?").fire({})
            .then((result) => {
                if (result.isConfirmed) {
                    dispatch(deleteRail(id.id));
                }
            })
    }
    const deleteMultipleRailsAction = () => {
        if (selectRail) {
            let railsIds = selectRail.map((rail) => {
                return (rail.id);
            })
            let rails = {
                "rails": railsIds
            }

if (railsIds>1) {
    swalDelete("Are you sure you want to delete these rails ?").fire({})
                .then((result) => {
                    if (result.isConfirmed) {
                        dispatch(deleteMultipleRails(rails));
                        setSelectRail();
                    }
                })
}
else{
    swalDelete("Are you sure you want to delete this rail ?").fire({})
                .then((result) => {
                    if (result.isConfirmed) {
                        dispatch(deleteMultipleRails(rails));
                        setSelectRail();
                    }
                })
}


            
        }
    }

    return (
        <div className='rails-list'>
            <h2>Rails Sections List</h2>
            <Row className='rails-list-top-bar' >
                <Col >
                    <Button onClick={() => (showModal_NewRailSection())}><Trans i18nKey="button.new" /></Button>
                </Col>
                <Col> {selectRail ?
                    <Button onClick={() => (deleteMultipleRailsAction())}><Trans i18nKey="button.del" /></Button>
                    :
                    <Button disabled={true}><Trans i18nKey="button.del" /></Button>}
                </Col>
            </Row>
            <Table
                rowSelection={{
                    type: 'checkbox',
                    ...rowSelectionSectionLine,
                }}
                bordered
                columns={RailsColumnsSection}
                dataSource={RailsList}
                pagination={true}
                scroll={{ y: 500 }}
                className="mp-location-rails-table"
                rowKey="id"
            />

            {/************* Modal : New Line *************/}

            <Modal
                title="New Rail Section"
                open={visibleModal_NewRailSection}
                onOk={formAddRail.submit}
                okText="Save"
                onCancel={handleCancelModal_NewRailSection}
            >
                <Form
                    labelCol={{
                        xs: { span: 24 },
                        sm: { span: 24 },
                        md: { span: 6 },
                        lg: { span: 6 },
                        xl: { span: 6 },
                    }}
                    wrapperCol={{
                        xs: { span: 24 },
                        sm: { span: 24 },
                        md: { span: 24  },
                        lg: { span: 24 },
                        xl: { span: 24 },
                    }}
                    layout="horizontal"
                    form={formAddRail}
                    labelAlign='left'
                    onFinish={() => onFinishForm_NewRailSection()}
                >
                    <Form.Item
                        name="name"
                        label="Rail Name"
                        rules={[
                            {
                                required: true,
                                whitespace:true,
                                message: 'Please input the rail name',
                            },
                        ]}
                    >
                        <Input placeholder="" />
                    </Form.Item>

                    <Form.Item
                        name="number"
                        label="Rail Number"
                        rules={[
                            {
                                required: true,
                                whitespace:true,
                                message: 'Please input the rail number',
                            },
                        ]}
                    >
                        <Input placeholder="" />
                    </Form.Item>

                    <Form.Item name="lineId"
                        label="Line"
                        rules={[
                            {
                                required: true,
                                message: 'Please input the line',
                            },
                        ]}>
                        <Select

                            placeholder="Select a option and change input text above"
                            options={[
                                {
                                    label: "lines",
                                    options: Linesoptions,
                                }
                            ]}

                        // disabled={disabled}
                        />
                    </Form.Item>
                    <Form.Item
                        name="note"
                        label="Rail Note">
                        <TextArea rows={4} />
                    </Form.Item>
                    <Row justify={'center'}>
                        <h3><Trans i18nKey="setup.ScadaInfra.editRailModalDescription" /></h3>
                    </Row>
                    <Form.Item name="stations" justify='center' initialValue={[]} rules={[
                        {
                            required: true,
                            message: 'Please associate stations to the rail',
                        },
                    ]}>
                        <Transfer
                            dataSource={stationList}
                            titles={['Stations', 'Associated Stations']}
                            targetKeys={targetKeys}
                            selectedKeys={selectedKeys}
                            onChange={onChange}
                            onSelectChange={onSelectChange}
                            onScroll={onScroll}
                            render={(item) => item.name}
                            operations={['add', 'remove']}
                            rowKey={record => record.id}
                        />
                    </Form.Item>
                </Form>

            </Modal>

            {/************* Modal : Edit Line *************/}

            <Modal
                title="Edit Rail"
                open={visibleModal_EditRail}
                onOk={formEditRail.submit}
                okText="Save"
                onCancel={handleCancelModal_EditRail}
            >
                <Form
                    labelCol={{
                        xs: { span: 24 },
                        sm: { span: 24 },
                        md: { span: 8 },
                        lg: { span: 8 },
                        xl: { span: 8 },
                    }}
                    wrapperCol={{
                        xs: { span: 24 },
                        sm: { span: 24 },
                        md: { span: 24 },
                        lg: { span: 24 },
                        xl: { span: 24 },
                    }}
                    layout="horizontal"
                    form={formEditRail}
                    labelAlign='left'
                    onFinish={() => onFinishForm_EditRail()}
                >
                    <Form.Item
                        name="name"
                        label="Rail Name"
                        rules={[
                            {
                                required: true,
                                whitespace:true,
                                message: 'Please input the rail name',
                            },
                        ]}
                    >
                        <Input placeholder="" />
                    </Form.Item>
                    <Form.Item
                        name="number"
                        label="Rail Number"
                        rules={[
                            {
                                required: true,
                                whitespace:true,
                                message: 'Please input the rail number',
                            },
                        ]}
                    >
                        <Input placeholder="" />
                    </Form.Item>
                    <Form.Item name="lineId"
                        label="Line"
                        rules={[
                            {
                                required: true,
                                message: 'Please input the line',
                            },
                        ]}>
                        <Select

                            placeholder="Select a line"
                            options={[
                                {
                                    label: "lines",
                                    options: Linesoptions,
                                }
                            ]}

                        // disabled={disabled}
                        />
                    </Form.Item>
                    <Form.Item
                        name="note"
                        label="Rail Note"
                    >
                        <TextArea rows={4} />
                    </Form.Item>
                    <Row justify={'center'}>
                        <h3><Trans i18nKey="setup.ScadaInfra.editRailModalDescription" /></h3>
                    </Row>
                    <Form.Item name="stations" justify='center' initialValue={[]} rules={[
                        {
                            required: true,
                            message: 'Please associate stations to the rail',
                        },
                    ]}>
                        <Transfer
                            dataSource={stationList}
                            titles={['Stations', 'Associated Stations']}
                            targetKeys={targetKeys}
                            selectedKeys={selectedKeys}
                            onChange={onChange}
                            onSelectChange={onSelectChange}
                            onScroll={onScroll}
                            render={(item) => item.name}
                            operations={['add', 'remove']}
                            rowKey={record => record.id}
                        />

                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}

export default RailSectionList;
