import { Button, Row, Space, Table, Modal, Form, Transfer, Typography } from 'antd';
import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import {
    getAllRailsSection,
    getRailsDetails,
    patchRailMeasuringPoints
} from 'redux/actions/SCADAEnergy.action';
import { useDispatch, useSelector } from 'react-redux';
import { getMeasuringPointsMinimalList } from "redux/actions/MeasuringPoint.action";

const RailsDevices = () => {
    const [targetKeys, setTargetKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [visibleModal_editRailsDevices, setVisibleModal_editRailsDevices] = useState(false);
    const [formEditRailsDevices] = Form.useForm();
    const dispatch = useDispatch();
    const devicesList = useSelector(state => state.scadaEnergy.measuringpointList);
    let rails = useSelector(state => state.scadaEnergy.railSections);
    let railDetails = useSelector(state => state.scadaEnergy.railDetails);
    const { Text } = Typography;

    const railsColumnsSection = [
        {
            title: <Trans i18nKey="setup.ScadaEnergy.RailNumber" />,
            dataIndex: 'number',
            width: 120,
        },
        {
            title: <Trans i18nKey="setup.ScadaEnergy.RailName" />,
            dataIndex: 'name',
            width: 180,
        },
        {
            title: <Trans i18nKey="setup.ScadaEnergy.Line" />,
            dataIndex: 'line',
            width: 180,
            render: (_, record) => {
                return (
                    record.line?.name
                )
            }

        },
        {
            title: <Trans i18nKey="setup.ScadaEnergy.stations" />,
            dataIndex: 'stations',
            width: 180,
            render: (_, record) => {
                const stationsName = record.stations?.map((item) => `${item.name}`);
                return (stationsName?.map((s) => {
                    return <Text key={s}>{s}<br /></Text>;
                }));
            }
        },
        {
            title: <Trans i18nKey="columns.actions" />,
            key: 'action',
            width: 100,
            render: (_, record) => (
                <Space size="middle">
                    <Button onClick={() => showModal_editRailsDevices(record.id)} size={"small"}><Trans
                        i18nKey="button.Edit" /></Button>
                </Space>
            ),
        },
    ];

    useEffect(() => {
        const initPage = () => {
            dispatch(getAllRailsSection());
            dispatch(getMeasuringPointsMinimalList());
        };
        initPage();

    }, [dispatch]);

    useEffect(() => {
        if (railDetails.measuringPoints) {
            const railsIdArray = railDetails.measuringPoints.map((rail) => {
                return (rail.id);
            })
            setTargetKeys(railsIdArray);
            formEditRailsDevices.setFieldsValue(railDetails);
        }
        // eslint-disable-next-line
    }, [railDetails]);


    ///////////////////////////////////////////////////////////////////////
    //           EDIT RAILS DEVICES MODAL
    ///////////////////////////////////////////////////////////////////////
    const showModal_editRailsDevices = (railId) => {
        setTargetKeys([]);
        setVisibleModal_editRailsDevices(true);
        dispatch(getRailsDetails(railId));

    };

    const handleCancelModal_editRailsDevices = () => {
        setVisibleModal_editRailsDevices(false);
        formEditRailsDevices.resetFields();
    };

    const onFinishForm_editRailsDevices = () => {
        dispatch(patchRailMeasuringPoints(railDetails.id, { 'measuringpoint': targetKeys }));
        setVisibleModal_editRailsDevices(false);
        formEditRailsDevices.resetFields();
    };


    const onChange = (nextTargetKeys) => {
        setTargetKeys(nextTargetKeys);
    };

    const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };

    return (
        <>
            <h2><Trans i18nKey="setup.ScadaEnergy.RailsDeviceTableTile" /></h2>
            <Table
                bordered
                columns={railsColumnsSection}
                dataSource={rails}
                pagination={true}
                scroll={{ y: 500 }}
                className="mp-location-rails-table"
                rowKey="id"
            />


            {/************* Modal : Edit Rails devices *************/}

            <Modal
                width={'900px'}
                title={<Trans i18nKey="setup.ScadaEnergy.editRailSectionModalTitle" />}
                open={visibleModal_editRailsDevices}
                onOk={formEditRailsDevices.submit}
                okText="Save"
                onCancel={handleCancelModal_editRailsDevices}
            >
                <Row justify={'center'}>
                    <h3><Trans i18nKey="setup.ScadaEnergy.editRailSectionModalDescription" /></h3>
                </Row>

                <Form
                    wrapperCol={{
                        xs: { span: 24 },
                        sm: { span: 24 },
                        md: { span: 24 },
                        lg: { span: 24 },
                        xl: { span: 24 },
                    }}
                    layout="horizontal"
                    form={formEditRailsDevices}
                    labelAlign='left'
                    onFinish={() => onFinishForm_editRailsDevices()}
                >
                    <Form.Item name="measuringpoint" justify='center' initialValue={[]}>
                        <Transfer
                            listStyle={{ width: 500 }}
                            dataSource={devicesList}
                            titles={['Devices', 'Associated Devices']}
                            targetKeys={targetKeys}
                            selectedKeys={selectedKeys}
                            onChange={onChange}
                            onSelectChange={onSelectChange}
                            render={(item) => {
                                return item.device_name + "_" + item.protocol_name + "_" + item.device_identification;
                            }
                            }
                            operations={['add', 'remove']}
                            rowKey={record => record.id}
                        />

                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

export default RailsDevices;
