// Importing necessary modules and components
import { Form, Radio, Input } from "antd";
import React from "react";

// Definition of the LevelsFilter functional component
const LevelsFilter = () => {
  return (
    <div className="level-filter">
      {/* Filter card for levels */}
      <div className="filter-card">
        {/* Title for levels */}
        <span className="filter-card-title">Levels</span>

        {/* Radio buttons for level type */}
        <Form.Item name="level_type">
          <Radio.Group>
            <Radio value={"individual"}>Individual</Radio>
            <Radio value={"System"}>System</Radio>
          </Radio.Group>
        </Form.Item>

        {/* Input field for max level */}
        <Form.Item name="max_level" label="Max Level" labelAlign="left">
          <Input size="small" />
        </Form.Item>

        {/* Input field for min level */}
        <Form.Item name="min_level" label="Min Level" labelAlign="left">
          <Input size="small" />
        </Form.Item>
      </div>
    </div>
  );
};

// Exporting the LevelsFilter component as default
export default LevelsFilter;
