import {Button, Checkbox, Col, Form, Input, Row, Select, Typography, Tag} from 'antd';
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ApiFilled, RightSquareFilled, RightSquareOutlined, WifiOutlined} from "@ant-design/icons";
import {
    communicationTest,
    editMeasuringPoint,
    getDeviceTypes, getDeviceProtocols, getDeviceByName,
    getMeasuringPointById, getProtocolByName
} from "redux/actions/MeasuringPoint.action";
import {useParams} from "react-router-dom";
import {Trans} from "react-i18next";
import {padLeadingZeros} from "utils/function";
import PropTypes from "prop-types";

const {Text} = Typography;
const { TextArea } = Input;

const Device = ({data}) => {
    const [componentSize, setComponentSize] = useState('default');
    const [form] = Form.useForm();
    const {id} = useParams();
    const dispatch = useDispatch();
    const measuringPoint = useSelector(state => state.measuringPoint.measuringPointID);
    const devices = useSelector(state => state.measuringPoint.devices);
    const [listDevice, setListDevice] = useState([]);
    const protokolls = useSelector(state => state.measuringPoint.protokolls);
    const [listProtokoll, setListProtokoll] = useState([]);
    const dataDevice = useSelector(state => state.measuringPoint.dataDevice);
    const dataProtocol = useSelector(state => state.measuringPoint.dataProtocol);

    useEffect(() => {
        dispatch(getDeviceTypes());
        dispatch(getDeviceProtocols());
        dispatch(getMeasuringPointById(id));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (devices) {
            const options = devices.map((element) => {
                return {
                    label: element.DeviceName,
                    id: element.folder,
                    value: element.folder,
                }
            });
            setListDevice(options);
        }
    }, [devices]);

    useEffect(() => {
        if (protokolls) {
            const options = protokolls.map((element) => {
                return {
                    label: element.ProtocolName,
                    id: element.folder,
                    value: element.folder,
                }
            });
            setListProtokoll(options);
        }
    }, [protokolls]);
    const setDefaultValue = (value, defaultValue) => (value || defaultValue);

    useEffect(() => {
        form.setFieldsValue({
            collecting_measurement_data: setDefaultValue(measuringPoint.collecting_measurement_data, false),
            collecting_status: setDefaultValue(measuringPoint.collecting_status, false),
            device: measuringPoint.device,
            device_description: setDefaultValue(measuringPoint.device_description, ""),
            device_identification: setDefaultValue(measuringPoint.device_identification, ""),
            device_protocol: setDefaultValue(measuringPoint.device_protocol, ""),
            device_type: setDefaultValue(measuringPoint.device_type, ""),
            device_use_and_display: setDefaultValue(measuringPoint.device_use_and_display, false),
            icon: setDefaultValue(measuringPoint.icon, ""),
            id: measuringPoint.id,
            installation_location: setDefaultValue(measuringPoint.installation_location, ""),
            installation_location_use_and_display: setDefaultValue(measuringPoint.installation_location_use_and_display, false),
            keep_central_operating_log: setDefaultValue(measuringPoint.keep_central_operating_log, false),
            maintenance: setDefaultValue(measuringPoint.maintenance, ""),
            note: setDefaultValue(measuringPoint.note, ""),
            status: setDefaultValue(measuringPoint.status, ""),
            tech_id: setDefaultValue(measuringPoint.tech_id, ""),
        });
        if (measuringPoint.device) {
            dispatch(getDeviceByName(measuringPoint.device));
            dispatch(getProtocolByName(measuringPoint.device_protocol));
        }
        // eslint-disable-next-line
    }, [measuringPoint]);

    useEffect(() => {
        if (dataDevice) {
            form.setFieldsValue({device_name: dataDevice.DeviceName});
            form.setFieldsValue({device_type: dataDevice.DeviceType});
            form.setFieldsValue({device_version: dataDevice.Version});
            form.setFieldsValue({device_serial_number: dataDevice.SerialNumber});
        }
    }, [form, dataDevice]);

    useEffect(() => {
        if (dataProtocol) {
            form.setFieldsValue({protocol_name: dataProtocol.ProtocolName});
        }
    }, [form, dataProtocol]);

    const onFormLayoutChange = ({size}) => {
        setComponentSize(size);
    };

    function handleForm(values) {
        dispatch((editMeasuringPoint(parseInt(id), values)))
    }

    function handleCommunicationTest() {
        dispatch(communicationTest());
    }

    const handleChange = (value, option) => {
        dispatch(getDeviceByName(option.id));
    }
    return (
        <Row className='mp-device'>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} className="ant-col-padded">
                <Row gutter={16} className='mp-device-infos'>
                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                        <label><Trans i18nKey="measuringPoints.edit.measuringPointId"/></label>
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                        <Tag color="default">{padLeadingZeros(measuringPoint.id, 4)}</Tag>
                    </Col>
                </Row>
                <Form
                    labelCol={{
                        xs: {span: 24},
                        sm: {span: 24},
                        md: {span: 8},
                        lg: {span: 8},
                        xl: {span: 8}
                    }}
                    wrapperCol={{
                        xs: {span: 24},
                        sm: {span: 24},
                        md: {span: 14},
                        lg: {span: 14},
                        xl: {span: 14}
                    }}
                    layout="horizontal"
                    initialValues={{
                        size: componentSize,
                        keepCentralOperatingLog: true
                    }}
                    form={form}
                    onValuesChange={onFormLayoutChange}
                    onFinish={handleForm}
                    size={componentSize}>

                    <Form.Item
                        label={<Trans i18nKey="measuringPoints.device.device"/>}
                        labelAlign="left"
                        name="device"
                        rules={[
                            {
                                required: true,
                                message: 'Please select a device',
                            },
                        ]}>
                        <Select
                            placeholder="Select a device"
                            options={listDevice}
                            onSelect={handleChange}
                        />
                    </Form.Item>
                    <Form.Item hidden={true}
                               label={<Trans i18nKey="measuringPoints.new.device_type"/>}
                               labelAlign="left"
                               name="device_type">
                        <Input/>
                    </Form.Item>
                    <Form.Item hidden={true}
                               label={"Version"}
                               labelAlign="left"
                               name="device_version">
                        <Input/>
                    </Form.Item>
                    <Form.Item hidden={true}
                               label={"SerialNumber"}
                               labelAlign="left"
                               name="device_serial_number">
                        <Input/>
                    </Form.Item>
                    <Form.Item hidden={true}
                               label={"DeviceName"}
                               labelAlign="left"
                               name="device_name">
                        <Input/>
                    </Form.Item>
                    <Form.Item hidden={true}
                               label={"ProtocolName"}
                               labelAlign="left"
                               name="protocol_name">
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label={<Trans i18nKey="measuringPoints.device.protocol"/>}
                        labelAlign="left"
                        name="device_protocol"
                        rules={[
                            {
                                required: true,
                                message: 'Please select the protocol',
                            },
                        ]}>
                        <Select
                            placeholder="Select a protocol"
                            options={listProtokoll}
                        />
                    </Form.Item>

                    <Form.Item
                        label={<Trans i18nKey="measuringPoints.device.identification"/>}
                        labelAlign="left"
                        name="device_identification"
                        rules={[
                            {
                                required: true,
                                whitespace:true,
                                message: 'Please input the identification',
                            },
                        ]}>
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label={<Trans i18nKey="measuringPoints.device.description"/>}
                        labelAlign="left"
                        name="device_description"
                        rules={[
                            {
                                required: true,
                                whitespace:true,
                                message: 'Please input the description',
                            },
                        ]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label={<Trans i18nKey="measuringPoints.device.TechID"/>}
                        labelAlign="left"
                        name="tech_id"
                        rules={[
                            {
                                required: true,
                                whitespace:true,
                                message: 'Please input the TechID',
                            },
                        ]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label={<Trans i18nKey="measuringPoints.device.maintenance"/>}
                        labelAlign="left"
                        name="maintenance"
                        rules={[
                            {
                                required: true,
                                whitespace:true,
                                message: 'Please input the maintenance',
                            },
                        ]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="device_use_and_display"
                        valuePropName="checked"
                        className={"mp-checkbook-margin"}>
                        <Checkbox>
                            <Trans i18nKey="measuringPoints.device.useAndDisplay"/>
                        </Checkbox>
                    </Form.Item>

                    <Form.Item
                        name="collecting_measurement_data"
                        valuePropName="checked"
                        className={"mp-checkbook-margin"}>
                        <Checkbox size="small">
                            <Trans i18nKey="measuringPoints.device.collectingMeasurementData"/>
                        </Checkbox>
                    </Form.Item>

                    <Form.Item
                        name="collecting_status"
                        valuePropName="checked"
                        className={"mp-checkbook-margin"}>
                        <Checkbox>
                            <Trans i18nKey="measuringPoints.device.collectingStatus"/>
                        </Checkbox>
                    </Form.Item>

                    <Form.Item
                        name="keep_central_operating_log"
                        valuePropName="checked">
                        <Checkbox>
                            <Trans i18nKey="measuringPoints.device.keepCentralOperatingLog"/>
                        </Checkbox>
                    </Form.Item>
                    <Form.Item
                               label={<Trans i18nKey="measuringPoints.new.installation_location"/>}
                               labelAlign="left"
                               name="installation_location"
                               rules={[
                                   {
                                       required: true,
                                       whitespace:true,
                                       message: 'Please input a location',
                                   },
                               ]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label={<Trans i18nKey="measuringPoints.location.icon"/>}
                               labelAlign="left"
                               className={"mp-checkbook-margin"}
                               name="icon" rules={[
                        {
                            required: true,
                            message: 'Please select an icon',
                        },
                    ]}>
                        <Select>
                            <Select.Option value="1"><RightSquareFilled/></Select.Option>
                            <Select.Option value="2"><RightSquareOutlined/></Select.Option>
                            <Select.Option value="3"><ApiFilled/></Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{span: 24}}
                        name="installation_location_use_and_display"
                        valuePropName="checked"
                        className={"mp-checkbook-margin"}>
                        <Checkbox>
                            <Trans i18nKey="measuringPoints.device.useAndDisplay"/>
                        </Checkbox>
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            xs: {span: 24},
                            sm: {span: 24},
                            md: {span: 24},
                            lg: {span: 22},
                            xl: {span: 22}
                        }}
                        labelCol={{span: 24}}
                        label={<Trans i18nKey="measuringPoints.location.specialNotes"/>}
                        labelAlign="left"
                        name="note">
                        <TextArea rows={3}/>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="submit" type={"primary"}>
                            <Trans i18nKey="button.applyAndSave"/>
                        </Button>
                    </Form.Item>

                </Form>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12} className="ant-col-padded">
                <Row className='mp-device-infos'>
                    <Col>
                        <Tag color="default">{dataDevice?.DeviceType}</Tag>
                    </Col>

                    <Col><Text>Version</Text></Col>

                    <Col>
                        <Tag color="default">{dataDevice?.Version}</Tag>
                    </Col>

                    <Col>
                        <WifiOutlined style={{fontSize: 20, color: "#52c41a"}}/>
                    </Col>
                </Row>

                <Row className='mp-device-infos'>
                    <Col>
                        <Text><Trans i18nKey="measuringPoints.device.serialNumber"/></Text>
                    </Col>
                    <Col>
                        <Text>{dataDevice?.SerialNumber}</Text>
                    </Col>
                </Row>

                <Row className='mp-device-infos'>
                    <Col>
                        <Button htmlType="button" onClick={handleCommunicationTest}>
                            <Trans i18nKey="measuringPoints.device.communicationTest"/>
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

Device.propTypes={
    data:PropTypes.object
}
export default Device;
