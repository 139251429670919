import {
    FETCH_USERS_REQUEST, // Importing the FETCH_USERS_REQUEST action type from "../constants/ActionTypes"
} from "../constants/ActionTypes"; // Importing action type constants

// Initial state for the Users reducer
const INIT_STATE = {
    UsersList: [], // Array to hold user data
    Departments: [], // Array to hold department data
    token: 'abc', // Default token value
};

// Users reducer function
export function Users(state = INIT_STATE, action) {
    if (action.type === FETCH_USERS_REQUEST) {
        // Handling the FETCH_USERS_REQUEST action
        return {
            ...state, // Copying the current state
            UsersList: action.UsersList // Updating UsersList with the new data from the action
        }
    } else {
        return state; // Returning the current state if action type doesn't match
    }

}

export default Users; // Exporting the Users reducer
