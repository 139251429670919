import {
    SYSTEM_STATUS_SUCCESS, // Importing the success action type constant
    SYSTEM_STATUS_FAIL // Importing the fail action type constant
} from '../constants/SystemStatusType'; // Importing action type constants

// Initial state for the systemStatus reducer
const initialState = {
    systemStatus: {
        measurePointStatus: false, // Initial measure point status
        communicationStatus: false // Initial communication status
    }
};

// Reducer function for systemStatus
// eslint-disable-next-line
export default function systemStatus(state = initialState, action) {
    const {type, payload} = action; // Destructuring action object
    switch (type) {
        case SYSTEM_STATUS_SUCCESS: // Handling the success action
            return {
                ...state, // Copying the current state
                systemStatus: payload.systemStatus, // Updating systemStatus with the new data from the action payload
            };
        case SYSTEM_STATUS_FAIL: // Handling the fail action
            return {message: "error"}; // Returning a message indicating failure
        default:
            return state; // Returning the current state if action type doesn't match
    }
}
