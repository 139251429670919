import React from "react";
import { Routes, Route,BrowserRouter } from "react-router-dom";
import "styles/App.less";
import { Provider } from "react-redux";
import store from "redux/store/store";
import { ConfigProvider } from "antd";
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";

import MainApp from "./containers/App/MainApp";
import ChartFlow from "pages/Chart/ChartFlow";
import SetupUser from "pages/System/Setup/SetupUser/SetupUser";
import NoMatch from "pages/NoMatch/NoMatch";
import Login from "pages/Login/Login";
import SoftwareInfo from "pages/System/Setup/Software/SoftwareInfo";
import PrivateRoute from "privateRoute/PrivateRoute";
import LogReport from "pages/System/LogReport/LogReport";
import MeasuringPointList from "pages/SCADA/MeasuringPoints/MeasuringPointList";
import MeasuringPointEdit from "pages/SCADA/MeasuringPoints/Edit/MeasuringPointEdit";
import MeasurementData from "pages/DataAnalysis/MeasurementData/MeasurementData";
import ScadaEnergy from "pages/System/Setup/SCADAEnergy/ScadaEnergy";
import Distribution from "pages/DataAnalysis/Distribution/Distribution";
import PRAS from "pages/DataAnalysis/PRAS/PRAS";
import { Workflow } from "pages/System/Setup/Workflow/Workflow";
import ScadaInfraTabs from "pages/System/Setup/SCADAInfra/ScadaInfraTabs";
import SCMS from "pages/DataAnalysis/SCMS/SCMS";
import NetworkService from "utils/network-service";

//Here is the guy where I set up the interceptors!
NetworkService.setupInterceptors();

function App() {
  return (
    <Provider store={store}>
      <ConfigProvider>
        <BrowserRouter>
          <I18nextProvider i18n={i18n}>
            <Routes>
              {/*<Route index element={<Login/>}/>*/}
              <Route path="/" element={<MainApp />}>
                {/* Main App Routes */}
                <Route
                  index
                  element={
                    <PrivateRoute>
                      <ChartFlow />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="overview-map"
                  index
                  element={
                    <PrivateRoute>
                      <ChartFlow />
                    </PrivateRoute>
                  }
                />
                {/* SCADA Routes */}
                <Route
                  path="scada/measuring-points"
                  element={
                    <PrivateRoute>
                      <MeasuringPointList />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="scada/measuring-points/:id"
                  element={
                    <PrivateRoute>
                      <MeasuringPointEdit />
                    </PrivateRoute>
                  }
                />
                {/* System Setup Routes */}
                <Route
                  path="setup/user"
                  element={
                    <PrivateRoute>
                      <SetupUser />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="software-info"
                  element={
                    <PrivateRoute>
                      <SoftwareInfo />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="setup/scada-infra"
                  element={
                    <PrivateRoute>
                      <ScadaInfraTabs />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="setup/scada-energy"
                  element={
                    <PrivateRoute>
                      <ScadaEnergy />
                    </PrivateRoute>
                  }
                />
                {/* Log Report Route */}
                <Route
                  path="system/log-report"
                  element={
                    <PrivateRoute>
                      <LogReport />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="setup/workflow"
                  element={
                      <Workflow/>
                  }
                />
                {/* Software Info Routes */}
                <Route
                  path="software-info"
                  element={
                    <PrivateRoute>
                      <SoftwareInfo updateFunction={false} />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="setup-software"
                  element={
                    <PrivateRoute>
                      <SoftwareInfo updateFunction={true} />
                    </PrivateRoute>
                  }
                />
                {/* Data Analysis Routes */}
                <Route
                  path="analysis/measurement-data"
                  element={
                    <PrivateRoute>
                      <MeasurementData />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="analysis/distribution"
                  element={
                    <PrivateRoute>
                      <Distribution />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="analysis/pras"
                  element={
                    <PrivateRoute>
                      <PRAS />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="analysis/scms"
                  element={
                    <PrivateRoute>
                      <SCMS />
                    </PrivateRoute>
                  }
                />
              </Route>
              {/* No Match and Login Routes */}
              <Route path="vide" element={<NoMatch />} />
              <Route path="login" element={<Login />} />
              <Route path="*" element={<NoMatch />} />
            </Routes>
          </I18nextProvider>
        </BrowserRouter>
      </ConfigProvider>
    </Provider>
  );
}

export default App;
