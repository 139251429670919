import axios from "axios";
import config from "../utils/consts.config.json";
import authHeader from "./AuthHeader";

const API_URL = config.API_URL;

/**
 * ////////////////////////////////////////////////////////////
 *                  Scada-Infra Line Services
 * ///////////////////////////////////////////////////////////
 **/

/**
 * Get list of All lines including rail section and MP
 *
 * @returns
 */
const getAllLineService = () => {
    return axios
        .get(API_URL + "lines?filter=%7B%0A%20%20%20%20%22include%22%3A%20%5B%7B%0A%20%20%20%20%20%20%20%20%22relation%22%3A%20%22railSections%22%2C%22scope%22%3A%7B%22include%22%3A%5B%7B%22relation%22%3A%22measuringPoints%22%7D%5D%7D%20%0A%20%20%20%20%7D%5D%0A%7D", {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * Get line detail by id
 * @param {number} id
 * @returns
 */
const getLineByIdService = (id) => {
    return axios
        .get(API_URL + `lines/${id}`, {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * Add new Line to scada Infra
 * @param {json} data
 * @returns
 */
const addLineService = (data) => {
    return axios
        .post(API_URL + "lines", data, {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * Delete line by id
 * @param {number} id
 * @returns
 */
const deleteLineService = (id) => {
    return axios
        .delete(API_URL + `lines/${id}`, {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * delete multiple lines
 * @param {array} lines array of lines Ids
 * @returns
 */
const deleteMultipleLinesService = (lines) => {
    return axios
        .delete(API_URL + `lines`, {data: lines, headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * Edit line by ID
 * @param {number} id
 * @param {json} data
 * @returns
 */
const editLineService = (id, data) => {
    return axios
        .put(API_URL + `lines/${id}`, data, {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * ////////////////////////////////////////////////////////////
 *                  Scada-Infra Station Services
 * ///////////////////////////////////////////////////////////
 **/

/**
 * Add new station to scada Infra
 * @param {json} data
 * @returns
 */
const addStationService = (data) => {
    return axios
        .post(API_URL + "stations", data, {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }

            return response;
        });
};

/**
 * Delete station by id
 * @param {number} id
 * @returns
 */
const deleteStationService = (id) => {
    return axios
        .delete(API_URL + `stations/${id}`, {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * delete multiple stations
 * @param {array} stations array of stations Ids
 * @returns
 */
const deleteMultipleStationService = (stations) => {
    return axios
        .delete(API_URL + `stations`, {headers:authHeader(),data: stations})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
}

/**
 * Edit station by ID
 * @param {number} id
 * @param {json} data
 * @returns
 */
const editStationService = (id, data) => {
    return axios
        .patch(API_URL + `stations/${id}`, data, {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * Get list of stations by line ID
 *
 * @returns
 */
const getStationsByLineIdService = (lineid) => {
    return axios
        .get(API_URL + `lines/${lineid}/stations`, {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * Get list of All stations including rail section and MP
 *
 * @returns
 */
const getAllStationsService = () => {
    return axios
        .get(API_URL + "stations?filter=%7B%0A%0A%20%20%22include%22%3A%20%5B%0A%20%20%20%20%7B%0A%20%20%20%20%20%20%22relation%22%3A%20%22lines%22%20%20%20%20%20%20%20%20%20%20%20%20%20%20%0A%20%20%20%20%7D%0A%20%20%5D%0A%0A%7D", {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * Get station detail by id
 * @param {number} stationId
 * @returns
 */
const getStationByIdService = (stationId) => {
    return axios
        .get(API_URL + `stations/${stationId}?filter=%7B%0A%0A%20%20%22include%22%3A%20%5B%0A%20%20%20%20%7B%0A%20%20%20%20%20%20%22relation%22%3A%20%22lines%22%20%20%20%20%20%20%20%20%20%20%20%20%20%20%0A%20%20%20%20%7D%0A%20%20%5D%0A%0A%7D`, {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * ////////////////////////////////////////////////////////////
 *                  Scada-Infra Rail Services
 * ///////////////////////////////////////////////////////////
 **/

/**
 * Get list of All rails including stations and MP
 *
 * @returns
 */
const getAllRailsService = () => {
    return axios
        .get(API_URL + "rail-sections?filter=%7B%22include%22%3A%20%5B%0A%20%20%20%20%7B%0A%20%20%20%20%20%20%22relation%22%3A%20%22measuringPoints%22%20%20%20%20%20%0A%20%20%20%20%7D%2C%0A%20%20%20%20%7B%0A%20%20%20%20%20%20%22relation%22%3A%20%22line%22%20%20%20%20%20%0A%20%20%20%20%7D%2C%0A%20%20%20%20%7B%0A%20%20%20%20%20%20%22relation%22%3A%20%22stations%22%20%20%20%20%20%0A%20%20%20%20%7D%0A%20%20%5D%0A%7D", {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
}

/**
 * Get rail detail by id including stations
 * @param {number} railId
 * @returns
 */
const getRailByIdService = (railId) => {
    return axios
        .get(API_URL + `rail-sections/${railId}?filter=%7B%0A%22include%22%3A%5B%22line%22%2C%22stations%22%5D%0A%7D`, {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
}

/**
 * Add new rail section to scada Infra
 * @param {json} data
 * @returns
 */
const addRailSectionService = (data) => {
    return axios
        .post(API_URL + "rail-sections", data, {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * Edit rail by ID
 * @param {number} id
 * @param {json} data
 * @returns
 */
const editRailService = (id, data) => {
    return axios
        .patch(API_URL + `rail-sections/${id}`, data, {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * delete rail by id
 * @param {array} id
 * @returns
 */
const deleteRailService = (id) => {
    return axios
        .delete(API_URL + `rail-sections/${id}`, {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * delete multiple rails
 * @param {array} rails array of rails Ids
 * @returns
 */
const deleteMultipleRailsService = (rails) => {
    return axios
        .delete(API_URL + `rail-sections`, { headers:authHeader(),data: rails})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
}

const scadaInfra = {
    addLineService,
    getAllLineService,
    deleteLineService,
    editLineService,
    addStationService,
    deleteStationService,
    editStationService,
    getStationsByLineIdService,
    getAllStationsService,
    getAllRailsService,
    addRailSectionService,
    editRailService,
    getLineByIdService,
    getStationByIdService,
    getRailByIdService,
    deleteMultipleLinesService,
    deleteRailService,
    deleteMultipleRailsService,
    deleteMultipleStationService
}
export default scadaInfra;
