// scada infra const
//LINE
export const RECEIVE_LINE = 'RECEIVE_LINE';
export const RECEIVE_LINE_BY_ID = '  RECEIVE_LINE_BY_ID'
export const LINE_POST_SUCCESS = 'LINE_POST_SUCCESS';
export const LINE_POST_FAILURE = 'LINE_POST_FAILURE';
export const DELETE_LINE = 'DELETE_LINE';
export const LINE_DELETE_SUCCESS = 'LINE_DELETE_SUCCESS';
export const LINE_DELETE_FAILURE = 'LINE_DELETE_FAILURE';
export const LINE_EDIT_SUCCESS = 'LINE_EDIT_SUCCESS';
export const LINE_EDIT_FAILURE = 'LINE_EDIT_SUCCESS';
export const LINE_MULTIPLE_DELETE_SUCCESS = 'LINE_MULTIPLE_DELETE_SUCCESS';
export const LINE_MULTIPLE_DELETE_FAILURE = 'LINE_MULTIPLE_DELETE_FAILURE';
//STATION
export const RECEIVE_STATION = 'RECEIVE_STATION';
export const STATION_POST_SUCCESS = 'STATION_POST_SUCCESS';
export const STATION_POST_FAILURE = 'STATION_POST_FAILURE';
export const DELETE_STATION = 'DELETE_STATION';
export const STATION_DELETE_SUCCESS = 'STATION_DELETE_SUCCESS';
export const STATION_DELETE_FAILURE = 'STATION_DELETE_FAILURE';
export const STATION_EDIT_SUCCESS = 'STATION_EDIT_SUCCESS';
export const STATION_EDIT_FAILURE = 'STATION_EDIT_FAILURE';
export const RECEIVE_STATION_BY_ID = 'RECEIVE_STATION_BY_ID';
export const STATION_MULTIPLE_DELETE_SUCCESS = 'STATION_MULTIPLE_DELETE_SUCCESS';
export const STATION_MULTIPLE_DELETE_FAILURE = 'STATION_MULTIPLE_DELETE_FAILURE';

//RAIL SECTION
export const RECEIVE_RAIL = 'RECEIVE_RAIL';
export const RECEIVE_RAIL_BY_ID = 'RECEIVE_RAIL_BY_ID';
export const RAIL_SECTION_POST_SUCCESS = 'RAIL_SECTION_POST_SUCCESS';
export const RAIL_SECTION_POST_FAILURE = 'RAIL_SECTION_POST_FAILURE';
export const RAIL_EDIT_SUCCESS = 'RAIL_EDIT_SUCCESS';
export const RAIL_EDIT_FAILURE = 'RAIL_EDIT_FAILURE';
export const RAIL_DELETE_SUCCESS = 'RAIL_DELETE_SUCCESS';
export const RAIL_DELETE_FAILURE = 'RAIL_DELETE_FAILURE';
export const RAIL_MULTIPLE_DELETE_SUCCESS = 'RAIL_MULTIPLE_DELETE_SUCCESS';
export const RAIL_MULTIPLE_DELETE_FAILURE = 'RAIL_MULTIPLE_DELETE_FAILURE';