import {
    MEASURING_POINT_POST_FAILURE,
    MEASURING_POINT_POST_SUCCESS,
    MEASURING_POINT_GET_LIST,
    MEASURING_POINT_GET_OPERATING_LOG,
    MEASURING_POINT_GET_READING,
    MEASURING_POINT_GET_REFERENCE_PRAS,
    MEASURING_POINT_GET_REFERENCE_SCMS,
    MEASURING_POINT_GET_THRESHOLD,
    MEASURING_POINT_GET_BY_ID,
    MEASURING_POINT_GET_DEVICE_TYPES,
    MEASURING_POINT_GET_DEVICE_PROTOCOLS,
    MEASURING_POINT_GET_DEVICE_BY_NAME,
    MEASURING_POINT_GET_RECTIFIERS_SUBSTATIONS_LIST,
    MEASURING_POINT_GET_PROTOCOL_BY_NAME
} from "../constants/MeasuringPointTypes"; // Importing action type constants related to measuring points

const initialState = {
    measuringPoint: [], // List of measuring points
    referenceSCMS: null, // Reference for SCMS
    referencePRAS: null, // Reference for PRAS
    reading: null, // Reading data
    threshold: null, // Threshold data
    operatingLog: null, // Operating log data
    postMeasuringPoint: false, // Flag indicating if measuring point was successfully posted
    error: "", // Error message
    measuringPointID: { // Details of measuring point
        collecting_measurement_data: '',
        collecting_status: false,
        device: '',
        device_name: '',
        device_description: '',
        device_identification: '',
        device_protocol: '',
        protocol_name: '',
        device_type: '',
        device_use_and_display: false,
        icon: '',
        id: null,
        installation_location: '',
        installation_location_use_and_display: false,
        keep_central_operating_log: false,
        maintenance: '',
        note: '',
        status: false,
        tech_id: '',
        railSections: []
    },
    devices: [], // List of devices
    protokolls: [], // List of protocols
    dataDevice: { // Data related to device
        DeviceName: '',
        DeviceType: '',
        Version: '',
        SerialNumber: '',
        Include: [],
        Values: [],
        Threshold: [],
        Command: [],
        Systemstatus: [],
        _comment: ''
    },
    dataProtocol: { // Data related to protocol
        ProtocolName: '',
        Version: '',
        Configuration: {
            IP_Adress: false,
            Mask: false
        }
    },
    RectifiersSubstations: [], // List of rectifiers substations
};

// Reducer function for measuring points
// eslint-disable-next-line
export default function measuringPoint(state = initialState, action) {
    const { type, payload } = action; // Destructuring action object
    switch (type) {
        case MEASURING_POINT_GET_LIST: {
            return {
                ...state,
                measuringPoint: payload.data // Updating list of measuring points with data from the action payload
            }
        }
        case MEASURING_POINT_GET_BY_ID: {
            return {
                ...state,
                measuringPointID: payload.data // Updating details of measuring point with data from the action payload
            }
        }
        case MEASURING_POINT_GET_REFERENCE_SCMS: {
            return {
                ...state,
                referenceSCMS: payload.referenceSCMS // Updating SCMS reference with data from the action payload
            }
        }
        case MEASURING_POINT_GET_REFERENCE_PRAS: {
            return {
                ...state,
                referencePRAS: payload.referencePRAS // Updating PRAS reference with data from the action payload
            }
        }
        case MEASURING_POINT_GET_READING: {
            return {
                ...state,
                reading: payload.reading // Updating reading data with data from the action payload
            }
        }
        case MEASURING_POINT_GET_THRESHOLD: {
            return {
                ...state,
                threshold: payload.threshold // Updating threshold data with data from the action payload
            }
        }
        case MEASURING_POINT_GET_OPERATING_LOG: {
            return {
                ...state,
                operatingLog: payload.operatingLog // Updating operating log data with data from the action payload
            }
        }
        case MEASURING_POINT_POST_SUCCESS: {
            return {
                ...state,
                postMeasuringPoint: payload.postMeasuringPoint // Updating postMeasuringPoint flag with data from the action payload
            }
        }
        case MEASURING_POINT_POST_FAILURE: {
            return {
                ...state,
                error: payload.error // Updating error message with data from the action payload
            }
        }
        case MEASURING_POINT_GET_RECTIFIERS_SUBSTATIONS_LIST: {
            return {
                ...state,
                RectifiersSubstations: payload.data // Updating list of rectifiers substations with data from the action payload
            }
        }
        case MEASURING_POINT_GET_DEVICE_TYPES: {
            return {
                ...state,
                devices: payload.devices // Updating list of devices with data from the action payload
            }
        }
        case MEASURING_POINT_GET_DEVICE_PROTOCOLS: {
            return {
                ...state,
                protokolls: payload.protokolls // Updating list of protocols with data from the action payload
            }
        }
        case MEASURING_POINT_GET_DEVICE_BY_NAME: {
            return {
                ...state,
                dataDevice: payload.dataDevice // Updating device data with data from the action payload
            }
        }
        case MEASURING_POINT_GET_PROTOCOL_BY_NAME: {
            return {
                ...state,
                dataProtocol: payload.dataProtocol // Updating protocol data with data from the action payload
            }
        }
        default:
            return state;
    }
}
