import {
    FILTER_DELETE_FAILURE,
    FILTER_DELETE_SUCCESS,
    FILTER_PATCH_FAILURE,
    FILTER_PATCH_SUCCESS,
    FILTER_POST_FAILURE,
    FILTER_POST_SUCCESS,
    GET_LINES_FAILURE,
    GET_LINES_SUCCESS,
    RECEIVE_FILTER,
    GET_STATIONS_SUCCESS,
    GET_STATIONS_FAILURE,
    GET_RAIL_SECTION_SUCCESS,
    GET_RAIL_SECTION_FAILURE,
    GET_MEASUREMENT_POINTS_SUCCESS,
    GET_MEASUREMENT_POINTS_FAILURE,
} from "../constants/DataAnalysisType"; // Importing action type constants related to data analysis

const initialState = {
    listFilter: [], // List of filters
    lines: [], // List of lines
    stations: [], // List of stations
    railSections: [], // List of rail sections
    measurementPoints: [], // List of measurement points
    success: false, // Flag indicating if the action was successful
    error: "", // Error message
};

// Reducer function for data analysis
// eslint-disable-next-line
export default function scadaInfra(state = initialState, action) {
    const {type, payload} = action; // Destructuring action object
    const handleSuccess = (state, payload) => ({
        ...state,
        success: payload.success,
    });

    const handleFailure = (state, payload) => ({
        ...state,
        error: payload.error,
    });

    switch (type) {
        case RECEIVE_FILTER: {
            // Handling reception of filter data
            return {
                ...state,
                listFilter: payload.data, // Updating list of filters with data from the action payload
            };
        }
        case FILTER_POST_SUCCESS: {
            // Handling successful posting of filter
            return handleSuccess(state, payload)// Updating success flag with data from the action payload
        }
        case FILTER_POST_FAILURE: {
            // Handling failure in posting filter
            return handleFailure(state, payload);// Updating error message with data from the action payload
        }
        case FILTER_PATCH_SUCCESS: {
            // Handling successful patching of filter
            return handleSuccess(state, payload)// Updating success flag with data from the action payload
        }
        case FILTER_PATCH_FAILURE: {
            // Handling failure in patching filter
            return handleFailure(state, payload);// Updating error message with data from the action payload
        }
        case FILTER_DELETE_SUCCESS: {
            // Handling successful deletion of filter
            return handleSuccess(state, payload)// Updating success flag with data from the action payload

        }
        case FILTER_DELETE_FAILURE: {
            // Handling failure in deleting filter
            return handleFailure(state,payload);// Updating error message with data from the action payload
        }
        case GET_LINES_SUCCESS: {
            // Handling successful retrieval of lines
            return {
                ...state,
                success: payload.success,
                lines: payload.data, // Updating list of lines with data from the action payload
            };
        }
        case GET_LINES_FAILURE: {
            // Handling failure in retrieving lines
            return handleFailure(state, payload);// Updating error message with data from the action payload
        }
        case GET_STATIONS_SUCCESS: {
            // Handling successful retrieval of stations
            return {
                ...state,
                success: payload.success,
                stations: payload.data, // Updating list of stations with data from the action payload
            };
        }
        case GET_STATIONS_FAILURE: {
            // Handling failure in retrieving stations
            return handleFailure(state, payload);// Updating error message with data from the action payload
        }
        case GET_RAIL_SECTION_SUCCESS: {
            // Handling successful retrieval of rail sections
            return {
                ...state,
                success: payload.success,
                railSections: payload.data, // Updating list of rail sections with data from the action payload
            };
        }
        case GET_RAIL_SECTION_FAILURE: {
            // Handling failure in retrieving rail sections
            return handleFailure(state, payload)// Updating error message with data from the action payload
        }
        case GET_MEASUREMENT_POINTS_SUCCESS: {
            // Handling successful retrieval of measurement points
            return {
                ...state,
                success: payload.success,
                measurementPoints: payload.data, // Updating list of measurement points with data from the action payload
            };
        }
        case GET_MEASUREMENT_POINTS_FAILURE: {
            // Handling failure in retrieving measurement points
            return handleFailure(state, payload);// Updating error message with data from the action payload
        }
        default:
            return state; // Returning the current state for unhandled actions
    }
}
