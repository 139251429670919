import axios from "axios";
import config from "../utils/consts.config.json";
import authHeader from "./AuthHeader";

const API_URL = config.API_URL;
//////// //////// //////// //////// //////// ////////
//////// GET RAILS ////////
//////// //////// //////// //////// //////// ////////
const getAllRails = (nodeelements) => {
    console.log("nodeelemtn",nodeelements);
    return axios
        .get(API_URL +`rail-sections?filter={"include": [{"relation": "stations","scope":{"where":{"or":[${nodeelements}]}}},{"relation": "measuringPoints"}]}`,{headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};
const chartflowService = {
    getAllRails
}
export default chartflowService;