// this component defines the layout structure of the application with a sidebar, top bar, main content area, and footer.
import {
  Outlet, // Outlet is used to render child routes
} from "react-router-dom";
import { Col, Row, Layout } from "antd";
import React from "react";
import TopBar from "../TopBar"; // Importing the TopBar component
import SideBar from "../SideBar"; // Importing the SideBar component

const { Content, Footer } = Layout; // Destructuring Layout components

// Definition of the MainApp functional component
function MainApp() {
  return (
    <Layout className="app-layout">
      {" "}
      {/* Outer layout for the entire application */}
      <SideBar /> {/* Rendering the SideBar component */}
      <Layout>
        {" "}
        {/* Nested layout for content and top bar */}
        <TopBar /> {/* Rendering the TopBar component */}
        <Content className="app-main">
          {" "}
          {/* Content area */}
          <div className="app-main-wrapper">
            <Outlet /> {/* Rendering child routes */}
          </div>
          {/* Footer section */}
          <Footer>
            {/* Row with space between items */}
            <Row justify="space-between">
              {/* Column for the powered by statement */}
              <Col>Powered by Witt Solutions ©2022</Col>
            </Row>
          </Footer>
        </Content>
      </Layout>
    </Layout>
  );
}

// Exporting the MainApp component as default
export default MainApp;
