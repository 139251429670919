import axios from "axios";
import config from "../utils/consts.config.json";
import authHeader from "./AuthHeader";

const API_URL = config.API_URL;

const updateSetupUser = (data) => {
    return axios
        .patch(API_URL + "ldap", data, {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

const getSetupUser = () => {
    return axios
        .get(API_URL + "ldap", {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};
// eslint-disable-next-line
export default {
    updateSetupUser,
    getSetupUser
};
