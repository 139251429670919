import React, {useCallback, useEffect, useState} from 'react';
import {Modal, Form, Select} from 'antd';
import {Trans} from "react-i18next";
import {
    editMeasuringPointStatus, getDeviceByName, getMeasuringPointsList,
} from "redux/actions/MeasuringPoint.action";
import {useDispatch, useSelector} from "react-redux";
import i18n from 'i18next';
import PropTypes from "prop-types";

const getCurrentLng = () => i18n.resolvedLanguage;

const EditStatus = (props) => {
    const {visible, setVisible, data} = props;
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const dataDevice = useSelector(state => state.measuringPoint.dataDevice);
    const [listStatus, setListStatus] = useState(false);

    useEffect(() => {
        if (dataDevice) {
            if (dataDevice.Systemstatus) {
                const lang = getCurrentLng();
                const options = dataDevice.Systemstatus.map((element) => {
                    const langStatus = element.language.find(l => l.languageType.toLowerCase() === lang);
                    const label = langStatus ? langStatus.ValueName : element.ShortValueName;
                    return {
                        label: label,
                        id: label,
                        value: label,
                    }
                });
                setListStatus(options);
            }
        }
    }, [dataDevice]);

    useEffect(() => {
        if (data && visible) {
            if (data.device) dispatch(getDeviceByName(data.device));
            form.setFieldsValue({status: data.status});
        }
        // eslint-disable-next-line
    }, [visible]);

    // eslint-disable-next-line
    const onSubmit = useCallback((values) => {
        if (data) {
            dispatch(editMeasuringPointStatus(parseInt(data.id), values))
                .then(() => dispatch(getMeasuringPointsList()));
        }
        form.resetFields();
        setVisible(false);
    });
    // eslint-disable-next-line
    const closePopup = useCallback(() => {
        form.resetFields();
        setVisible(false);
    });

    return (
        <Modal
            open={visible}
            title={<Trans i18nKey="measuringPoints.edit.status"/>}
            okText="Save"
            onOk={form.submit}
            onCancel={closePopup}>
            <Form form={form}
                  onFinish={onSubmit}>
                <Form.Item labelCol={{span: 9}} label={<Trans i18nKey="measuringPoints.list.status"/>}
                           labelAlign="left"
                           name="status"
                           rules={[
                               {
                                   required: true,
                                   message: 'Please select a status',
                               },
                           ]}>
                    <Select
                        placeholder="Select a status"
                        options={listStatus}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
}
EditStatus.propTypes = {
    visible:PropTypes.bool,
    setVisible:PropTypes.func,
    data: PropTypes.object,

}
export default EditStatus;
