// Importing necessary modules and styles
import "./index.less";
import React from "react";
import {
  Col,
  Row,
  Form,
  Select,
  TimePicker,
  DatePicker,
  InputNumber,
  Checkbox,
} from "antd";
import moment from "moment";
import { ClockCircleOutlined } from "@ant-design/icons";

// Definition of the BottomFilter functional component
const BottomFilter = () => {
  // Define formats for time and date
  const timeFrameFormat = "HH:mm";
  const dateFormat = "YYYY-MM-DD";

  // Return JSX representing the BottomFilter component
  return (
    <div className="bottom-filter">
      {/* Row for the filters */}
      <Row className="bottom-filter-row">
        {/* First column */}
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          className="bottom-filter-row-col"
        >
          {/* Filter card for short-time forecast */}
          <div className="filter-card">
            {/* Title for short-time forecast */}
            <span className="filter-card-title">Forecast Short-time</span>
            {/* Row for options */}
            <Row justify="start">
              <Col span={18}>
                {/* Checkbox for Short-time average */}
                <Form.Item name="Short_time_average" valuePropName="checked">
                  <Checkbox>Short-time average</Checkbox>
                </Form.Item>
                {/* Checkbox for Short-time average of absolute */}
                <Form.Item
                  name="Short_time_average_absolute"
                  valuePropName="checked"
                >
                  <Checkbox>Short-time average of absolute</Checkbox>
                </Form.Item>
              </Col>
              <Col span={6}>
                {/* Checkbox for Display */}
                <Form.Item name="short_time_display" valuePropName="checked">
                  <Checkbox>Display</Checkbox>
                </Form.Item>
                {/* Select dropdown for display color */}
                <Form.Item name="display_color">
                  <Select size="small">
                    <Select.Option
                      value="#0058B3"
                      style={{ backgroundColor: "#0058B3" }}
                    />
                    <Select.Option
                      value="#C70039"
                      style={{ backgroundColor: "#C70039" }}
                    />
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {/* Row for time and date inputs */}
            <Row>
              <Col span={8}>
                {/* Time frame start */}
                <Form.Item
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  label="Time frame"
                  name="short_time_frame_start"
                >
                  <TimePicker
                    size="small"
                    defaultValue={moment("00:00", timeFrameFormat)}
                    format={timeFrameFormat}
                  />
                </Form.Item>
                {/* Time frame end */}
                <Form.Item name="short_time_frame_end">
                  <TimePicker
                    size="small"
                    defaultValue={moment("00:00", timeFrameFormat)}
                    format={timeFrameFormat}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                {/* Date range start */}
                <Form.Item
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  label="Date range"
                  name="short_time_date_range_start"
                >
                  <DatePicker size="small" />
                </Form.Item>
                {/* Date range end */}
                <Form.Item name="short_time_date_range_end">
                  <DatePicker size="small" />
                </Form.Item>
              </Col>
              <Col span={8}>
                {/* Step Minutes */}
                <Form.Item
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  label="Step Minutes"
                  name="short_time_step"
                >
                  <InputNumber
                    size="small"
                    min={1}
                    prefix={<ClockCircleOutlined />}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Col>
        {/* Second column */}
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          className="bottom-filter-row-col"
        >
          {/* Filter card for long-time forecast */}
          <div className="filter-card">
            {/* Title for long-time forecast */}
            <span className="filter-card-title">Forecast Long-time</span>
            {/* Row for options */}
            <Row justify="start">
              <Col span={15}>
                {/* Checkbox for Long-time average */}
                <Form.Item name="long_time_average" valuePropName="checked">
                  <Checkbox>Long-time average</Checkbox>
                </Form.Item>
              </Col>
              <Col span={5}>
                {/* Checkbox for Display */}
                <Form.Item name="long_time_display" valuePropName="checked">
                  <Checkbox>Display</Checkbox>
                </Form.Item>
              </Col>
              <Col span={4}>
                {/* Select dropdown for display color */}
                <Form.Item name="long_time_color">
                  <Select size="small">
                    <Select.Option
                      value="#0058B3"
                      style={{ background: "#0058B3" }}
                    />
                    <Select.Option
                      value="#C70039"
                      style={{ background: "#C70039" }}
                    />
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {/* Row for additional options */}
            <Row>
              <Col span={24}>
                {/* Checkbox for Long-time average of absolute */}
                <Form.Item
                  name="long-time-average-absolute"
                  valuePropName="checked"
                  wrapperCol={{ span: 16 }}
                >
                  <Checkbox>Long-time average of absolute</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            {/* Row for date range and step days */}
            <Row justify="start">
              <Col span={12}>
                {/* Date range start */}
                <Form.Item
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  label="Date range"
                  name="long_time_date_range_start"
                >
                  <DatePicker size="small" />
                </Form.Item>
                {/* Date range end */}
                <Form.Item
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  name="long_time_date_range_end"
                >
                  <DatePicker format={dateFormat} size="small" />
                </Form.Item>
              </Col>
              <Col span={12}>
                {/* Step Days */}
                <Form.Item
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  label="Step Days"
                  name="long-time-step"
                >
                  <InputNumber
                    size="small"
                    min={1}
                    prefix={<ClockCircleOutlined />}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

// Exporting the BottomFilter component as default
export default BottomFilter;
