import axios from "axios";
import config from "../utils/consts.config.json";
import authHeader from "./AuthHeader";

const API_URL = config.API_URL;

/**
 * Returns created Filter.
 *
 * @param {object} data The data of filter to create.
 * @return {json} created filter.
 */
const addFilterService = (data) => {
    return axios
        .post(API_URL + "data-analysis-filters", data, {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * Returns All Filters by page reference.
 *
 * @param {string} filterRef The reference of filters.
 * @return {json}  array of Filters.
 */
const getAllFilterService = (filterRef) => {
    return axios
        .get(API_URL + "data-analysis-filters" +
            "?filter=%7B%0A%20%20%20%20%22where%22%3A%20%7B%0A%20%20%20%20%20%20%20%20%22filterRef%22%3A%20%22" + filterRef + "%22%0A%20%20%20%20%7D%0A%7D", {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * edit filter by id
 *
 * @param {number} id Id of filter to patch
 * @param {json} data filter data
 * @return {json}  array of Lines.
 */
const patchFilterService = (id, data) => {
    return axios
        .patch(API_URL + "data-analysis-filters/" + id, data, {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;

        });
};

/**
 *  Delete filter by name
 * @param {number} id Id of filter to delete
 * @return {json}  array of Lines.
 */
const deleteFilterService = (id) => {
    return axios
        .delete(API_URL + "data-analysis-filters/" + id, {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;

        });
};

/**
 * Returns All Lines.
 *
 * @return {json}  array of Lines.
 */
const getAllLinesService = () => {
    return axios
        .get(API_URL + "lines", {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * Returns All Stations.
 *
 * @return {json}  array of Stations.
 */
const getAllStationsService = () => {
    return axios
        .get(API_URL + "stations", {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * Returns All Rail Sections.
 *
 * @return {json}  array of Rail Sections.
 */
const getAllRailSectionsService = () => {
    return axios
        .get(API_URL + "rail-sections", {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * Returns All Measurement Points.
 *
 * @return {json}  array of Measurement Points.
 */
const getAllMeasurementPointsService = () => {
    return axios
        .get(API_URL + "measuring-points", {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

const dataAnalysis = {
    addFilterService,
    getAllFilterService,
    patchFilterService,
    deleteFilterService,
    getAllLinesService,
    getAllStationsService,
    getAllRailSectionsService,
    getAllMeasurementPointsService,
}
export default dataAnalysis;
