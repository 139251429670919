import { REQUEST } from "redux/constants/ActionTypes";
import chartflowService from "services/chartFlow.service";
import {
    RECEIVE_CHART_FLOW_RAILS

} from "../constants/ChartFlowType";

///////////////////////////////////////////////////////////////////////
// Rails
///////////////////////////////////////////////////////////////////////

export const getAllRailsCharts = (nodeelements) => dispatch => {
    dispatch(request());
    return chartflowService.getAllRails(nodeelements)
        .then((json) => dispatch(receiveLine(json)));
}

export const receiveLine = (json) => ({
    type: RECEIVE_CHART_FLOW_RAILS,
    payload: { data: json }
});
export const request = () => ({
    type: REQUEST
});