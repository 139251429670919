import {Navigate, useLocation} from 'react-router-dom';
import PropTypes from "prop-types";

const PrivateRoute = ({children}) => {

    const location = useLocation();
    const token = localStorage.getItem("token");

    if (!token) {
        return (<Navigate to='/login'/>);
    } else {
        const permission = JSON.parse(localStorage.getItem("permission"));
        const route = permission.find((r) =>  location.pathname.includes(r));
        if (!route) {
            return (<Navigate to='/vide'/>);
        }
    }

    return (children);
}
PrivateRoute.propTypes = {
    children:PropTypes.node
}
export default PrivateRoute;
