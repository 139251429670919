import {SOFTWARE_INFO_SUCCESS, SOFTWARE_INFO_FAIL} from "../constants/SoftwareInfoType"; // Importing action type constants

// Initial state for the softwareInfo reducer
const initialState = {
    softwareInfo: {
        Lizenz: "", // License information
        LizenzNO: "", // License number
        Device: "", // Device information
        User: "", // User information
        Version: "" // Software version
    },
    message: "" // Error message
};

// Reducer function for softwareInfo
// eslint-disable-next-line
export default function softwareInfo(state = initialState, action) {
    const {type, payload} = action; // Destructuring action object
    switch (type) {
        case SOFTWARE_INFO_SUCCESS: // Handling the success action
            return {
                ...state, // Copying the current state
                softwareInfo: payload.softwareInfo, // Updating softwareInfo with the new data from the action payload
            };
        case SOFTWARE_INFO_FAIL: // Handling the fail action
            return {message: "error"}; // Returning a message indicating failure
        default:
            return state; // Returning the current state if action type doesn't match
    }
}
