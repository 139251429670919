import {Button, Row, Space, Table, Modal, Form, Input, Transfer, Select, Col} from 'antd';
import React, {useEffect, useState} from "react";
import {Trans} from "react-i18next";
import {swalDelete} from 'utils/swal';
import {
    deleteMultipleStations,
    deleteStation,
    editStation,
    getAllLine,
    getAllRails,
    getAllStations,
    getStationById,
    postsStation
} from 'redux/actions/SCADAInfra.action';
import {useDispatch, useSelector} from 'react-redux';
import stationIcon from '../../../../../assets/icons/ic_train.png';
import tramIcon from '../../../../../assets/icons/ic_tram.png';
import subwayIcon from '../../../../../assets/icons/ic_subway.png';

const StationsList = () => {
    ///////////////////////////////////////////////////////////////////////
    // Station LIST TABLE
    ///////////////////////////////////////////////////////////////////////
    const [selectStation, setSelectStation] = useState();
    const [visibleModal_NewStation, setVisibleModal_NewStation] = useState(false);
    const [visibleModal_EditStation, setVisibleModal_EditStation] = useState(false);
    const [formAddStation] = Form.useForm();
    const [formEditStation] = Form.useForm();
    const dispatch = useDispatch();
    useEffect(() => {

            dispatch(getAllStations());
            dispatch(getAllLine());
            // eslint-disable-next-line
        },  // eslint-disable-next-line
        []);
    const linesList = useSelector(state => state.scadaInfra.listLine);
    const stationList = useSelector(state => state.scadaInfra.listStation);


    const [targetKeys, setTargetKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const onChange = (nextTargetKeys, direction, moveKeys) => {
        setTargetKeys(nextTargetKeys);
    };
    const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };

    const onScroll = (direction, e) => {

    };

    ///////////////////////////////////////////////////////////////////////
    //        Station LIST COLUMN
    ///////////////////////////////////////////////////////////////////////

    const StationColumnsSection = [
        {
            title: <Trans i18nKey="setup.ScadaInfra.LineNumberColumn"/>,
            dataIndex: 'number',
            width: 120,
        },
        {
            title: <Trans i18nKey="setup.ScadaInfra.LineNameColumn"/>,
            dataIndex: 'name',
            width: 180,
        },
        {
            title: <Trans i18nKey="setup.ScadaInfra.actionColumn"/>,
            key: 'action',
            width: 100,
            render: (_, record) => (
                <Space size="middle">
                    <Button onClick={() => showModal_EditStation(record)} size={'small'}><Trans i18nKey="button.Edit"/></Button>
                    <Button onClick={() => (deleteStationAction(record))} size={'small'}><Trans
                        i18nKey="button.del"/></Button>
                </Space>
            ),
        },
    ];

    ///////////////////////////////////////////////////////////////////////
    //           Station LIST CHECKBOXs
    ///////////////////////////////////////////////////////////////////////

    const rowSelectionSectionStation = {
        onChange: (selectedRowKeys, selectedRows) => {
            if (selectedRows.length > 0) {
                setSelectStation(selectedRows);
            } else {
                setSelectStation();
            }
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === 'Disabled User',
            // Column configuration not to be checked
            name: record.name,
        }),
    };
    ///////////////////////////////////////////////////////////////////////
    //           Station MODAL
    ///////////////////////////////////////////////////////////////////////
    const showModal_NewStation = () => {
        setTargetKeys([]);
        setVisibleModal_NewStation(true);
    };

    const handleCancelModal_NewStation = () => {
        setVisibleModal_NewStation(false);
        formAddStation.resetFields();
    };

    const onFinishForm_NewStation = () => {
        const data = formAddStation.getFieldsValue();
        const StationDataAdd = {
            station: {
                name: data.name,
                number: data.number,
                icon: data.icon
            },
            lines: data.lines
        }
        dispatch(postsStation(StationDataAdd));
        setVisibleModal_NewStation(false);
        formAddStation.resetFields();
    }
    const stationDetail = useSelector(state => state.scadaInfra.stationById);
    useEffect(() => {
        setTargetKeys([])
        if (stationDetail.lines) {
            let lineIdArray = stationDetail.lines.map((lineid) => {

                return (lineid.id);
            })
            setTargetKeys(lineIdArray);
            formEditStation.setFieldsValue(stationDetail);
        } else {
            setTargetKeys([]);

            formEditStation.setFieldsValue(stationDetail);
        }
        // eslint-disable-next-line
    }, [stationDetail]);


    const showModal_EditStation = (data) => {
        setTargetKeys([])
        dispatch(getAllLine());
        dispatch(getAllStations());
        dispatch(getStationById(data.id));
        setVisibleModal_EditStation(true);
    };

    const handleCancelModal_EditStation = () => {
        formEditStation.resetFields();
        setVisibleModal_EditStation(false);
        setSelectedKeys([]);
    };

    const onFinishForm_EditStation = (id) => {
        const formDataStationEdit = formEditStation.getFieldsValue();
        const StationDataEdit = {
            station: {
                name: formDataStationEdit.name,
                number: formDataStationEdit.number,
                icon: formDataStationEdit.icon
            },
            lines: targetKeys
        }

        dispatch(editStation(formEditStation.getFieldValue().id, StationDataEdit));
        setVisibleModal_EditStation(false);
        formEditStation.resetFields();
        setSelectedKeys([]);
    }

    const deleteStationAction = (id) => {
        swalDelete("Are you sure you want to delete this station ?").fire({})
            .then((result) => {
                if (result.isConfirmed) {
                    dispatch(deleteStation(id.id));
                    dispatch(getAllRails());
                }

            })
    }
    const deleteMultipleStationAction = () => {
        if (selectStation) {
            let stationsIds = selectStation.map((rail) => {
                return (rail.id);
            })
            let stations = {
                "stations": stationsIds
            }

            if (stationsIds.length > 1) {
                swalDelete("Are you sure you want to delete these stations ?").fire({})
                    .then((result) => {
                        if (result.isConfirmed) {
                            dispatch(deleteMultipleStations(stations));
                            setSelectStation();
                        }
                    })
            } else {
                swalDelete("Are you sure you want to delete this station ?").fire({})
                    .then((result) => {
                        if (result.isConfirmed) {
                            dispatch(deleteMultipleStations(stations));
                            setSelectStation();
                        }
                    })
            }


        }
    }
    return (
        <div className='station-list'>
            <h2>Stations List</h2>
            <Row className='station-list-top-bar'>
                <Col>
                    <Button htmlType='button' onClick={() => (showModal_NewStation())}><Trans
                        i18nKey="button.new"/></Button>
                </Col>
                <Col>

                    {selectStation ?
                        <Button htmlType='button' onClick={() => (deleteMultipleStationAction())}><Trans
                            i18nKey="button.del"/></Button>
                        : <Button htmlType='button' disabled={true}><Trans i18nKey="button.del"/></Button>}

                </Col>
            </Row>
            <Table
                rowSelection={{
                    type: 'checkbox',
                    ...rowSelectionSectionStation,
                }}
                bordered
                columns={StationColumnsSection}
                dataSource={stationList}
                pagination={true}
                scroll={{y: 500}}
                className="mp-location-rails-table"
                rowKey="id"

            />

            {/************* Modal : New Station *************/}

            <Modal
                title="New Station"
                open={visibleModal_NewStation}
                onOk={formAddStation.submit}
                okText="Save"
                onCancel={handleCancelModal_NewStation}
            >
                <Form
                    labelCol={{
                        xs: {span: 24},
                        sm: {span: 24},
                        md: {span: 6},
                        lg: {span: 6},
                        xl: {span: 6},
                    }}
                    wrapperCol={{
                        xs: {span: 24},
                        sm: {span: 24},
                        md: {span: 24},
                        lg: {span: 24},
                        xl: {span: 24},
                    }}
                    layout="horizontal"
                    form={formAddStation}
                    labelAlign='left'
                    onFinish={() => onFinishForm_NewStation()}
                >
                    <Form.Item
                        name="name"
                        label="Station Name"
                        rules={[
                            {
                                required: true,
                                whitespace: true,
                                message: 'Please input the station name',
                            },
                        ]}
                    >
                        <Input placeholder=""/>
                    </Form.Item>

                    <Form.Item
                        name="number"
                        label="Station Number"
                        rules={[
                            {
                                required: true,
                                whitespace: true,
                                message: 'Please input the station number',
                            },
                        ]}
                    >
                        <Input placeholder=""/>
                    </Form.Item>

                    <Form.Item

                        wrapperCol={{
                            xs: {span: 4},
                            sm: {span: 4},
                            md: {span: 4},
                            lg: {span: 4},
                            xl: {span: 4},
                        }}
                        name="icon"
                        label="Station Icon"
                        rules={[
                            {
                                required: true,
                                message: 'Please select the station icon',
                            },
                        ]}
                    >
                        <Select>
                            <Select.Option value='ic_train'><img src={stationIcon} alt='signal'
                                                                    style={{height: 20, width: 20}}/></Select.Option>
                            <Select.Option value='ic_tram'> <img src={tramIcon} alt='signal'
                                                                  style={{height: 20, width: 20}}/></Select.Option>
                            <Select.Option value='ic_subway'> <img src={subwayIcon} alt='signal'
                                                                    style={{height: 20, width: 20}}/></Select.Option>
                        </Select>
                    </Form.Item>
                    <Row justify='space-around'><h3>Associate here the stations to their lines</h3></Row>
                    <Row justify='center'>
                        <Form.Item name="lines" justify='center' initialValue={[]}
                                   rules={[
                                       {
                                           required: true,
                                           message: 'Please associate lines to the station',
                                       },
                                   ]}
                        >
                            <Transfer
                                dataSource={linesList}
                                titles={['Lines', 'Associated Lines']}
                                targetKeys={targetKeys}
                                selectedKeys={selectedKeys}
                                onChange={onChange}
                                onSelectChange={onSelectChange}
                                onScroll={onScroll}
                                render={(item) => item.name}
                                operations={['add', 'remove']}
                                rowKey={record => record.id}
                            />

                        </Form.Item>
                    </Row>
                </Form>
            </Modal>

            {/************* Modal : Edit Station *************/}

            <Modal
                title="Edit Station"
                open={visibleModal_EditStation}
                onOk={formEditStation.submit}
                okText="Save"
                onCancel={handleCancelModal_EditStation}
            >
                <Form
                    labelCol={{
                        xs: {span: 24},
                        sm: {span: 24},
                        md: {span: 6},
                        lg: {span: 6},
                        xl: {span: 6},
                    }}
                    wrapperCol={{
                        xs: {span: 24},
                        sm: {span: 24},
                        md: {span: 24},
                        lg: {span: 24},
                        xl: {span: 24},
                    }}
                    layout="horizontal"
                    form={formEditStation}
                    labelAlign='left'
                    onFinish={() => onFinishForm_EditStation()}
                >
                    <Form.Item
                        name="name"
                        label="Station Name"
                        rules={[
                            {
                                required: true,
                                whitespace: true,
                                message: 'Please input the station name',
                            },
                        ]}
                    >
                        <Input placeholder=""/>
                    </Form.Item>
                    <Form.Item
                        name="number"
                        label="Station Number"
                        rules={[
                            {
                                required: true,
                                whitespace: true,
                                message: 'Please input the station number',
                            },
                        ]}
                    >
                        <Input placeholder=""/>
                    </Form.Item>
                    <Form.Item
                        name="icon"
                        label="Station Icon"
                        wrapperCol={{
                            xs: {span: 4},
                            sm: {span: 4},
                            md: {span: 4},
                            lg: {span: 4},
                            xl: {span: 4},
                        }}
                        rules={[
                            {
                                required: true,
                                message: 'Please select the station icon',
                            },
                        ]}
                    >
                        <Select>
                            <Select.Option value='ic_train'><img src={stationIcon} alt='signal'
                                                                    style={{height: 20, width: 20}}/></Select.Option>
                            <Select.Option value='ic_tram'> <img src={tramIcon} alt='signal'
                                                                  style={{height: 20, width: 20}}/></Select.Option>
                            <Select.Option value='ic_subway'> <img src={subwayIcon} alt='signal'
                                                                    style={{height: 20, width: 20}}/></Select.Option>
                        </Select>
                    </Form.Item>
                    <Row justify='space-around'><h3>Associate here the stations to their lines</h3></Row>
                    <Row justify='center'>
                        <Form.Item name="lines" justify='center' initialValue={[]} rules={[
                            {
                                required: true,
                                message: 'Please associate lines to the station',
                            },
                        ]}>
                            <Transfer
                                dataSource={linesList}
                                titles={['Lines', 'Associated Lines']}
                                targetKeys={targetKeys}
                                selectedKeys={selectedKeys}
                                onChange={onChange}
                                onSelectChange={onSelectChange}
                                onScroll={onScroll}
                                render={(item) => item.name}
                                operations={['add', 'remove']}
                                rowKey={record => record.id}
                            />

                        </Form.Item>
                    </Row>
                </Form>
            </Modal>
        </div>
    );
}

export default StationsList;
