import {
    RECEIVE_SUBSTATION,
    SUBSTATION_POST_SUCCESS,
    SUBSTATION_POST_FAILURE,
    SUBSTATION_EDIT_SUCCESS,
    SUBSTATION_EDIT_FAILURE,
    RECEIVE_RAILS_SUBSTATION_ID,
    RECEIVE_MP_RAIL_ID,
    SUBSTATION_DELETE_SUCCESS,
    RECEIVE_RAIL_SUCCESS,
    RECEIVE_RAIL_FAILURE,
    RSS_DETAILS_FAILURE,
    RSS_DETAILS_SUCCESS,
    PATCH_RS_VLD,
    RAIL_DETAILS_SUCCESS,
    RAIL_DETAILS_FAILURE,
    PATCH_RAIL_MP,
} from "../constants/SCADAEnergyType"; // Importing action type constants related to SCADA energy
import {MEASURING_POINT_GET_MINIMAL_LIST} from "../constants/MeasuringPointTypes"; // Importing action type constants related to measuring points

const initialState = {
    substations: [], // List of substations
    railSections: [], // List of rail sections
    measuringpointList: [], // List of measuring points
    success: false, // Success flag
    error: "", // Error message
    railDetails: {}, // Details of rail
    rectifierSubstationDetails: {}, // Details of rectifier substation
};

// Reducer function for SCADA energy
// eslint-disable-next-line
export default function scadaEnergy(state = initialState, action) {
    const {type, payload} = action; // Destructuring action object
    const handleSuccess = (state, payload) => ({
        ...state,
        success: payload.success,
    });

    const handleFailure = (state, payload) => ({
        ...state,
        error: payload.error,
    });

    const handleMeasuringPoints = (state, payload) => ({
        ...state,
        measuringpointList: payload.data,
    });
    switch (type) {
        case RECEIVE_SUBSTATION: {
            return {
                ...state,
                substations: payload.data, // Updating list of substations with data from the action payload
            };
        }
        case SUBSTATION_POST_SUCCESS: {
            return {
                ...state,
                success: payload.success, // Updating success flag with data from the action payload
            };
        }
        case SUBSTATION_POST_FAILURE: {
            return handleMeasuringPoints(state, payload);// Updating measuring point list with data from the action payload
        }
        // Handling substation edit actions
        case SUBSTATION_EDIT_SUCCESS: {
            return handleSuccess(state,payload);// Updating success flag with data from the action payload
        }
        case SUBSTATION_EDIT_FAILURE: {
            return handleFailure(state,payload);// Updating error message with data from the action payload
        }
        case RECEIVE_RAILS_SUBSTATION_ID: {
            return {
                ...state,
                railSections: payload.data, // Updating list of rail sections with data from the action payload
                measuringpointList: [], // Resetting measuring point list
            };
        }
        case RECEIVE_MP_RAIL_ID: {
            return {
                ...state,
                measuringpointList: payload.data, // Updating measuring point list with data from the action payload
            };
        }
        case SUBSTATION_DELETE_SUCCESS: {
            return {
                ...state,
                success: payload.success, // Updating success flag with data from the action payload
                railSections: [], // Resetting rail sections
                measuringpointList: [], // Resetting measuring points
            };
        }
        case RECEIVE_RAIL_SUCCESS: {
            return {
                ...state,
                success: payload.success, // Updating success flag with data from the action payload
                railSections: payload.data, // Updating list of rail sections with data from the action payload
            };
        }
        case RECEIVE_RAIL_FAILURE: {
            return handleFailure(state,payload);// Updating error message with data from the action payload
        }
        case RAIL_DETAILS_SUCCESS: {
            return {
                ...state,
                railDetails: payload.data, // Updating rail details with data from the action payload
            };
        }
        case RAIL_DETAILS_FAILURE: {
            return handleFailure(state,payload);// Updating error message with data from the action payload
        }
        case MEASURING_POINT_GET_MINIMAL_LIST: {
            return handleMeasuringPoints(state, payload);// Updating measuring point list with data from the action payload

        }
        case PATCH_RAIL_MP: {
            return handleSuccess(payload.success);// Updating success flag with data from the action payload
        }
        case RSS_DETAILS_SUCCESS: {
            return {
                ...state,
                rectifierSubstationDetails: payload.data, // Updating rectifier substation details with data from the action payload
            };
        }
        case RSS_DETAILS_FAILURE: {
            return handleFailure(state,payload);// Updating error message with data from the action payload
        }
        case PATCH_RS_VLD: {
            return handleSuccess(state,payload);// Updating success flag with data from the action payload
        }
        default:
            return state; // Returning the current state if action type doesn't match
    }
}
