/*
**************** IMPORTANT ********************************
*
* FOR ALL CHART COMPONENTS IN THIS PROJECT PLEASE READ OFFICIAL DOCUMENTATION 
* THESE FUNCTIONS ARE UNDER DEVELOPMENT AND NOT FINISHED YET
* TO_DO_: OPTIMIZE CODE + MAKE IT MORE MAINTAINABLE
*  
* docs: https://reactflow.dev/learn 
*
*/
import {
  ApiFilled,
  RightSquareFilled,
  RightSquareOutlined,
} from "@ant-design/icons";
import React, { useCallback } from "react";
import { getBezierPath, getEdgeCenter, useStore } from "react-flow-renderer";
import { Link } from "react-router-dom";
import "./chartFlow.less";
import { padLeadingZeros } from "utils/function";
import { getEdgeParams } from "./utils";
import PropTypes from "prop-types";

export default function CustomEdge({
  id,
  style = { backgroundColor: "red", color: "red" }, // Default style for the edge
  data = {}, // Custom data for the edge
  source, // ID of the source node
  target, // ID of the target node
  sourceX, //Possible values top,bottom,right,left
  sourceY, //Possible values top,bottom,right,left
  targetX, //Possible values top,bottom,right,left
  targetY, //Possible values top,bottom,right,left
}) {
  // Retrieve the source and target nodes using React Flow store
  const sourceNode = useStore(
    useCallback((store) => store.nodeInternals.get(source), [source])
  );
  const targetNode = useStore(
    useCallback((store) => store.nodeInternals.get(target), [target])
  );

  // If source or target nodes are not available, return null
  if (!sourceNode || !targetNode) {
    return null;
  }

  // Calculate edge parameters using helper function
  const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(
    sourceNode,
    targetNode
  );
  const foreignObjectSize = 40;

  const topBottom=(sourcePos)=>{
    return sourcePos === "top" || sourcePos === "bottom"
  }

  const leftRight=(sourcePos)=>{
    return sourcePos === "right" || sourcePos === "left"
  }
  const returnPathPlus=(sourcePos)=>{
    return getBezierPath({
      sourceX: topBottom(sourcePos) ? sx + 10 : sx,
      sourceY: leftRight(sourcePos) ? sy + 10 : sy,
      sourcePosition: sourcePos,
      targetPosition: targetPos,
      targetX: topBottom(sourcePos) ? tx + 10 : tx,
      targetY: leftRight(sourcePos) ? ty + 10 : ty,
      borderRadius: 10,
    })
  }
  const returnPathMinus=(sourcePos)=>{
    return  getBezierPath({
      sourceX: topBottom(sourcePos)? sx - 10 : sx,
      sourceY: leftRight(sourcePos)? sy - 10 : sy,
      sourcePosition: sourcePos,
      targetPosition: targetPos,
      targetX: topBottom(sourcePos)? tx - 10 : tx,
      targetY: leftRight(sourcePos)? ty - 10 : ty,
      borderRadius: 10,
    })
  }
  // Render different types of edges based on the data
  if (data.left) {
    // Render edge with label on the left side
    const edgePath =returnPathPlus(sourcePos);

    const [edgeCenterX, edgeCenterY] = getEdgeCenter({
      sourceX,
      sourceY,
      targetX,
      targetY,
    });

    const random = Math.floor(Math.random() * 5);

    return (
      <>
        {/* Render the edge path */}
        <path
          id={id.toString()}
          style={style}
          className="react-flow__edge-path"
          d={edgePath}
          strokeWidth={5}
        />
        {/* Render foreign object for custom content */}
        <foreignObject
          width={120}
          height={100}
          x={edgeCenterX - foreignObjectSize * 2.7}
          y={edgeCenterY - foreignObjectSize / 2.7}
          className="foreign-object"
          requiredExtensions="http://www.w3.org/1999/xhtml"
        >
          <div>
            {/* Render custom content */}
            <img
                alt={`${id.toString()}.png`}
              style={{
                height: "10px",
                width: "10px",
                marginLeft: "70%",
                marginTop: "20%",
              }}
              src={`/assets/icons/ic_signal_cellular_${random}_bar.png`}
            />
            {data?.elements?.mp?.map((mp) => (
              <Link
                key={mp.id}
                to={{
                  pathname: `/scada/measuring-points/${mp.id}`,
                  state: { tabActive: "1" },
                }}
              >
                <div>
                  <p style={{ fontSize: 7 }}>
                    {mp.icon === "1" && <RightSquareFilled />}
                    {mp.icon === "2" && <RightSquareOutlined />}
                    {mp.icon === "3" && <ApiFilled />}
                    {"MP " +
                      padLeadingZeros(mp.id, 4) +
                      " | " +
                      mp.device_name +
                      "_" +
                      mp.protocol_name +
                      "_" +
                      mp.device_identification}
                  </p>
                </div>
              </Link>
            ))}
          </div>
        </foreignObject>
        {/* Render text along the path */}
        <text>
          <textPath
            href={`#${id}`}
            style={{ fontSize: "8px" }}
            startOffset="50%"
            textAnchor="middle"
          ></textPath>
        </text>
      </>
    );
  } else {
    // Render edge with label on the right side
    const random = Math.floor(Math.random() * 5);
    const edgePath = returnPathMinus(sourcePos);

    const [edgeCenterX, edgeCenterY] = getEdgeCenter({
      sourceX,
      sourceY,
      targetX,
      targetY,
    });

    return (
      <>
        {/* Render the edge path */}
        <path
          id={id.toString()}
          style={style}
          className="react-flow__edge-path"
          d={edgePath}
        />
        {/* Render foreign object for custom content */}
        <foreignObject
          width={120}
          height={100}
          x={edgeCenterX + foreignObjectSize * 0.3}
          y={edgeCenterY - foreignObjectSize / 1.5}
          className="edgebutton-foreignobject"
          requiredExtensions="http://www.w3.org/1999/xhtml"
        >
          <div>
            {/* Render custom content */}
            <img
                alt={`${id.toString()}.png`}
              style={{ height: "10px", width: "10px", marginLeft: "10%" }}
              src={`/assets/icons/ic_signal_cellular_${random}_bar.png`}
            />
            {data?.elements?.mp?.map((mp) => (
              <Link
                key={mp.id}
                to={{
                  pathname: `/scada/measuring-points/${mp.id}`,
                  state: { tabActive: "1" },
                }}
              >
                <div style={{ marginLeft: "10%" }}>
                  <p style={{ fontSize: 7 }}>
                    {mp.icon === "1" && <RightSquareFilled />}
                    {mp.icon === "2" && <RightSquareOutlined />}
                    {mp.icon === "3" && <ApiFilled />}
                    {"MP " +
                      padLeadingZeros(mp.id, 4) +
                      " | " +
                      mp.device_name +
                      "_" +
                      mp.protocol_name +
                      "_" +
                      mp.device_identification}
                  </p>
                </div>
              </Link>
            ))}
          </div>
        </foreignObject>
        {/* Render text along the path */}
        <text>
          <textPath
            href={`#${id}`}
            style={{ fontSize: "8px" }}
            startOffset="50%"
            textAnchor="middle"
          ></textPath>
        </text>
      </>
    );
  }
}
CustomEdge.propTypes={
  id:PropTypes.string,
  style: PropTypes.object,
  data: PropTypes.object,
  source: PropTypes.string,
  target: PropTypes.string,
  sourceX: PropTypes.string,
  sourceY: PropTypes.string,
  targetX: PropTypes.string,
  targetY: PropTypes.string,
}