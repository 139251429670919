import {
    LOG_REPORT_POST_FAILURE,
    LOG_REPORT_POST_SUCCESS,
} from "../constants/LogReportTypes"; // Importing action type constants related to log reports

const initialState = {
    postLogReport: false, // Flag indicating if log report was successfully posted
    error: "" // Error message
};

// Reducer function for log reports
// eslint-disable-next-line
export default function logReport(state = initialState, action) {
    const { type, payload } = action; // Destructuring action object
    switch (type) {
        case LOG_REPORT_POST_SUCCESS: { // Handling successful posting of log report
            return {
                ...state,
                postLogReport: payload.postLogReport // Updating postLogReport flag with data from the action payload
            }
        }
        case LOG_REPORT_POST_FAILURE: { // Handling failure in posting log report
            return {
                ...state,
                error: payload.error // Updating error message with data from the action payload
            }
        }
        default:
            return state; // Returning the current state for unhandled actions
    }
}
