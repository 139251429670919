// eslint-disable-next-line
import {
  GET_USER_SETUP_SUCCESS, // Action type constant for successful retrieval of user setup
  PUT_USER_SETUP_SUCCESS, // Action type constant for successful update of user setup
} from "../constants/SetupUserType"; // Importing action type constants related to user setup
import { REQUEST } from "../constants/ActionTypes"; // Importing action type constant for request
import setupUserService from "services/SetupUser.service"; // Importing service for user setup
import Swal from "sweetalert2"; // Importing SweetAlert 2 for displaying alerts
import { swalSuccess } from "utils/swal"; // Importing custom Swal success function

// Action creator for getting user setup data
export const getSetupUser = (data) => (dispatch) => {
  dispatch(request()); // Dispatching a request action
  // Calling the setupUserService to get user setup data
  return setupUserService.getSetupUser().then(
    (data) => dispatch(getSetupUserSuccess(data)) // Dispatching success action with data received
  );
};

// Action creator for updating user setup data
export const updateSetupUser = (data) => (dispatch) => {
  dispatch(request()); // Dispatching a request action
  // Calling the setupUserService to update user setup data
  return setupUserService
    .updateSetupUser(data)
    .then(
      (json) => dispatch(updateSetupUserSuccess(json)) // Dispatching success action with updated data
    )
    .catch((error) => {
      console.log(error); // Logging error to console
      // Displaying error alert using SweetAlert 2
      Swal.fire({
        icon: "error",
        text: "Something went wrong!",
      });
    });
};

// Action creator for successful retrieval of user setup data
const getSetupUserSuccess = (data) => ({
  type: GET_USER_SETUP_SUCCESS, // Setting action type
  payload: { setupUser: data }, // Setting payload with setup user data
});

// Action creator for successful update of user setup data
const updateSetupUserSuccess = (json) => {
  // Displaying success alert using custom Swal function
  swalSuccess("The configuration is saved successfully").fire({});
  return {
    type: PUT_USER_SETUP_SUCCESS, // Setting action type
    payload: { setupUser: json }, // Setting payload with updated setup user data
  }
}

// Action creator for request action
export const request = () => ({
  type: REQUEST, // Setting action type
});
