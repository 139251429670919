import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
} from "../constants/AuthType"; // Importing action type constants for login, login failure, and logout

// Initial state for the authentication reducer
const initialState = {
    user: null, // User information
    isLoggedIn: false, // Flag indicating if the user is logged in
    token: null, // Authentication token
    role: null // Role of the user
}

// Reducer function for authentication
// eslint-disable-next-line
export default function auth(state = initialState, action) {
    const { type, payload } = action; // Destructuring action object
    switch (type) {
        // Handling login success action
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true, // Setting the logged-in state to true
                token: payload.data.token, // Setting the authentication token from the payload
                user: payload.data.user, // Setting the user information from the payload
                role: payload.data.user ? (payload.data.user.role || null) : null // Setting the user role
            };
        // Handling login failure action
        case LOGIN_FAIL:
        // Handling logout action
        case LOGOUT:
            return {
                ...state,
                isLoggedIn: false, // Setting the logged-in state to false
                user: null, // Clearing user information
            };
        default:
            return state; // Returning the current state for unhandled actions
    }
}
