import {REQUEST} from "../constants/ActionTypes";
import scadaEnergyService from "services/SCADAEnergy.service";
import {
    RECEIVE_SUBSTATION,
    SUBSTATION_POST_SUCCESS,
    SUBSTATION_POST_FAILURE,
    RAIL_SECTION_POST_SUCCESS,
    RAIL_SECTION_POST_FAILURE,
    SUBSTATION_EDIT_SUCCESS,
    SUBSTATION_EDIT_FAILURE,
    RAIL_SECTION_EDIT_SUCCESS,
    RAIL_SECTION_EDIT_FAILURE,
    SUBSTATION_DELETE_SUCCESS,
    SUBSTATION_DELETE_FAILURE,
    RAIL_SECTION_DELETE_SUCCESS,
    RAIL_SECTION_DELETE_FAILURE,
    RECEIVE_RAILS_SUBSTATION_ID,
    RECEIVE_MP_RAIL_ID,
    RECEIVE_RAIL_SUCCESS,
    RSS_DETAILS_SUCCESS,
    PATCH_RS_VLD,
    RAIL_DETAILS_SUCCESS,
    PATCH_RAIL_MP,
} from "../constants/SCADAEnergyType";
import Swal from 'sweetalert2';
import {swalSuccess} from "utils/swal"
import measuringPointService from "services/MeasuringPoint.service";

/**
 * ////////////////////////////////////////////////////////////
 *                  Substation Services
 * ///////////////////////////////////////////////////////////
 **/

/**
 * Returns List Of All Substations .
 *
 * @return {json}  array of Substations.
 */
export const getAllSubstations = () => dispatch => {
    dispatch(request());
    return scadaEnergyService.getAllSubstationsService()
        .then((json) => dispatch(receiveSubstations(json)));
};

/**
 * Returns List Of All Substations success - Redux Action .
 *
 * @return
 */
export const receiveSubstations = (json) => ({
    type: RECEIVE_SUBSTATION,
    payload: {data: json}
});

/**
 * Add New Substation.
 *
 * @param {string} data substation data.
 * @return {json}  added substation data.
 */
export const postsSubstation = data => dispatch => {
    dispatch(request);
    return scadaEnergyService.addSubstationService(data)
        .then(() => dispatch(substationPostSuccess(true), dispatch(getAllSubstations())))
        .catch((error) => dispatch(substationPostFailure(error)));
};

/**
 * Add New Substation success - Redux Action .
 *
 * @param {boolean} success substation data.
 */
export const substationPostSuccess = (success) => {
    // eslint-disable-next-line
    swalSuccess('The substation is successfully created').fire({})
       return{
            type: SUBSTATION_POST_SUCCESS,
            payload: {success}
        }};

/**
 * Add New Substation error - Redux Action .
 *
 * @param {boolean} error substation data.
 */
export const substationPostFailure = (error) => {
    Swal.fire({
        icon: 'error',
        text: 'error occured while creating the substation',
        // eslint-disable-next-line
    });
        return {
            type: SUBSTATION_POST_FAILURE,
            payload: {error}
        }};

/**
 * ////////////////////////////////////////////////////////////
 *                  Rail Section Services
 * ///////////////////////////////////////////////////////////
 **/

/**
 * Add New Rail Section.
 *
 * @param {json} data.
 * @return
 */
export const postRailSection = data => dispatch => {
    dispatch(request);
    return scadaEnergyService.addRailSectionService(data)
        .then(() => dispatch(RailSectionPostSuccess(true), dispatch(getRailSectionbySubstationId(data.substationId))))
        .catch((error) => dispatch(RailSectionPostFailure(error)));
};

/**
 * Add New Rail Section success - Redux Action ..
 *
 * @param {boolean} success.
 * @return
 */
export const RailSectionPostSuccess = (success) => {
// eslint-disable-next-line
    swalSuccess('The Rail is successfully created').fire({});
        return {
            type: RAIL_SECTION_POST_SUCCESS,
            payload: {success}
        }};

/**
 * Add New Rail Section error - Redux Action
 *
 * @param {boolean} error.
 * @return
 */
export const RailSectionPostFailure = (error) => {
    Swal.fire({
        icon: 'error',
        text: 'error occured while creating the Rail',
        // eslint-disable-next-line
    })
    return {
        type: RAIL_SECTION_POST_FAILURE,
        payload: {error}
    }};

/**
 * Edit Substation
 * @param {number} id
 * @param {json} data
 * @return
 */
export const editSubStation = (id, data) => dispatch => {
    dispatch(request());
    return scadaEnergyService.editSubStationService(id, data)
        .then(() => dispatch(subStationEditSuccess(true), dispatch(getAllSubstations())))
        .catch((error) => dispatch(substationEditFailure(error)));
};

/**
 * Edit Substation  success - Redux Action ..
 * @param {boolean} success
 * @return
 */
export const subStationEditSuccess = (success) => {
    // eslint-disable-next-line
    swalSuccess('The substation is successfully edited').fire({});
     return    {
            type: SUBSTATION_EDIT_SUCCESS,
            payload: {success}
        }};


/**
 * Edit Substation  error - Redux Action .
 * @param {boolean} error
 * @return
 */
export const substationEditFailure = (error) => {Swal.fire({
    icon: 'error',
    text: 'error occured while updating the substation',
    // eslint-disable-next-line
})
    return {
    type: SUBSTATION_EDIT_FAILURE,
    payload: {error}
}};

/**
 * Edit Rail Section by ID.
 *
 * @param {number} id id of The Rail Section.
 * @param {number} substationId id of The substationId.
 * @param {json} data
 * @return
 */
export const editRailSection = (id, data, substationId) => dispatch => {
    dispatch(request());
    return scadaEnergyService.editRailSectionService(id, data)
        .then(() => dispatch(railSectionEditSuccess(true), dispatch(getRailSectionbySubstationId(substationId))))
        .catch((error) => dispatch(railSectionEditFailure(error)));
}

/**
 * Edit Rail Section by ID success - Redux Action .
 *
 * @param {boolean} success
 * @return
 */
export const railSectionEditSuccess = (success) => {
    // eslint-disable-next-line
    swalSuccess('The association Rail/Devices is successfully edited').fire({});
       return {
            type: RAIL_SECTION_EDIT_SUCCESS,
            payload: {success}
        }};

/**
 * Edit Rail Section by ID error - Redux Action .
 *
 * @param {boolean} error
 * @return
 */
export const railSectionEditFailure = (error) => {
    Swal.fire({
    icon: 'error',
    text: 'error occured while editing the rail',
    // eslint-disable-next-line
})
    return {
    type: RAIL_SECTION_EDIT_FAILURE,
    payload: {error}
}};

/**
 * Delete association between substation and MP
 *
 * @param {number} id The substations id.
 * @return
 */
export const deleteSubstation = (id) => dispatch => {
    dispatch(request());
    return scadaEnergyService.deleteSubstationService(id)
        .then(() => dispatch(substationDeleteSuccess(true), dispatch(getAllSubstations())))
        .catch((error) => dispatch(substationDeleteFailure(error)));
};

/**
 * Delete association between substation and MP success - Redux Action .
 *
 * @param {boolean} success
 * @return
 */
export const substationDeleteSuccess = (success) => {
    // eslint-disable-next-line
    swalSuccess('The substation is successfully deleted').fire({});
    return {

            type: SUBSTATION_DELETE_SUCCESS,
            payload: {success}
        }};

/**
 * Delete association between substation and MP error - Redux Action .
 *
 * @param {boolean} error
 * @return
 */
export const substationDeleteFailure = (error) => {
    Swal.fire({
    icon: 'error',
    text: 'error occured while deleting the substation',
    // eslint-disable-next-line
});
    return {
    type: SUBSTATION_DELETE_FAILURE,
    payload: {error}
}};

/**
 * Delete relation between rail section and measuring point by ID rail-section.
 *
 * @param {number} id
 * @param {number} substationId
 * @return {json}
 */
export const deleteRailSection = (id, substationId) => dispatch => {
    dispatch(request());
    return scadaEnergyService.deleteRailSectionService(id)
        .then(() => dispatch(RailSectionDeleteSuccess(true), dispatch(getRailSectionbySubstationId(substationId))))
        .catch((error) => dispatch(RailSectionDeleteFailure(error)));
};

/**
 * Delete rail section by ID
 *
 * @param {string} id
 * @return
 */
export const deleteRailSectionEnergy = (id, substationId) => dispatch => {
    dispatch(request());
    return scadaEnergyService.deleteRailSectionEnergyService(id)
        .then(() => dispatch(RailSectionDeleteSuccess(true), dispatch(getRailSectionbySubstationId(substationId))))
        .catch((error) => dispatch(RailSectionDeleteFailure(error)));
};

/**
 * Delete rail section by ID success - Redux Action
 *
 * @param {boolean} success
 * @return
 */
export const RailSectionDeleteSuccess = (success) => {
// eslint-disable-next-line
    swalSuccess('The rail is successfully deleted').fire({});
    return {
        type: RAIL_SECTION_DELETE_SUCCESS,
        payload: {success}
    }};

/**
 * Delete rail section by ID error - Redux Action
 *
 * @param {boolean} error
 * @return
 */
export const RailSectionDeleteFailure = (error) => {
    Swal.fire({
        icon: 'error',
        text: 'error occured while deleting rail',
        // eslint-disable-next-line
    })
    return {
        type: RAIL_SECTION_DELETE_FAILURE,
        payload: {error}
    }};

export const request = () => ({
    type: REQUEST
});

/**
 * Get related list of rail sections by substation ID.
 *
 * @param {number} substationId.
 * @return {json}  array of substations.
 */
export const getRailSectionbySubstationId = (substationId) => dispatch => {
    dispatch(request());
    return scadaEnergyService.getrailsBysubstationIdService(substationId)
        .then((json) => dispatch(receiveRailsBySubstationId(json)));
};

/**
 * Get related list of rail sections by substation ID.
 *
 * @param {json} json.
 */
export const receiveRailsBySubstationId = (json) => ({
    type: RECEIVE_RAILS_SUBSTATION_ID,
    payload: {data: json}
});

/**
 * Returns measuring point of by rail section_id
 * @param {number} railId
 * @return {json}  array of Rail sections.
 */
export const getMpByRailId = (railId) => dispatch => {
    dispatch(request());
    return scadaEnergyService.getMpByRailIdService(railId)
        .then((json) => dispatch(receiveMpByRailId(json)));
};

/**
 * Returns measuring point of by rail section_id success - Redux Action ..
 * @param {json} json
 */
export const receiveMpByRailId = (json) => ({
    type: RECEIVE_MP_RAIL_ID,
    payload: {data: json}
});

/**
 * Returns List Of All Rail Sections.
 * @param {json} json
 * @return {json}  array of Rail Sections.
 */
export const getAllRails = (json) => dispatch => {
    dispatch(request());
    return scadaEnergyService.getAllRailsService()
        .then((json) => dispatch(receiveRails(json)));

};

/**
 * Returns List Of All Rail Sections success - Redux Action
 * @param {json} json
 * @return {json}  array of Rail Sections.
 */
export const receiveRails = (json) => ({
    type: RECEIVE_RAIL_SUCCESS,
    payload: {data: json}
});

/**
 * Returns list of Rail sections by rail-section-id including their MP relation.
 *
 * @param {string} railId
 * @return {json}  array of Rail sections.
 */
export const getRailsDetails = (railId) => dispatch => {
    dispatch(request());
    return scadaEnergyService.getRailsMeasuringPointsService(railId)
        .then((json) => dispatch(receiveRailDetails(json)));

};

/**
 * Returns list of Rail sections by rail-section-id including their MP relation success - Redux Action.
 *
 * @param {json} json
 * @return {json}  array of Rail sections.
 */
export const receiveRailDetails = (json) => ({
    type: RAIL_DETAILS_SUCCESS,
    payload: {data: json}
});

/**
 * Edit association between rail section and MP
 *
 * @param {number} id  ID of rail section.
 * @param {json} data
 * @return
 */
export const patchRailMeasuringPoints = (id, data) => dispatch => {
    dispatch(request());
    return scadaEnergyService.editRailsMeasuringPointService(id, data)
        .then((json) => {
            dispatch(patchRailsMP(json));
            railSectionEditSuccess();
        });
};

/**
 * Edit association between rail section and MP success - Redux Action.
 *
 * @param {json} json  ID of rail section.
 * @return
 */
export const patchRailsMP = (json) => ({
    type: PATCH_RAIL_MP,
    payload: {data: json}
});

/**
 * Get "Rectifier Substation" by ID
 * @param {number} measuringPointId
 * @returns
 */
export const getRectifierSubstationDetails = (measuringPointId) => dispatch => {
    dispatch(request());
    return measuringPointService.getRectifierSubstationByIdService(measuringPointId)
        .then((json) => dispatch(receiveRectifierSubstationDetails(json)));

};

/**
 * Get "Rectifier Substation" by ID success - Redux Action.
 * @param {json} json
 * @returns
 */
export const receiveRectifierSubstationDetails = (json) => ({
    type: RSS_DETAILS_SUCCESS,
    payload: {data: json}
});

/**
 * ////////////////////////////////////////////////////////////
 *                  Measuring points Services
 * ///////////////////////////////////////////////////////////
 **/

/**
 * Edit association between MP of type RSS and type VLD
 *
 * @param {number} id  ID of MP.
 * @param {json} data
 * @return
 */
export const editRectifierSubstation = (id, data) => dispatch => {
    dispatch(request());
    return scadaEnergyService.patchRectifierSubstationService(id, data)
        .then((json) => {
            dispatch(edit_RS_VLD(json));
            rssEditSuccess();
        });
};

/**
 * Edit association between MP of type RSS and type VLD success - Redux Action.
 *
 * @param {json} json
 * @return
 */
export const edit_RS_VLD = (json) => ({
    type: PATCH_RS_VLD,
    payload: {data: json}
});

/**
 * Edit association between MP of type RSS and type VLD success - Redux Action.
 *
 * @param {boolean} success
 * @return
 */
export const rssEditSuccess = (success) => {
    // eslint-disable-next-line
    swalSuccess('The association RSS/VLD is successfully edited').fire({})
    return {
            type: RAIL_SECTION_EDIT_SUCCESS,
            payload: {success}
        }};

/**
 * Returns list of All rail-sections with their relations success - Redux Action.
 * @param {boolean} success
 * @return {array} Array of rail sections
 */
export const getAllRailsSection = (json) => dispatch => {
    dispatch(request());
    return scadaEnergyService.getAllRailsSectionService()
        .then((json) => dispatch(receiveRails(json)));
};
