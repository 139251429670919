// Importing necessary modules and components
import { Button, Drawer, Row, Col, Layout, Tooltip, Space, Menu } from "antd"; // Ant Design components
import Moment from "moment"; // Library for date and time manipulation

import React, { useState } from "react"; // React hooks for state management
import { LogoutOutlined, MenuOutlined } from "@ant-design/icons"; // Ant Design icons

import MainMenu from "../Navigation/MainMenu"; // Importing the MainMenu component
import {Trans, useTranslation} from "react-i18next"; // Translation component from react-i18next
import SystemStatus from "./SystemStatus"; // Importing the SystemStatus component

import logoWITT from "assets/images/logo_WITT.png"; // Image file for WITT logo
import { Link, useNavigate } from "react-router-dom"; // Components and hooks for routing
import { logout } from "redux/constants/Auth"; // Logout action creator
import { useDispatch } from "react-redux"; // Redux hook for dispatching actions

const { Header } = Layout; // Destructuring Layout components

// Definition of the TopBar functional component
const TopBar = () => {
  const [visible, setVisible] = useState(false); // State variable for controlling drawer visibility
  // eslint-disable-next-line
  const {i18n} = useTranslation(); // required for translation without delay
  const dispatch = useDispatch(); // Redux hook for dispatching actions
  let navigate = useNavigate(); // Navigation hook for React Router

  // Function to handle menu item click
  const menuItemClick = (event) => {
    setVisible(false);
  };

  // Function to handle user logout
  const logOut = () => {
    navigate("/login"); // Redirect to the login page
    dispatch(logout()); // Dispatching logout action
  };

  const locale = "en"; // Locale for date and time formatting
  const [today, setToday] = React.useState(new Date()); // State variable to hold the current date and time

  // Effect hook to update the current date and time every minute
  React.useEffect(() => {
    const timer = setInterval(() => {
      // Update the date and time to trigger a rerender
      setToday(new Date());
    }, 49 * 1000); // Interval set to 49 seconds to avoid setInterval drift
    return () => {
      clearInterval(timer); // Cleanup function to clear the interval
    };
  }, [today]);

  const time = today.toLocaleTimeString(locale, {
    hour: "numeric",
    hour12: false,
    minute: "numeric",
  });

  // Menu component for logout
  // eslint-disable-next-line
  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: <Link onClick={() => logOut()}>Log Out</Link>,
        },
      ]}
    />
  );

  return (
    <Header>
      {/* Header section */}
      <Row className="header-container">
        {/* Button to toggle the drawer */}
        <Button
          className="mobile-menu-switch"
          type="primary"
          icon={<MenuOutlined />}
          onClick={() => setVisible(true)}
        />
        {/* Left section of the header */}
        <Col className="header-col-left">
          <img src={logoWITT} alt="WITT" className="top-logo" />
        </Col>
        {/* Center section of the header */}
        <Col className="header-col-center">
          <Row className="header-subtitle">Witt Solutions Gmbh</Row>
          <Row className="header-title">Wanda line</Row>
        </Col>
        {/* Right section of the header */}
        <Col className="header-col-right">
          {/* First row of the right section */}
          <Row className="header-col-right-1" justify="end">
            <Col className="header-status-title">
              <Trans i18nKey="title.systemStatus" />
            </Col>
            {/* System status component */}
            <SystemStatus />
          </Row>
          {/* Second row of the right section */}
          <Row className="header-col-right-2" justify="end">
            <Col className="header-user">
              <Link to="/">
                <Space>SupAdmin | Klaus Gradischek</Space>
              </Link>
            </Col>
            {/* Logout button */}
            <Col className="header-status-icon">
              <Tooltip placement="bottom" title={"LogOut"}>
                <Button
                  type="text"
                  style={{ marginLeft: 6 }}
                  icon={<LogoutOutlined />}
                  onClick={() => logOut()}
                />
              </Tooltip>
            </Col>
            {/* Date and time display */}
            <Col className="header-date-time">
              {Moment(today).format("DD.MM.YYYY")} {time}
            </Col>
          </Row>
        </Col>
      </Row>

      {/* Drawer component for mobile menu */}
      <Drawer
        placement="left"
        onClose={() => setVisible(false)}
        open={visible}
        className="main-drawer"
      >
        {/* Main menu component */}
        <MainMenu menuItemClick={menuItemClick} />
      </Drawer>
    </Header>
  );
};

// Exporting the TopBar component as default
export default TopBar;
