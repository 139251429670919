import {combineReducers} from "redux";
import auth from "./Auth";
import authMessage from "./AuthMessage";
import Users from "./Users";
import logReport from "./LogReport.reducer";
import softwareInfo from "./SoftwareInfo";
import setupUser from "./SetupUser.reducer";
import scadaInfra from "./SCADAInfra.reducer";
import measuringPoint from "./MeasuringPoint.reducer";
import systemStatus from "./SystemStatus.reducer";
import scadaEnergy from "./SCADAEnergy.reducer";
import dataAnalysis from "./DataAnalysis.reducer";
import chartFlow from "./chartFlow.reducer";
// eslint-disable-next-line
export default () => combineReducers({
    auth,
    authMessage,
    softwareInfo,
    users: Users,
    logReport,
    setupUser,
    scadaInfra,
    measuringPoint,
    systemStatus,
    scadaEnergy,
    dataAnalysis,
    chartFlow
});
