// Importing necessary modules and components
import { Menu } from "antd"; // Menu component from Ant Design
import React, { useState } from "react"; // React hooks for state management
import PermissionMenu from "utils/permission/menu";
import PropTypes from "prop-types"; // Custom utility for permission-based menu

// Definition of the MainMenu functional component
const MainMenu = ({ menuItemClick }) => {
  const [collapsed] = useState(false); // State variable for menu collapse status
  const items = PermissionMenu.getMenu(); // Fetching menu items from the permission-based menu utility

  return (
    <Menu
      mode="inline" // Display mode of the menu
      inlineCollapsed={collapsed} // Specifies whether the menu is collapsed or not
      items={items} // Menu items to be displayed
      theme="dark" // Theme of the menu
      onClick={menuItemClick} // Event handler for menu item click
    />
  );
};
MainMenu.propTypes={
  menuItemClick: PropTypes.func,
}
// Exporting the MainMenu component as default
export default MainMenu;
