import React, {useEffect, useState} from 'react';
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  DatePicker,
  Modal,
  Row, Col, Checkbox, Card, Alert, Tag, Typography, InputNumber
} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {getLogReport, postsLogReport} from "redux/actions/LogReport.action";
import {Trans} from "react-i18next";

const {Text} = Typography;
const {TextArea} = Input;
const CheckboxGroup = Checkbox.Group;

const ticketInformationOptions = [
  {label: 'Configuration', value: 'configuration'},
  {label: 'Operation Log', value: 'operationLog'},
  {label: 'Measurement data', value: 'measurementData'},
];

const defaultTicketInformationOptions = ['configuration', 'operationLog', 'measurementData'];


const LogReport = () => {


  const [componentSize, setComponentSize] = useState('default');
  const [formLogReport] = Form.useForm();
  const [ticketInformationCheckedList, setTicketInformationCheckedList] = useState(defaultTicketInformationOptions);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const {postLogReport, error} = useSelector(state => state.logReport);
  const dispatch = useDispatch();

  useEffect(() => {
    const initPage = () => {
      dispatch(getLogReport());
    };
    initPage();
    // eslint-disable-next-line
  },[]);

  useEffect(() => {
  }, [postLogReport]);

  useEffect(() => {
  }, [error]);


  function handleLogReport(values) {
    dispatch(postsLogReport(values));
  }


  const showModal = (data) => {
    console.log(data);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  const onChange = (list) => {
    setTicketInformationCheckedList(list);
  };

  const onFinish = (values: any) => {
    console.log(values);
  };

  const layout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 20,
    },
  };
  return (
      <div className='log-report'>
        <h1>Log Report</h1>
        <Card>
          <Row>
            <Col span={24} className='ant-col-padded'>

              <Alert
                  message="LogReportYour direct wire to WITT Support"
                  description="Depending on your service contact, you will incur cost"
                  type="info"
              />

              <Row>
                <Col xs={24} sm={24} md={24} lg={16} xl={16}>

                  <Form
                      labelCol={{
                        xs: {span: 24},
                        sm: {span: 24},
                        md: {span: 6},
                        lg: {span: 6},
                        xl: {span: 6},
                      }}
                      wrapperCol={{
                        xs: {span: 24},
                        sm: {span: 24},
                        md: {span: 18},
                        lg: {span: 18},
                        xl: {span: 18},
                      }}
                      layout="horizontal"
                      initialValues={{
                        size: componentSize,
                      }}
                      form={formLogReport}
                      onFinish={handleLogReport}
                      size={componentSize}>

                    <Form.Item
                        label="Severity level"
                        labelAlign="left"
                        name="severityLevel">
                      <Row className='log-report-form-row-mixed'>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Select>
                            <Select.Option value="Functional-deviation">Functional
                              deviation</Select.Option>
                          </Select>
                        </Col>
                        <Col xs={12} sm={12} md={18} lg={18} xl={18}
                             className='log-report-form-row-mixed-text'>
                          <Text> Service Contract</Text>
                        </Col>
                      </Row>
                    </Form.Item>

                    <Form.Item
                        label="Urgency level" labelAlign="left"
                        name="urgencyLevel">

                      <Row className='log-report-form-row-mixed'>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Select>
                            <Select.Option value="Unspecified">Unspecified</Select.Option>
                          </Select>
                        </Col>
                        <Col xs={12} sm={12} md={18} lg={18} xl={18}
                             className='log-report-form-row-mixed-text'>
                          <Tag color="default">bronze 125.4589</Tag>
                        </Col>
                      </Row>
                    </Form.Item>

                    <Form.Item
                        label="Ticket Titel" labelAlign="left"
                        name="ticketTitel">
                      <Input/>
                    </Form.Item>

                    <Form.Item
                        label="Contact for queries" labelAlign="left"
                        name="contactQueries">

                      <Row className='log-report-form-row-mixed'>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Input/>
                        </Col>
                        <Col xs={12} sm={12} md={18} lg={18} xl={18}
                             className='log-report-form-row-mixed-text'>
                          <Button onClick={() => showModal()} htmlType="submit">
                            WfW
                          </Button>
                        </Col>
                      </Row>
                    </Form.Item>

                    <hr/>

                    <h3>Ticket information</h3>

                    <Row>
                      <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                        <CheckboxGroup options={ticketInformationOptions}
                                       value={ticketInformationCheckedList} onChange={onChange}/>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <Row className='log-report-form-row-time-period'>
                          <Col span={24}>
                            <label htmlFor="descriptionErrorCase">Time Period</label>
                          </Col>
                          <Col xs={12} sm={8} md={12} lg={12} xl={12}>
                            <Form.Item name="timePeriodFrom"
                                       valuePropName="checked"
                                       wrapperCol={{span: 22}}
                            >
                              <DatePicker/>
                            </Form.Item>
                          </Col>
                          <Col xs={12} sm={8} md={12} lg={12} xl={12}>
                            <Form.Item name="timePeriodTo"
                                       valuePropName="checked"
                                       wrapperCol={{span: 22}}>
                              <DatePicker/>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Form.Item
                        wrapperCol={{span: 24}}
                        labelCol={{span: 24}}
                        label="Description of the error case"
                        labelAlign="left"
                        name="descriptionErrorCase">
                      <TextArea rows={5}/>
                    </Form.Item>

                    <Row className='log-report-form-actions'>
                      <Col>
                        <Form.Item>
                          <Button htmlType="submit" type="primary">
                            <Trans i18nKey="button.applyAndSave"/>
                          </Button>
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item name="creatTicket"
                                   wrapperCol={{
                                     xs: {span: 24},
                                     sm: {span: 24},
                                     md: {span: 24},
                                     lg: {span: 24},
                                     xl: {span: 24}
                                   }}>
                          <Radio.Group>
                            <Radio value="creatTicket">Creat a ticket</Radio>
                            <Radio value="creatTicketFile">Creat a ticket File</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
        <Modal title="Edit WFW"
               open={isModalVisible}
               onOk={handleOk}
               onCancel={handleCancel}>
          <Row justify='start'>
            <Col span={24}>
              <Form {...layout}
                    name="nest-messages"
                    onFinish={onFinish}>
                <Form.Item name={'id'}
                           initialValue={100}
                           label="WFW ID"
                           rules={[{type: 'number', min: 100, max: 999}]}>
                  <InputNumber/>
                </Form.Item>
                <Form.Item name={'title'} label="Title">
                  <Input/>
                </Form.Item>
                <Form.Item name={'description'} label="Description">
                  <Input.TextArea/>
                </Form.Item>
              </Form>
            </Col>

          </Row>

        </Modal>
      </div>
  );
};
export default LogReport;