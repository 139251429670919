import { configureStore } from '@reduxjs/toolkit'; // Importing the configureStore function from Redux Toolkit
import thunk from 'redux-thunk'; // Importing thunk middleware for async actions
import rootReducer from '../reducers/index'; // Importing the root reducer from '../reducers/index'

// Array of middlewares, currently only includes thunk
const middlewares = [thunk];

// Creating the Redux store using configureStore function
const store = configureStore({
    reducer: rootReducer(), // Setting the root reducer
    middleware: middlewares // Applying middlewares to the store
});

export default store; // Exporting the configured Redux store
