import axios from "axios";
import config from "../utils/consts.config.json";
import authHeader from "./AuthHeader";

const API_URL = config.API_URL;

/**
 * Api get software info
 * @returns {Promise<AxiosResponse<any>>}
 */
const getSoftwareInfo = () => {
    return axios
        .get(API_URL + "software", {headers: authHeader()})
        .then((response) => {
            return response.data;
        });
};

const softwareInfo = {
    getSoftwareInfo,
}
export default softwareInfo;
