import {Col, Row, Table } from 'antd';
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
    getMeasuringPointReading
} from "redux/actions/MeasuringPoint.action";
import { useParams } from "react-router-dom";
import { Trans } from "react-i18next";
import { DownOutlined } from "@ant-design/icons";

const data = require('../data.json');

const MpReading = () => {

    const { id } = useParams();
// eslint-disable-next-line
    const dispatch = useDispatch();
// eslint-disable-next-line
    useEffect(() => {
        if (id) {
            const initPage = () => {
                dispatch(getMeasuringPointReading(id));
            };
            initPage();
        }
        // eslint-disable-next-line
    }, []);


    const columnsStatus = [
        {
            title: <Trans i18nKey="measuringPoints.reading.status" />,
            dataIndex: 'status',
            width: 150,
        },
        {
            title: <Trans i18nKey="measuringPoints.reading.description" />,
            dataIndex: 'description',
        },
        {
            title: <Trans i18nKey="measuringPoints.reading.operationLog" />,
            dataIndex: 'operationLog',
            width: 150,
            render: (operationLog) => (
                <div>
                    <DownOutlined />&nbsp;
                    {operationLog}
                </div>
            )
        }
    ];
// eslint-disable-next-line
    const [dataStatus, setDataStatus] = useState(data.reading.binaryStatus);

    const columnsControl = [
        {
            title: <Trans i18nKey="measuringPoints.reading.remote" />,
            dataIndex: 'remote',
            width: 150,
        },
        {
            title: <Trans i18nKey="measuringPoints.reading.description" />,
            dataIndex: 'description'
        },
        {
            title: <Trans i18nKey="measuringPoints.reading.operationLog" />,
            dataIndex: 'operationLog',
            width: 150,
            render: (operationLog) => (
                <div>
                    <DownOutlined />&nbsp;
                    {operationLog}
                </div>
            )
        }
    ];
// eslint-disable-next-line
    const [dataControl, setDataControl] = useState(data.reading.binaryControl);

    const columnsValue = [
        {
            title: <Trans i18nKey="measuringPoints.reading.value" />,
            dataIndex: 'value',
            width: 150,
        },
        {
            title: <Trans i18nKey="measuringPoints.reading.description" />,
            dataIndex: 'description',
        },
        {
            title: <Trans i18nKey="measuringPoints.reading.assemblingRate" />,
            dataIndex: 'assemblingRete',
            width: 150,
            render: (assemblingRete) => (
                <div>
                    <DownOutlined />&nbsp;
                    {assemblingRete}
                </div>
            )
        }
    ];
// eslint-disable-next-line
    const [dataValue, setDataValue] = useState(data.reading.measuredValues);

    return (
        <div className='mp-status-ref-reading'>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <h3><Trans i18nKey="measuringPoints.reading.binaryStatus" /></h3>
                    <Table
                        bordered
                        columns={columnsStatus}
                        dataSource={dataStatus}
                        pagination={true}
                        scroll={{ x: 800 }} />
                </Col>
            </Row>
            <br />
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <h3><Trans i18nKey="measuringPoints.reading.binaryControlCommands" /></h3>
                    <Table
                        bordered
                        columns={columnsControl}
                        dataSource={dataControl}
                        pagination={true}
                        scroll={{ x: 800 }}  />
                </Col>
            </Row>
            <br />
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <h3><Trans i18nKey="measuringPoints.reading.measuredValues" /></h3>
                    <Table
                        bordered
                        columns={columnsValue}
                        dataSource={dataValue}
                        pagination={true}
                        scroll={{ x: 800 }}  />
                </Col>
            </Row>
            <br />
            <Row>
                <Col xs={24} sm={24} md={20} lg={14} xl={14}>
                    <h3><Trans i18nKey="measuringPoints.reading.nominalValues" /></h3>
                </Col>
            </Row>
        </div>
    );
}

export default MpReading;
