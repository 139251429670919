import {Button, Col, Row, Tag, Typography} from 'antd';
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    getDeviceByName,
    getMeasuringPointById,
} from "redux/actions/MeasuringPoint.action";
import {useParams} from "react-router-dom";
import {Trans} from "react-i18next";
import i18n from "i18next";

const {Text} = Typography;

const getCurrentLng = () => i18n.resolvedLanguage;

const MeasuringPointStatus = () => {

    const {id} = useParams();
    const dataDevice = useSelector(state => state.measuringPoint.dataDevice);
    const measuringPoint = useSelector(state => state.measuringPoint.measuringPointID);
    const dispatch = useDispatch();
    const [values, setValues] = useState([]);
    const [lang, setLang] = useState();

    useEffect(() => {
        if (id) {
            const initPage = () => {
                dispatch(getMeasuringPointById(id));
                if (measuringPoint) {
                    dispatch(getDeviceByName(measuringPoint.device));
                }
                setLang(getCurrentLng().toUpperCase());
            };
            initPage();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (dataDevice) {
            if (dataDevice.Values) {
                setValues(dataDevice.Values);
            }
        }
    }, [dataDevice]);

    return (
        <Row className='mp-status'>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Row>
                    <Col xs={24} sm={24} md={16} lg={16} xl={16} className='ant-col-padded mp-status-values'>
                        <Row className='mp-status-value-row'>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <label><Trans i18nKey="measuringPoints.status.type"/>:</label>
                            </Col>
                            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                <Tag color="default">{measuringPoint.device_type}</Tag>
                            </Col>
                        </Row>

                        <Row className='mp-status-value-row'>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <label><Trans i18nKey="measuringPoints.status.status"/>:</label>
                            </Col>
                            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                <Tag color="green">{measuringPoint.status}</Tag>
                                <Tag color="green" style={{width: "auto"}}>open</Tag>
                            </Col>
                        </Row>

                        {values.map((data) => {
                            return <Row className='mp-status-value-row' key={data.ShortValueName}>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <label>{
                                        data.language ? data.language.find(l => l.languageType === lang)?.ValueName : data.ShortValueName
                                    }:</label>
                                </Col>
                                <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                    <Tag color="default">{"-"}</Tag> {data.Unit}
                                </Col>
                            </Row>
                        })}

                    </Col>

                    <Col xs={24} sm={24} md={8} lg={8} xl={8} className="ant-col-padded">
                        <Row type="flex" justify={"center"} align="middle" className="status-width">
                            <Col span={12}><Trans i18nKey="measuringPoints.status.DC"/></Col>
                            <Col span={12}><Trans i18nKey="measuringPoints.status.ampere"/></Col>
                        </Row>
                        <br/>
                        <Row type="flex" justify={"center"} align="middle" className="status-width status-height">
                            <Col span={12}>
                                <Text>
                                    783
                                </Text>
                            </Col>
                            <Col span={12}>
                                <Text>
                                    max.Varlue
                                </Text>
                                <br/>
                                <Text>
                                    812
                                </Text>
                            </Col>
                        </Row>
                        <Row type="flex" justify={"center"} align="middle"
                             className="status-width status-border-top">
                            <Col span={12}>
                                <Text>
                                    500
                                </Text>
                            </Col>
                            <Col span={12} className="status-border-left">
                                <Text>
                                    max.threshold
                                </Text>
                                <br/>
                                <Text>
                                    800
                                </Text>
                            </Col>
                        </Row>
                        <Row type="flex" justify={"center"} align="middle"
                             className="status-width status-border-top">
                            <Col span={12} className="status-border-bottom-blue">
                                <Text>
                                    324
                                </Text>
                            </Col>
                            <Col span={12} className="status-border-left status-height">
                            </Col>
                        </Row>
                        <Row type="flex" justify={"center"} align="middle"
                             className="status-width">
                            <Col span={12}>
                                <Text>
                                </Text>
                            </Col>
                            <Col span={12} className="status-border-left status-border-bottom-blue">
                                <Text>
                                    212
                                </Text>
                            </Col>
                        </Row>
                        <Row type="flex" justify={"center"} align="middle"
                             className="status-width">
                            <Col span={12}>
                                <Text>
                                    0
                                </Text>
                            </Col>
                            <Col span={12} className="status-border-left status-height-min">
                                <Text>
                                </Text>
                            </Col>
                        </Row>
                        <Row type="flex" justify={"center"} align="middle"
                             className="status-width status-border-top status-height">
                            <Col span={12} className="status-border-bottom">
                                <br/>
                                <Text>
                                    - 500
                                </Text>
                            </Col>
                            <Col span={12} className="status-border-left status-height">
                                <br/>
                                <Text>
                                    min.threshold
                                </Text>
                            </Col>
                        </Row>
                        <Row type="flex" justify={"center"} align="middle"
                             className="status-width status-height">
                            <Col span={12} className="status-border-bottom">
                                <br/>
                                <Text>
                                    - 973
                                </Text>
                            </Col>
                            <Col span={12} className="status-border-left status-height">
                                <br/>
                                <Text>
                                    min.Varlue
                                </Text>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col>
                                <Button htmlType="button" type={"primary"}>
                                    <Trans i18nKey="button.dataAnalysis"/>
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default MeasuringPointStatus;
