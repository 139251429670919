import { Button, Col, DatePicker, Form, Input, Row, Select, Table, TimePicker } from 'antd';
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
    getMeasuringPointOperatingLog,
} from "redux/actions/MeasuringPoint.action";
import { useParams } from "react-router-dom";
import { Trans } from "react-i18next";
import { SearchOutlined } from "@ant-design/icons";

const data = require('../data.json');

const OperatingLog = () => {
    const [componentSize, setComponentSize] = useState('default');
    const [form] = Form.useForm();
    const { id } = useParams();
// eslint-disable-next-line
    const dispatch = useDispatch();
// eslint-disable-next-line
    useEffect(() => {
        if (id) {
            const initPage = () => {
                dispatch(getMeasuringPointOperatingLog(id, {}));
            };
            initPage();
        }
        // eslint-disable-next-line
    },[]);

    const onFormLayoutChange = ({ size }) => {
        setComponentSize(size);
    };

    function handleForm(values) {
        console.log(values);
        dispatch(getMeasuringPointOperatingLog(id, values));
    }

    const columns = [
        {
            title: <Trans i18nKey="measuringPoints.operatingLog.date" />,
            dataIndex: 'date',
            width: 90,
        },
        {
            title: <Trans i18nKey="measuringPoints.operatingLog.time" />,
            dataIndex: 'time',
            width: 90,
        }
        ,
        {
            title: <Trans i18nKey="measuringPoints.operatingLog.code" />,
            dataIndex: 'code',
            width: 50,
        }
        ,
        {
            title: <Trans i18nKey="measuringPoints.operatingLog.state" />,
            dataIndex: 'state',
            width: 90,
        }
        ,
        {
            title: <Trans i18nKey="measuringPoints.operatingLog.description" />,
            dataIndex: 'description',
        }
    ];
    // eslint-disable-next-line
    const [dataOperatingLog, setDataOperatingLog] = useState(data.operatingLog);

    return (
        <div className='mp-status-ref-operating-log'>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form
                        labelCol={{
                            span: 0,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        layout="horizontal"
                        initialValues={{
                            size: componentSize,
                            keepCentralOperatingLog: true,
                        }}
                        form={form}
                        onValuesChange={onFormLayoutChange}
                        onFinish={handleForm}
                        size={componentSize}>

                        <Row className='mp-status-ref-operating-log-filter'>
                            <Col className='mp-status-ref-operating-log-filter-col1'>
                                <Row>
                                    <Col style={{ width: "90px", paddingRight: "2px" }}>
                                        <Form.Item name="startDate">
                                            <DatePicker showTime format="YYYY-MM-DD"
                                                placeholder="Select start date" />
                                        </Form.Item>
                                        <Form.Item
                                            name="endDate">
                                            <DatePicker showTime format="YYYY-MM-DD"
                                                placeholder="Select end date and time" />
                                        </Form.Item>
                                    </Col>

                                    <Col style={{ width: "90px", paddingRight: "2px" }}>
                                        <Form.Item name="startTime">
                                            <TimePicker placeholder="Select start time" />
                                        </Form.Item>
                                        <Form.Item name="endTime">
                                            <DatePicker showTime format="YYYY-MM-DD"
                                                placeholder="Select end date and time" />
                                        </Form.Item>
                                    </Col>

                                    <Col style={{ width: "50px", paddingRight: "2px", display: "flex", alignItems: "flex-end" }}>
                                        <Form.Item name="search">
                                            <Input />
                                        </Form.Item>
                                    </Col>

                                    <Col style={{ width: "90px", paddingRight: "2px", display: "flex", alignItems: "flex-end" }}>
                                        <div style={{ display: "block", width: "100%" }}>
                                            <Form.Item name="searchFilter">
                                                <Select>
                                                    <Select.Option value="All" isSelectOption={true}>Alxxxxxxxxxxl</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>

                            <Col className='mp-status-ref-operating-log-filter-col2'>
                                <div style={{ display: "block", width: "100%" }}>
                                    <Row>
                                        <Col xs={0} sm={0} md={12} lg={12} xl={12}>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                            <Row>
                                                <div style={{ display: "flex", width: "100%" }}>
                                                    <div style={{ display: "flex", flexGrow: "2" }}>
                                                        <div style={{ display: "block", width: "100%" }}>
                                                            <Row>
                                                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                                    <Form.Item
                                                                        name="searchFilter">
                                                                        <Select>
                                                                            <Select.Option value="All" isSelectOption={true}>All</Select.Option>
                                                                        </Select>
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <Button htmlType="submit" type={"primary"}>
                                                            <SearchOutlined />
                                                        </Button>
                                                    </div>

                                                </div>
                                            </Row>

                                            <Row>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <Form.Item>
                                                        <Button htmlType="button" type={"primary"}>
                                                            <Trans i18nKey="button.saveAs" />
                                                        </Button>

                                                    </Form.Item>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <Form.Item>
                                                        <div style={{ textAlign: "right" }}>
                                                            <Button htmlType="reset" type={"primary"}>
                                                                <Trans i18nKey="button.reset" />
                                                            </Button>
                                                        </div>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>

                            </Col>

                        </Row>
                    </Form>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Table
                        bordered
                        columns={columns}
                        dataSource={dataOperatingLog}
                        pagination={true}
                        scroll={{x: 800}} />
                    <br />
                </Col>
            </Row>

        </div>
    );
}

export default OperatingLog;
