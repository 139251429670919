import { REQUEST } from "../constants/ActionTypes";
import measuringPointService from "services/MeasuringPoint.service";
import {
    MEASURING_POINT_POST_FAILURE,
    MEASURING_POINT_POST_SUCCESS,
    MEASURING_POINT_GET_LIST,
    MEASURING_POINT_GET_OPERATING_LOG,
    MEASURING_POINT_GET_READING,
    MEASURING_POINT_GET_REFERENCE_PRAS,
    MEASURING_POINT_GET_REFERENCE_SCMS,
    MEASURING_POINT_GET_THRESHOLD,
    MEASURING_POINT_DELETE_SUCCESS,
    MEASURING_POINT_DELETE_FAILURE,
    MEASURING_POINT_GET_BY_ID,
    MEASURING_POINT_EDIT_SUCCESS,
    MEASURING_POINT_EDIT_FAILURE,
    MEASURING_POINT_GET_MINIMAL_LIST,
    MEASURING_POINT_GET_RECTIFIERS_SUBSTATIONS_LIST,
    MEASURING_POINT_GET_DEVICE_TYPES,
    MEASURING_POINT_GET_DEVICE_PROTOCOLS,
    MEASURING_POINT_GET_DEVICE_BY_NAME,
    MEASURING_POINT_GET_PROTOCOL_BY_NAME,
} from "../constants/MeasuringPointTypes";

import Swal from 'sweetalert2';
import { swalError, swalSuccess } from "utils/swal"

/*
///////////////////////////////////////////////////////////////////////////////
                            Alerts
///////////////////////////////////////////////////////////////////////////////
*/

/**
 * Success message display action
 * @param {*} message 
 * @param {*} success 
 * @returns 
 */
export const measuringPointSuccessAction = (message) => {
    // eslint-disable-next-line
    swalSuccess(message).fire({});
        return {
            type: MEASURING_POINT_POST_SUCCESS,
            payload: true
        }
}

/**
 * Failure message display action
 * @param {*} message 
 * @returns 
 */
export const measuringPointFailureAction = (message, error) => {
    // eslint-disable-next-line
    swalError(message).fire({});
    return {
        type: MEASURING_POINT_POST_FAILURE,
        payload: {error}
    }
};

/*
///////////////////////////////////////////////////////////////////////////////
                            Common
///////////////////////////////////////////////////////////////////////////////
*/

export const request = () => ({
    type: REQUEST
});

/*
///////////////////////////////////////////////////////////////////////////////
                            Measuring Points List
///////////////////////////////////////////////////////////////////////////////
*/

/**
 * Get measuring points by calling the API
 * @returns 
 */
export const getMeasuringPointsList = () => dispatch => {
    dispatch(request());
    return measuringPointService.getMeasuringPointsListService()
        .then((json) => dispatch(getMeasuringPointsListAction(json)));
}

/**
 * Get measuring points list - Redux Action
 * @param {*} json 
 * @returns 
 */
export const getMeasuringPointsListAction = (json) => ({
    type: MEASURING_POINT_GET_LIST,
    payload: { data: json }
});

/*
///////////////////////////////////////////////////////////////////////////////
                            Measuring Point
///////////////////////////////////////////////////////////////////////////////
*/

/**
 * Get measuring point by ID
 * @param {*} id 
 * @returns 
 */
export const getMeasuringPointById = (id) => dispatch => {
    dispatch(request());
    return measuringPointService.getMeasuringPointByIdService(id)
        .then((json) => dispatch(getMeasuringPointByIdAction(json)));
}

/**
 * Get measuring point by ID - Redux Action
 * @param {*} json 
 * @returns 
 */
export const getMeasuringPointByIdAction = (json) => ({
    type: MEASURING_POINT_GET_BY_ID,
    payload: { data: json }
});

/**
 * Add a new measuring point
 * @param {*} data 
 * @returns 
 */
export const addMeasuringPoint = (data) => dispatch => {
    dispatch(request());
    return measuringPointService.addMeasuringPointService(data)
        .then(() => {
            dispatch(measuringPointSuccessAction('The measuring point is successfully created'))
            dispatch(getMeasuringPointsList());
        }, dispatch(getMeasuringPointsList()))
        .catch((error) =>
            dispatch(measuringPointFailureAction("Error occurred while saving the measuring point", error))
        );
}

/**
 * Edit a measuring point
 * @param {*} id 
 * @param {*} data 
 * @returns 
 */
export const editMeasuringPoint = (id, data) => dispatch => {
    dispatch(request());
    return measuringPointService.editMeasuringPointService(id, data)
        .then(() => dispatch(editMeasuringPointSuccess(true), dispatch(getMeasuringPointById((id)))))
        .catch((error) => dispatch(editMeasuringPointFailure(error)));
}

/**
 * Edit a measuring point "Success Alert"
 * @param {*} success 
 * @returns 
 */
export const editMeasuringPointSuccess = (success) => {
    // eslint-disable-next-line
    swalSuccess('The measuring point is successfully updated').fire({});
    return {
        type: MEASURING_POINT_EDIT_SUCCESS,
        payload: { success }
    }
;}

/**
 * Edit a measuring point "Failure Alert"
 * @param {*} error
 * @returns 
 */
export const editMeasuringPointFailure = (error) => {
    // eslint-disable-next-line
    swalError('Error occurred while updating the measuring point').fire({});
    return {
        type: MEASURING_POINT_EDIT_FAILURE,
        payload: { error }
    };}

/**
 * Delete a measuring point
 * @param {*} id 
 * @returns 
 */
export const deleteMeasuringPoint = (id) => dispatch => {

    dispatch(request());
    return measuringPointService.deleteMeasuringPointService(id)
        .then(() => dispatch(deleteMeasuringPointSuccess(true), dispatch(getMeasuringPointsList())))
        .catch((error) => dispatch(deleteMeasuringPointFailure(error)));

}

/**
 * Delete a measuring point "Success Alert"
 * @param {*} success 
 * @returns 
 */
export const deleteMeasuringPointSuccess = (success) => {
    // eslint-disable-next-line
    swalSuccess('The measuring point is successfully deleted').fire({});
    return {
        type: MEASURING_POINT_DELETE_SUCCESS,
        payload: { success }
    };}

/**
 * Delete a measuring point "Failure Alert"
 * @param {*} error 
 * @returns 
 */
export const deleteMeasuringPointFailure = (error) => {
    Swal.fire({
        icon: 'error',
        text: 'error while deleting measuring point',
        // eslint-disable-next-line
    });
    return {
        type: MEASURING_POINT_DELETE_FAILURE,
        payload: { error }
    };}

/*
///////////////////////////////////////////////////////////////////////////////
                    Measuring Point [Device]
///////////////////////////////////////////////////////////////////////////////
*/

/*
///////////////////////////////////////////////////////////////////////////////
                    Measuring Point [Status]
///////////////////////////////////////////////////////////////////////////////
*/

/**
 * Edit the measuring point "Status"
 * @param {*} id 
 * @param {*} data 
 * @returns 
 */
export const editMeasuringPointStatus = (id, data) => dispatch => {
    dispatch(request());
    return measuringPointService.editMeasuringPointService(id, data)
        .then(() => dispatch(measuringPointEditStatusSuccess(true), dispatch(getMeasuringPointById((id)))))
        .catch((error) => dispatch(editMeasuringPointFailure(error)));
}

/**
 * Edit the measuring point "Status" Success
 * @param {*} success 
 * @returns 
 */
export const measuringPointEditStatusSuccess = (success) => {
    // eslint-disable-next-line
    swalSuccess('The measuring point status is successfully updated').fire({});
       return  {
            type: MEASURING_POINT_EDIT_SUCCESS,
            payload: {success}
        }
}

/*
///////////////////////////////////////////////////////////////////////////////
                    Measuring Point [Reference SCMS]
///////////////////////////////////////////////////////////////////////////////
*/

/**
 * Get "Reference SCMS" of a measuring point
 * @param id
 * @returns {function(*): Promise<*>}
 */
export const getMeasuringPointRefSCMS = (id) => dispatch => {
    dispatch(request());
    return measuringPointService.getMeasuringPointRefSCMSService(id)
        .then((json) => dispatch(getMeasuringPointRefSCMSAction(json)));
}

/**
 * Get "Reference SCMS" list of a measuring point - Redux Action
 * @param json
 * @returns {{payload: {referenceSCMS}, type: string}}
 */
export const getMeasuringPointRefSCMSAction = (json) => ({
    type: MEASURING_POINT_GET_REFERENCE_SCMS,
    payload: { referenceSCMS: json }
});

/**
 * Save "Reference SCMS" of a measuring point
 * @param id
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const saveMeasuringPointRefSCMS = (id, data, form) => dispatch => {
    dispatch(request());
    return measuringPointService.saveMeasuringPointRefSCMSService(id, data)
        .then(() => {
            dispatch(measuringPointSuccessAction('The Reference SCMS is successfully saved'))
            dispatch(getMeasuringPointRefSCMS(id));
            form.resetFields();

        })
        .catch((error) =>
            dispatch(measuringPointFailureAction("Error occurred while saving the Reference SCMS", error))
        );
}

/**
 * Start the measurement of the "Reference SCMS"
 * @returns 
 */
export const startMeasuringPointRefSCMS = () => dispatch => {
    dispatch(request());
}

/*
///////////////////////////////////////////////////////////////////////////////
                    Measuring Point [Reference PRAS]
///////////////////////////////////////////////////////////////////////////////
*/

/**
 * Get "Reference PRAS" list of a measuring point
 * @param id
 * @returns {function(*): Promise<*>}
 */
export const getMeasuringPointRefPRAS = (id) => dispatch => {
    dispatch(request());
    return measuringPointService.getMeasuringPointRefPRASService(id)
        .then((json) => dispatch(getMeasuringPointRefPRASAction(json)));
}

/**
 * Get "Reference PRAS" of a measuring point - Redux Action
 * @param json
 * @returns {{payload: {referencePRAS}, type: string}}
 */
export const getMeasuringPointRefPRASAction = (json) => ({
    type: MEASURING_POINT_GET_REFERENCE_PRAS,
    payload: { referencePRAS: json }
});

/**
 * Save "Reference PRAS" of a measuring point
 * @param id
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const saveMeasuringPointRefPRAS = (id, data, form) => dispatch => {
    dispatch(request());
    return measuringPointService.saveMeasuringPointRefPRASService(id, data)
        .then(() => {
            dispatch(measuringPointSuccessAction('The Reference PRAS is successfully saved'));
            dispatch(getMeasuringPointRefPRAS(id));
            form.resetFields();
        })
        .catch((error) => dispatch(measuringPointFailureAction("Error occurred while saving the Reference PRAS", error)));
}

/**
 * Start the measurement of the "Reference PRAS"
 * @returns 
 */
export const startMeasuringPointRefPRAS = () => dispatch => {
    dispatch(request());
}

/*
///////////////////////////////////////////////////////////////////////////////
                    Measuring Point [Reading]
///////////////////////////////////////////////////////////////////////////////
*/

/**
 * Get measuring point "Reading" list
 * @param {*} id 
 * @returns 
 */
export const getMeasuringPointReading = (id) => dispatch => {
    dispatch(request());
    return measuringPointService.getMeasuringPointReadingService(id)
        .then((json) => dispatch(getMeasuringPointReadingAction(json)));
}

/**
 * Get measuring point "Reading" - Redux Action
 * @param {*} json 
 * @returns 
 */
export const getMeasuringPointReadingAction = (json) => ({
    type: MEASURING_POINT_GET_READING,
    payload: { reading: json }
});

/*
///////////////////////////////////////////////////////////////////////////////
                    Measuring Point [Threshold]
///////////////////////////////////////////////////////////////////////////////
*/

/**
 * Get measuring point "Threshold"
 * @param {*} id 
 * @returns 
 */
export const getMeasuringPointThreshold = (id) => dispatch => {
    dispatch(request());
    return measuringPointService.getMeasuringPointThresholdService(id)
        .then((json) => dispatch(getMeasuringPointThresholdAction(json)));
}

/**
 * Get measuring point "Threshold" - Redux Action
 * @param {*} json 
 * @returns 
 */
export const getMeasuringPointThresholdAction = (json) => ({
    type: MEASURING_POINT_GET_THRESHOLD,
    payload: { threshold: json }
});

/*
///////////////////////////////////////////////////////////////////////////////
                    Measuring Point [OperatingLog]
///////////////////////////////////////////////////////////////////////////////
*/

/**
 * Get measuring point "OperatingLog" list
 * @param {*} id 
 * @param {*} filter 
 * @returns 
 */
export const getMeasuringPointOperatingLog = (id, filter) => dispatch => {
    dispatch(request());
    return measuringPointService.getMeasuringPointOperatingLogService(id, filter)
        .then((json) => dispatch(getMeasuringPointOperatingLogAction(json)));
}

/**
 * Get measuring point "OperatingLog" - Redux Action
 * @param {*} json 
 * @returns 
 */
export const getMeasuringPointOperatingLogAction = (json) => ({
    type: MEASURING_POINT_GET_OPERATING_LOG,
    payload: { threshold: json }
});

/*
///////////////////////////////////////////////////////////////////////////////
                    Measuring Point [Utils]
///////////////////////////////////////////////////////////////////////////////
*/

/**
 * Get minimal list of measuring point
 * @param {*} filter 
 * @returns 
 */
export const getMeasuringPointsMinimalList = (filter) => dispatch => {
    dispatch(request());
    return measuringPointService.getMeasuringPointsMinimalListService(filter)
        .then((json) => dispatch(getMeasuringPointsMinimalListAction(json)));
}

/**
 * Get minimal list of measuring point - Redux Action
 * @param {*} filter 
 * @returns 
 */
export const getMeasuringPointsMinimalListAction = (json) => ({
    type: MEASURING_POINT_GET_MINIMAL_LIST,
    payload: { data: json }
});

/**
 * Get Rectifiers Substations
 * @returns 
 */
export const getRectifiersSubstationsList = () => dispatch => {
    dispatch(request());
    return measuringPointService.getRectifiersSubstationsListService()
        .then((json) => dispatch(getRectifiersSubstationsListAction(json)));
}

/**
 * Get Rectifiers Substations - Redux Action
 * @param {*} json 
 * @returns 
 */
export const getRectifiersSubstationsListAction = (json) => ({
    type: MEASURING_POINT_GET_RECTIFIERS_SUBSTATIONS_LIST,
    payload: { data: json }
});

/*
///////////////////////////////////////////////////////////////////////////////
                    Measuring Point [json files]
///////////////////////////////////////////////////////////////////////////////
*/

/**
 * Get devices types from json folders
 * @returns 
 */
export const getDeviceTypes = () => dispatch => {
    dispatch(request());
    return measuringPointService.getDeviceTypesService()
        .then((json) => dispatch(getDeviceTypesAction(json)));
}

/**
 * Get devices types from json folders - Redux Action
 * @param json
 * @returns {{payload: {devices}, type: string}}
 */
export const getDeviceTypesAction = (json) => ({
    type: MEASURING_POINT_GET_DEVICE_TYPES,
    payload: { devices: json }
});


/**
 * Get devices protocols from json folders
 * @returns 
 */
export const getDeviceProtocols = () => dispatch => {
    dispatch(request());
    return measuringPointService.getDeviceProtocolsService()
        .then((json) => dispatch(getDeviceProtocolsAction(json)));
}

/**
 * Get devices protocols from json folders - Redux Action
 * @param json
 * @returns {{payload: {devices}, type: string}}
 */
export const getDeviceProtocolsAction = (json) => ({
    type: MEASURING_POINT_GET_DEVICE_PROTOCOLS,
    payload: { protokolls: json }
});

/**
 * Get device from json by its name
 * @param {*} name 
 * @returns 
 */
export const getDeviceByName = (name) => dispatch => {
    dispatch(request());
    return measuringPointService.getDeviceByNameService(name)
        .then((json) => dispatch(getDeviceByNameAction(json)));
}

/**
 * Get device from json by its name - Redux Action
 * @param json
 * @returns {{payload: {dataDevice}, type: string}}
 */
export const getDeviceByNameAction = (json) => ({
    type: MEASURING_POINT_GET_DEVICE_BY_NAME,
    payload: { dataDevice: json }
});

/**
 * Get protocol from json by its name
 * @param {*} name 
 * @returns 
 */
export const getProtocolByName = (name) => dispatch => {
    dispatch(request());
    return measuringPointService.getProtocolByNameService(name)
        .then((json) => dispatch(getProtocolByNameAction(json)));
}

/**
* Get protocol from json by its name - Redux Action
 * @param json
 * @returns {{payload: {dataProtocol}, type: string}}
 */
export const getProtocolByNameAction = (json) => ({
    type: MEASURING_POINT_GET_PROTOCOL_BY_NAME,
    payload: { dataProtocol: json }
});

/*
///////////////////////////////////////////////////////////////////////////////
                    Measuring Point [Communication]
///////////////////////////////////////////////////////////////////////////////
*/

export const communicationTest = data => dispatch => {
    dispatch(request());
}
