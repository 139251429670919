export const FILTER_POST_SUCCESS = 'FILTER_POST_SUCCESS';
export const FILTER_POST_FAILURE = 'FILTER_POST_FAILURE';
export const RECEIVE_FILTER = 'RECEIVE_FILTER';
export const FILTER_PATCH_SUCCESS = 'FILTER_PATCH_SUCCESS';
export const FILTER_PATCH_FAILURE = 'FILTER_PATCH_FAILURE';
export const FILTER_DELETE_SUCCESS = 'FILTER_DELETE_SUCCESS';
export const FILTER_DELETE_FAILURE = 'FILTER_DELETE_FAILURE';
export const GET_LINES_SUCCESS = 'GET_LINES_SUCCESS';
export const GET_LINES_FAILURE = 'GET_LINES_FAILURE';
export const GET_STATIONS_FAILURE = 'GET_STATIONS_FAILURE';
export const GET_STATIONS_SUCCESS = 'GET_STATIONS_SUCCESS';
export const GET_MEASUREMENT_POINTS_FAILURE = 'GET_MEASUREMENT_POINTS_FAILURE';
export const GET_MEASUREMENT_POINTS_SUCCESS = 'GET_MEASUREMENT_POINTS_SUCCESS';
export const GET_RAIL_SECTION_SUCCESS = 'GET_RAIL_SECTION_SUCCESS';
export const GET_RAIL_SECTION_FAILURE = 'GET_RAIL_SECTION_FAILURE';
