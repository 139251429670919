import {Col, Row, Tag, Typography} from 'antd';
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    getDeviceByName,
    getMeasuringPointById,
    getMeasuringPointThreshold
} from "redux/actions/MeasuringPoint.action";
import {useParams} from "react-router-dom";
import {Trans} from "react-i18next";
import i18n from "i18next";

const {Text} = Typography;

const getCurrentLng = () => i18n.resolvedLanguage;

const Threshold = () => {

    const {id} = useParams();
    const dataDevice = useSelector(state => state.measuringPoint.dataDevice);
    const measuringPoint = useSelector(state => state.measuringPoint.measuringPointID);
    const dispatch = useDispatch();
    const [threshold, setThreshold] = useState([]);
    const [lang, setLang] = useState();

    useEffect(() => {
        if (id) {
            const initPage = () => {
                dispatch(getMeasuringPointThreshold(id));
            };
            initPage();
        }
        // eslint-disable-next-line
    }, [id, dispatch]);

    useEffect(() => {
        if (id) {
            const initPage = () => {
                dispatch(getMeasuringPointById(id));
                if (measuringPoint) {
                    dispatch(getDeviceByName(measuringPoint.device));
                }
                setLang(getCurrentLng().toUpperCase());
            };
            initPage();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (dataDevice) {
            if (dataDevice.Threshold) {
                setThreshold(dataDevice.Threshold);
            }
        }
    }, [dataDevice]);
    return (
        <div className='mp-status-ref-threshold'>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="ant-col-padded">
                <h3><Trans i18nKey="measuringPoints.threshold.subTitle"/>{measuringPoint.device_type}</h3>
            </Col>

            <Row>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="ant-col-padded">
                    {threshold.map((data,index) => {
                        return <Row className='mp-status-ref-threshold-row' key={`${index.toString()}-threshold`}>
                            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                <label>{
                                    data.language ? data.language.find(l => l.languageType === lang)?.ValueName : data.ShortValueName
                                }:</label>
                            </Col>
                            <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                                <Tag color="default">{data.min}</Tag>
                                <Tag color="default">{data.max}</Tag>
                                <Text>{data.Unit}</Text>
                            </Col>
                        </Row>
                    })}
                </Col>
            </Row>
        </div>
    );
}

export default Threshold;
