import {
    RECEIVE_CHART_FLOW_RAILS // Importing action type constant for receiving chart flow rails
} from "../constants/ChartFlowType"; // Importing action type constants related to chart flow
import { REQUEST } from "redux/constants/ActionTypes"; // Importing action type constant for making requests

const initialState = {
    chartFlowrails: [], // Array to store chart flow rails
    success: false, // Flag indicating if the action was successful
    error: "", // Error message
};

// Reducer function for chart flow
// eslint-disable-next-line
export default function chartFlow(state = initialState, action) {
    const { type, payload } = action; // Destructuring action object
    switch (type) {
        // Handling the reception of chart flow rails
        case RECEIVE_CHART_FLOW_RAILS: {
            return {
                ...state,
                chartFlowrails: payload.data // Updating chart flow rails with data from the action payload
            }
        }
        // Handling a request action
        case REQUEST: {
            return {
                ...state,
                loading: true, // Setting loading flag to true
            }
        }
        default:
            return state; // Returning the current state for unhandled actions
    }
}
