import axios from "axios";
import config from "../utils/consts.config.json";
import authHeader from "./AuthHeader";

const API_URL = config.API_URL;

const addLogReport = (data) => {
    return axios
        .post(API_URL + "logReport", data, {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

const getLogReport = () => {
    return axios
        .get(API_URL + "logReport", {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

const logReportService = {
    addLogReport,
    getLogReport
}
export default logReportService;
