import { Tabs, Card } from 'antd';
import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import LinesList from './Lines/LinesList';
import StationsList from './Stations/StationsList';
import RailSectionList from './RailSections/RailSectionsList';
import { getAllLine, getAllRails, getAllStations } from 'redux/actions/SCADAInfra.action';
import { useDispatch } from 'react-redux';

const ScadaInfraTabs = () => {
    const [activeTab, setActiveTab] = useState("1");
    const { TabPane } = Tabs;
    const dispatch = useDispatch();
    useEffect(() => {
        setActiveTab(activeTab);
        dispatch(getAllLine());
        dispatch(getAllStations());
        dispatch(getAllRails());
        // eslint-disable-next-line
    }, []);

    function changeTab(key) {
        setActiveTab(key);
    }
    return (
        <>
            <h1>SCADA-Infra</h1>
            <Card>

                <Tabs onChange={changeTab} type="card" activeKey={activeTab}>
                    <TabPane key="1" tab={<Trans i18nKey="setup.ScadaInfra.LinesListTitleTab" />}>
                        <LinesList />
                    </TabPane>
                    <TabPane key="2" tab={<Trans i18nKey="setup.ScadaInfra.stationsListTitle" />}>
                        <StationsList />
                    </TabPane>
                    <TabPane key="3" tab={<Trans i18nKey="setup.ScadaInfra.RailSectionListTitleTab" />}>
                        <RailSectionList />
                    </TabPane>

                </Tabs>


            </Card>
        </>
    );
}

export default ScadaInfraTabs;