/*
 **************** IMPORTANT ********************************
 *
 * FOR ALL CHART COMPONENTS IN THIS PROJECT PLEASE READ OFFICIAL DOCUMENTATION
 * THESE FUNCTIONS ARE UNDER DEVELOPMENT AND NOT FINISHED YET
 * TO_DO_: OPTIMIZE CODE + MAKE IT MORE MAINTAINABLE
 *
 * docs: https://reactflow.dev/learn
 *
 */

import React, { memo, useEffect, useState } from "react";
import { Handle, useUpdateNodeInternals } from "react-flow-renderer";
import { Col, Form, Row, Select, Modal, Descriptions } from "antd";
import { Trans } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getStationById } from "redux/actions/SCADAInfra.action";
import PropTypes from "prop-types";

const SelectorNodeType = memo(({ data }) => {
  // Redux dispatch
  const dispatch = useDispatch();

  // State for modal visibility and form data
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [itemList, setItemList] = useState([]);
  const [itemTopList, setItemTopList] = useState([]);
  const [dimensions, setDimensions] = useState({ width: 20, height: 20 });

  // Update node internals hook
  const updateNodeInternals = useUpdateNodeInternals();

  // Redux state selectors
  const stationList = useSelector((state) => state.scadaInfra.listStation);
  const stationByidData = useSelector((state) => state.scadaInfra.stationById);

  // Effect to update node internals when itemList changes
  useEffect(() => {
    data.onChange(data.iddata, data.nodeId);
    updateNodeInternals(data.id);
  }, [itemList]);

  // Show modal function
  const showModal = () => {
    setIsModalVisible(true);
    if (data.iddata) {
      dispatch(getStationById(data.iddata));
    }
  };

  // Update node internals after closing modal
  const handleOk = () => {
    showHandlesRails();
    showHandlesTopRails();
    setIsModalVisible(false);
    data.onChange(form.getFieldsValue().stationId?.value, data.nodeId);
    form.resetFields();
  };

  const onSelect = (values, options) => {
    // Set label based on icon
    data.label = (
      <img
        src={`./assets/icons/${options.icon}.png`}
        alt="test"
        height={"20px"}
        width={"auto"}
      />
    );

    console.log("VALUES", values);

    // Update node data
    data.onChange(values.value, data.nodeId);
    data.name = values.label;

    // Dispatch action
    dispatch(getStationById(values.value));
    updateNodeInternals(data.id);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  let Linesoptions = stationList.map((element) => {
    return {
      icon: element.icon,
      label: element.name,
      id: element.id,
      value: element.id,
    };
  });
  const positionHandletop = (index) => {
    if (index === 1 || index === 2) {
      return (dimensions.width / 3) * index;
    } else if (index === 3) {
      return 0;
    } else if (index === 4) {
      return dimensions.width;
    }
  };
  const showHandlesRails = () => {
    setItemList([]);
    if (form.getFieldsValue().handleNumber) {
      let j = parseInt(form.getFieldsValue().handleNumber);

      for (let index = 1; index <= j; index++) {
        setItemList((oldState) => [
          ...oldState,

            <Handle
              id={"dynamique " + index.toString()}
              key={"dynamique " + index.toString()}
              type="source"
              position="bottom"
              style={{
                background: "#00000",
                left: positionHandletop(index + 1),
              }}
              onConnect={(params) => {}}
              isConnectable="true"
            />
          ,
        ]);
        // itemList.push(
        //     <Handle
        //         id={index}
        //         type='source'
        //         position="right"
        //         style={{ background: '#555' }}
        //         onConnect={(params) => {
        //         }}
        //         isConnectable="true"
        //     />
        // )
      }
    }
  };
  const showHandlesTopRails = () => {
    setItemTopList([]);
    if (form.getFieldsValue().handleNumberTop) {
      let j = parseInt(form.getFieldsValue().handleNumberTop);

      for (let index = 1; index <= j; index++) {
        setItemTopList((oldState) => [
          ...oldState,
            <Handle
              id={"dynamique Top" + index.toString()}
              key={"dynamique Top" + index.toString()}
              type="target"
              position="top"
              style={{
                background: "#00000",
                left: positionHandletop(index + 1),
              }}
              onConnect={(params) => {}}
              isConnectable="true"
            />
          ,
        ]);
        // itemList.push(
        //     <Handle
        //         id={index}
        //         type='source'
        //         position="right"
        //         style={{ background: '#555' }}
        //         onConnect={(params) => {
        //         }}
        //         isConnectable="true"
        //     />
        // )
      }
    }
  };
  return (
    <button onDoubleClick={showModal} style={{all: 'unset'}}>
      <Modal
        title="Select Station"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form form={form} onFinish={handleOk}>
          <Form.Item
            labelCol={{ span: 7 }}
            label={<Trans i18nKey="measuringPoints.location.stationID" />}
            labelAlign="left"
            name="stationId"
            initialValue={data.name}
          >
            <Select
              labelInValue
              placeholder="Select a option and change input text above"
              options={[
                {
                  label: "Stations",
                  options: Linesoptions,
                },
              ]}
              onSelect={onSelect}
              // disabled={disabled}
            />
          </Form.Item>
          {/* <Form.Item labelCol={{ span: 7 }} label={<Trans i18nKey="handle number" />}
                        labelAlign="left"
                        name="handleNumber">
                        <Input placeholder="" />
                    </Form.Item>
                    <Form.Item labelCol={{ span: 7 }} label={<Trans i18nKey="handleNumberTop" />}
                        labelAlign="left"
                        name="handleNumberTop">
                        <Input placeholder="" />
                    </Form.Item> */}
          {form.getFieldValue("stationId") && (
            <Descriptions bordered>
              <Descriptions.Item label="Station Name" span={24}>
                {stationByidData.name}
              </Descriptions.Item>
              <Descriptions.Item label="Station Number" span={24}>
                {stationByidData.number}
              </Descriptions.Item>
              <Descriptions.Item label="Lines" span={24}>
                {stationByidData?.lines?.map((line) => {
                  return (
                    <p key={line.id}>
                      {line.name}
                      <br />
                    </p>
                  );
                })}
              </Descriptions.Item>
            </Descriptions>
          )}
        </Form>
      </Modal>
      <div className="node-information">
        <Row>
          <Col>
            <button onClick={showModal} style={{all: 'unset',color:"#114c90"}}>{data?.name}</button>
          </Col>
          {/* <Row>
                        <Col>
                            <TagFilled className='ok-tag' />
                        </Col>
                        <Col>
                            <Link
                                to={{ pathname: `/measuring-point/` }}>{form.getFieldsValue().subStation || '0023 | WE321.43'}</Link>
                        </Col>
                    </Row> */}
        </Row>
      </div>

      {/* <Handle
                position="left"
                id='1'
                type='target'
                style={{ background: '#555' }}
                onConnect={(params) => {
                }}
                isConnectable="true"

            />
            <Handle
                id='2'
                type='target'
                position="top"
                style={{ background: 'red', color: 'red' }}
                onConnect={(params) => {
                }}
                isConnectable="true"
            />

            <Handle
                id='3'
                type='source'
                position="bottom"
                style={{ background: '#555' }}
                onConnect={(params) => {
                }}
                isConnectable="true"
            />

            <Handle
                id='4'
                type='source'
                position="right"
                style={{ background: '#555' }}
                onConnect={(params) => {
                }}
                isConnectable="true"
            /> */}

      {/* {itemList.map(item => {

                return (

                    <Handle id={item.props.id}
                        position={item.props.position}
                        style={item.props.style}
                        type={item.props.type}
                    ></Handle>
                )

            })} */}
      {/* <Handle
                id='0'
                type='source'
                position="bottom"
                style={{ background: '#555' }}
                onConnect={(params) => {
                }}
                isConnectable="true"
            /> */}
      <Handle
        id="3"
        type="source"
        position="top"
        style={{
          background: "red",
          color: "red",
          width: "5px",
          height: "5px",
          top: "auto",
          left: "0px",
        }}
        onConnect={(params) => {}}
        isConnectable="true"
      />

      <Handle
        id="2"
        type="source"
        position="right"
        style={{
          background: "#555",
          width: "5px",
          height: "5px",
          top: "3px",
          left: "17px",
        }}
        onConnect={(params) => {}}
        isConnectable="true"
      />
      <Handle
        id="5"
        type="source"
        position="left"
        style={{
          background: "red",
          color: "red",
          width: "5px",
          height: "5px",
          top: "23px",
          //left: '17px'
        }}
        onConnect={(params) => {}}
        isConnectable="true"
      />

      <Handle
        id="4"
        type="source"
        position="bottom"
        style={{
          background: "#555",
          width: "5px",
          height: "5px",
          top: "21px",
          left: "19px",
        }}
        onConnect={(params) => {}}
        isConnectable="true"
      />
      {itemList.map((item) => item)}
      {itemTopList.map((item) => item)}

      {data.label}
    </button>
  );
});
SelectorNodeType.propTypes={
    data: PropTypes.object
}
export default SelectorNodeType;
