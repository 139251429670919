import * as React from "react";
import { Space, Table } from "antd";
import { ReadOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";

interface DataType {
  key: string;
  title: string;
  id: number;
  description: string;
}

export const Workflow = () => {
  // Define the columns for the table
  const columns: ColumnsType<DataType> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Instruction",
      key: "instruction",
      render: (_, record) => (
        <Space size="small">
          {/* Add a button with a ReadOutlined icon */}
          <button>
            <ReadOutlined />
          </button>
        </Space>
      ),
    },
  ];

  // Define sample data for the table
  const data: DataType[] = [
    {
      key: "1",
      id: "1",
      title: "Transfer LogReport",
      description: "Transfer LogReport description...",
    },
    {
      key: "2",
      id: "2",
      title: "Transferring reports",
      description: "Transferring reports description...",
    },
    // Add more data entries as needed
  ];

  // Return the table component
  return <Table columns={columns} dataSource={data} />;
};
