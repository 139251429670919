// Importing necessary modules and components
import { Col, Form, Row, Checkbox } from "antd";
import React from "react";

// Definition of the TopFilter functional component
const TopFilter = () => {
  return (
    <div className="top-filter">
      {/* Container for the top filter */}
      <Row className="top-filter-container">
        <Col>
          {/* Checkbox for Max Values */}
          <Form.Item name="max_values" valuePropName="checked">
            <Checkbox>Max Values</Checkbox>
          </Form.Item>
          {/* Checkbox for Min Values */}
          <Form.Item name="min_values" valuePropName="checked">
            <Checkbox>Min Values</Checkbox>
          </Form.Item>
        </Col>
        <Col>
          {/* Checkbox for Trends */}
          <Form.Item name="trends" valuePropName="checked">
            <Checkbox>Trends</Checkbox>
          </Form.Item>
          {/* Checkbox for Display Values */}
          <Form.Item name="display_values" valuePropName="checked">
            <Checkbox>Display Values</Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

// Exporting the TopFilter component as default
export default TopFilter;
