import { Button, Row, Space, Table, Modal, Form, Transfer, Typography } from 'antd';
import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import {
    editRectifierSubstation,
    getRectifierSubstationDetails,
} from 'redux/actions/SCADAEnergy.action';
import { useDispatch, useSelector } from 'react-redux';
import { getRectifiersSubstationsList, getMeasuringPointsMinimalList } from "redux/actions/MeasuringPoint.action";

const RectifiersSubstations = () => {
    const [targetKeys, setTargetKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [visibleModal_edit, setVisibleModal_edit] = useState(false);
    const [formEditRectifierSubstation] = Form.useForm();
    const dispatch = useDispatch();
    const devicesVLDList = useSelector(state => state.scadaEnergy.measuringpointList);
    let deviceRSSList = useSelector(state => state.measuringPoint.RectifiersSubstations);
    let rectifierSubstationDetails = useSelector(state => state.scadaEnergy.rectifierSubstationDetails);
    const { Text } = Typography;

    const railsColumnsSection = [
        {
            title: <Trans i18nKey="setup.ScadaEnergy.rectifierSubstationName" />,
            dataIndex: 'device',
            width: 250,
            render: (_, record) => {
                return <Text >{record?.device_name + "_" + record?.protocol_name + "_" + record?.device_identification}</Text>
            }
        },
        {
            title: <Trans i18nKey="setup.ScadaEnergy.Line" />,
            dataIndex: 'stations',
            width: 180,
            render: (_, record) => {
                const lines = record.railSections?.map((item) => item.line);
                return (lines?.map((s) => {
                    return <Text key={s.name}>{s.name}<br /></Text>;
                }));
            }
        },
        {
            title: <Trans i18nKey="setup.ScadaEnergy.stations" />,
            dataIndex: 'stations',
            width: 180,
            render: (_, record) => {
                const stations = record.railSections?.map((item) => item.stations);
                return (stations?.map((s) => {
                    return s?.map((station) => <Text key={station.name}>{station.name}<br /></Text>);
                }));
            }
        },
        {
            title: <Trans i18nKey="setup.ScadaEnergy.railSectionsColumn" />,
            dataIndex: 'stations',
            width: 180,
            render: (_, record) => {
                const railSections = record.railSections?.map((item) => `${item.name}`);
                return (railSections?.map((s) => {
                    return <Text key={s}>{s}<br /></Text>;
                }));
            }
        },
        {
            title: <Trans i18nKey="columns.actions" />,
            key: 'action',
            width: 100,
            render: (_, record) => (
                <Space size="middle">
                    <Button onClick={() => showModal_edit(record.id)} size={"small"}><Trans
                        i18nKey="button.Edit" /></Button>
                </Space>
            ),
        },
    ];

    useEffect(() => {
        const initPage = () => {
            dispatch(getRectifiersSubstationsList());
            dispatch(getMeasuringPointsMinimalList('?filter=%7B%22where%22%3A%20%7B%22device_type%22%3A%22VLD%22%7D%7D'));
        };
        initPage();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setTargetKeys([]);
        if (rectifierSubstationDetails?.measuringPoints) {
            const RS_VLD_ids = rectifierSubstationDetails.measuringPoints.map((rectifier) => {
                return (rectifier.id);
            })
            setTargetKeys(RS_VLD_ids);
            formEditRectifierSubstation.setFieldsValue(rectifierSubstationDetails);
        }
        // eslint-disable-next-line
    }, [rectifierSubstationDetails])


    ///////////////////////////////////////////////////////////////////////
    //           EDIT RECTIFIER SUBSTATION MODAL
    ///////////////////////////////////////////////////////////////////////
    const showModal_edit = (RS_Id) => {
        setVisibleModal_edit(true);
        dispatch(getRectifierSubstationDetails(RS_Id));

    };

    const handleCancelModal_edit = () => {
        setVisibleModal_edit(false);
        formEditRectifierSubstation.resetFields();
    };

    const onFinishForm_editRectifierSubstation = () => {
        dispatch(editRectifierSubstation(rectifierSubstationDetails.id, { 'measuringpoint': targetKeys }));
        setVisibleModal_edit(false);
        formEditRectifierSubstation.resetFields();
    }


    const onChange = (nextTargetKeys) => {
        setTargetKeys(nextTargetKeys);
    };

    const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };

    return (
        <>
            <h2><Trans i18nKey="setup.ScadaEnergy.rectifiersSubstationsTableTile" /></h2>
            <Table
                bordered
                columns={railsColumnsSection}
                dataSource={deviceRSSList}
                pagination={true}
                scroll={{ y: 500 }}
                className="mp-location-rails-table"
                rowKey="id"
            />


            {/************* Modal : Edit Rails devices *************/}

            <Modal
                 width={'900px'}
                title={<Trans i18nKey="setup.ScadaEnergy.editRSSModalTitle" />}
                open={visibleModal_edit}
                onOk={formEditRectifierSubstation.submit}
                okText="Save"
                onCancel={handleCancelModal_edit}
            >
                <Row justify={'center'}>
                    <h3><Trans i18nKey="setup.ScadaEnergy.editRSSModalDescription" /></h3>
                </Row>

                <Form
                    wrapperCol={{
                        xs: { span: 24 },
                        sm: { span: 24 },
                        md: { span: 24 },
                        lg: { span: 24 },
                        xl: { span: 24 },
                    }}
                    layout="horizontal"
                    form={formEditRectifierSubstation}
                    labelAlign='left'
                    onFinish={() => onFinishForm_editRectifierSubstation()}
                >
                    <Form.Item name="measuringpoint" justify='center' initialValue={[]}>
                        <Transfer
                            listStyle={{ width: 500 }}
                            dataSource={devicesVLDList}
                            titles={['Devices', 'Associated Devices']}
                            targetKeys={targetKeys}
                            selectedKeys={selectedKeys}
                            onChange={onChange}
                            onSelectChange={onSelectChange}
                            render={(item) => {
                                return item?.device_name + "_" + item?.protocol_name + "_" + item?.device_identification;
                            }
                            }
                            operations={['add', 'remove']}
                            rowKey={record => record.id}
                        />

                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

export default RectifiersSubstations;
