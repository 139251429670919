import {REQUEST} from "redux/constants/ActionTypes";
import scadaInfraService from "services/SCADAInfra.service";
import {
    RECEIVE_LINE,
    LINE_POST_SUCCESS,
    LINE_POST_FAILURE,
    LINE_DELETE_SUCCESS,
    LINE_DELETE_FAILURE,
    LINE_EDIT_SUCCESS,
    LINE_EDIT_FAILURE,
    STATION_POST_SUCCESS,
    STATION_POST_FAILURE,
    STATION_DELETE_SUCCESS,
    STATION_DELETE_FAILURE,
    STATION_EDIT_SUCCESS,
    STATION_EDIT_FAILURE,
    STATION_MULTIPLE_DELETE_SUCCESS,
    STATION_MULTIPLE_DELETE_FAILURE,
    RECEIVE_STATION,
    RECEIVE_RAIL,
    RAIL_SECTION_POST_SUCCESS,
    RAIL_SECTION_POST_FAILURE,
    RAIL_EDIT_SUCCESS,
    RAIL_EDIT_FAILURE,
    RECEIVE_LINE_BY_ID,
    RECEIVE_STATION_BY_ID,
    RECEIVE_RAIL_BY_ID,
    LINE_MULTIPLE_DELETE_SUCCESS,
    LINE_MULTIPLE_DELETE_FAILURE,
    RAIL_DELETE_SUCCESS,
    RAIL_DELETE_FAILURE,
    RAIL_MULTIPLE_DELETE_SUCCESS,
    RAIL_MULTIPLE_DELETE_FAILURE,

} from "../constants/SCADAInfraType";

import {swalError, swalSuccess} from "utils/swal"


/**
 * ////////////////////////////////////////////////////////////
 *                  Scada-Infra Line Actions
 * ///////////////////////////////////////////////////////////
 **/

/**
 * Get list of All lines including rail section and MP
 *
 * @returns
 */
export const getAllLine = () => dispatch => {
    dispatch(request());
    return scadaInfraService.getAllLineService()
        .then((json) => dispatch(receiveLine(json)));
}

/**
 * Get list of All lines including rail section and MP - Redux Action
 * @param {json} json
 * @returns
 */
export const receiveLine = (json) => ({
    type: RECEIVE_LINE,
    payload: {data: json}
});

/**
 * Get line detail by id
 * @param {number} id
 * @returns
 */
export const getLinebyId = (id) => dispatch => {
    dispatch(request());
    return scadaInfraService.getLineByIdService(id)
        .then((json) => dispatch(receiveLineById(json)));
}

/**
 * Get line detail by id - Redux Action
 * @param {json} json
 * @returns
 */
export const receiveLineById = (json) => ({
    type: RECEIVE_LINE_BY_ID,
    payload: {data: json}
});

/**
 * Add new Line to scada Infra
 * @param {json} data
 * @returns
 */
export const postsLine = data => dispatch => {
    dispatch(request());
    return scadaInfraService.addLineService(data)
        .then(() => dispatch(linePostSuccess(true), dispatch(getAllLine())))
        .catch((error) => dispatch(linePostFailure(error)));
}

/**
 * Add new Line success to scada Infra - Redux Action
 * @param {boolean} success
 * @returns
 */
export const linePostSuccess = (success) => {
    // eslint-disable-next-line
    swalSuccess('The line is successfully created').fire({});
        return {
            type: LINE_POST_SUCCESS,
            payload: {success}
        }};

/**
 * Add new Line error to scada Infra - Redux Action
 * @param {boolean} error
 * @returns
 */
export const linePostFailure = (error) => ({
    type: LINE_POST_FAILURE,
    payload: {error}
});

/**
 * Delete line by id
 * @param {number} id
 * @returns
 */
export const deleteLine = (id) => dispatch => {
    dispatch(request());
    return scadaInfraService.deleteLineService(id)
        .then(() => dispatch(lineDeleteSuccess(true), dispatch(getAllLine()), dispatch(getAllStations()), dispatch(getAllRails())))
        .catch((error) => dispatch(lineDeleteFailure(error)));
}

/**
 * Delete success line by id - Redux Action
 * @param {boolean} success
 * @returns
 */
export const lineDeleteSuccess = (success) => {
    // eslint-disable-next-line
    swalSuccess('The line is successfully deleted').fire({});
        return {
            type: LINE_DELETE_SUCCESS,
            payload: {success}
        }};

/**
 * Delete error line by id - Redux Action
 * @param {boolean} error
 * @returns
 */
export const lineDeleteFailure = (error) => {
    // eslint-disable-next-line
    swalError('An error occured while deleting the line').fire({});
    return {
        type: LINE_DELETE_FAILURE,
        payload: {error}
    }};


/**
 * delete multiple lines
 * @param {array} lines array of lines Ids
 * @returns
 */
export const deleteMultipleLines = (lines) => dispatch => {
    dispatch(request());
    return scadaInfraService.deleteMultipleLinesService(lines)
        .then(() =>
            // eslint-disable-next-line
            dispatch(lineMultipleDeleteSuccess(true, lines.lines.length), dispatch(getAllLine()),
                dispatch(getAllStations()),
                dispatch(getAllRails())))
        .catch((error) => dispatch(lineDeleteFailure(error), dispatch(getAllLine()), dispatch(getAllStations()), dispatch(getAllRails())));
}

/**
 * delete multiple lines - Redux Action
 * @param {boolean} success
 * @param {number} length
 * @returns
 */
export const lineMultipleDeleteSuccess = (success, length) => {

    if (length > 1) {
        swalSuccess('The lines are deleted succefully').fire({})
        return{
                type: LINE_MULTIPLE_DELETE_SUCCESS,
                payload: {success}
            }
    } else {
        swalSuccess('The line is deleted succefully').fire({})
        return {
                type: LINE_MULTIPLE_DELETE_SUCCESS,
                payload: {success}
            }
    }

};

/**
 * delete multiple lines - Redux Action
 * @param {boolean} error
 * @returns
 */
export const lineMultipleDeleteFailure = (error) => {
    // eslint-disable-next-line
    swalError('An error occured while deleting the lines').fire({});
    return {
        type: LINE_MULTIPLE_DELETE_FAILURE,
        payload: {error}
    }};

/**
 * Edit line by ID
 * @param {number} id
 * @param {json} data
 * @returns
 */
export const editLine = (id, data) => dispatch => {
    dispatch(request());
    return scadaInfraService.editLineService(id, data)
        .then(() => dispatch(lineEditSuccess(true), dispatch(getAllLine()), dispatch(getAllStations()), dispatch(getAllRails())))
        .catch((error) => dispatch(lineEditFailure(error)));
}

/**
 * Edit line by ID - Redux Action
 * @param {boolean} success
 * @returns
 */
export const lineEditSuccess = (success) => {
    // eslint-disable-next-line
    swalSuccess('The line is successfully updated').fire({});
       return {
            type: LINE_EDIT_SUCCESS,
            payload: {success}
        }};

/**
 * Edit line by ID - Redux Action
 * @param {boolean} error
 * @returns
 */
export const lineEditFailure = (error) => {
    // eslint-disable-next-line
    swalError('An error occured while updating the line').fire({});
    return {
        type: LINE_EDIT_FAILURE,
        payload: {error}
    }};

export const request = () => ({
    type: REQUEST
});

/**
 * ////////////////////////////////////////////////////////////
 *                  Scada-Infra Station Actions
 * ///////////////////////////////////////////////////////////
 **/

/**
 * Get list of All stations including rail section and MP
 *
 * @returns
 */
export const getAllStations = () => dispatch => {
    dispatch(request());
    return scadaInfraService.getAllStationsService()
        .then((json) => dispatch(receiveStation(json)));
}

/**
 * Get list of All stations including rail section and MP - Redux Action
 *@param {json} json
 * @returns
 */
export const receiveStation = (json) => ({
    type: RECEIVE_STATION,
    payload: {data: json}
});

/**
 * Get station detail by id
 * @param {number} stationId
 * @returns
 */
export const getStationById = (stationId) => dispatch => {
    dispatch(request());
    return scadaInfraService.getStationByIdService(stationId)
        .then((json) => dispatch(receiveStationById(json)));
}

/**
 * Get station detail by id
 * @param {json} json
 * @returns
 */
export const receiveStationById = (json) => ({
    type: RECEIVE_STATION_BY_ID,
    payload: {data: json}
});

/**
 * Add new station to scada Infra
 * @param {json} data
 * @returns
 */
export const postsStation = (data) => dispatch => {
    dispatch(request());
    return scadaInfraService.addStationService(data)
        .then(() => dispatch(stationPostSuccess(true), dispatch(getAllStations())))
        .catch((error) => dispatch(stationPostFailure(error)));
}

/**
 * Add new station success to scada Infra - Redux Action
 * @param {boolean} success
 * @returns
 */
export const stationPostSuccess = (success) => {
    // eslint-disable-next-line
    swalSuccess('The station is successfully created').fire({});
      return  {
            type: STATION_POST_SUCCESS,
            payload: {success}
        };}

/**
 * Add new station error to scada Infra - Redux Action
 * @param {boolean} error
 * @returns
 */
export const stationPostFailure = (error) => ({
    type: STATION_POST_FAILURE,
    payload: {error}
});

/**
 * Delete station by id
 * @param {number} id
 * @returns
 */
export const deleteStation = (id) => dispatch => {
    dispatch(request());
    return scadaInfraService.deleteStationService(id)
        .then(() => dispatch(stationDeleteSuccess(true), dispatch(getAllStations())))
        .catch((error) => dispatch(stationDeleteFailure(error)));
}

/**
 * Delete station by id success - Redux Action
 * @param {boolean} success
 * @returns
 */
export const stationDeleteSuccess = (success) => {
    // eslint-disable-next-line
    swalSuccess('The station is successfully deleted').fire({});
    return {
            type: STATION_DELETE_SUCCESS,
            payload: {success}
        }};

/**
 * Delete station by id error - Redux Action
 * @param {boolean} error
 * @returns
 */
export const stationDeleteFailure = (error) => ({
    type: STATION_DELETE_FAILURE,
    payload: {error}
});

/**
 * delete multiple stations
 * @param {array} stations array of stations Ids
 * @returns
 */
export const deleteMultipleStations = (stations) => dispatch => {
    dispatch(request());
    return scadaInfraService.deleteMultipleStationService(stations)
        .then(() => dispatch(stationMultipleDeleteSuccess(true, stations.stations.length), dispatch(getAllStations(), dispatch(getAllRails()))))
        .catch((error) => dispatch(stationMultipleDeleteFailure(error)));
}

/**
 * delete multiple stations success - Redux Action
 * @param {boolean} success
 * @param {number} length
 * @returns
 */
export const stationMultipleDeleteSuccess = (success, length) => {
    if (length > 1) {
        swalSuccess('The stations are successfully deleted').fire({})
        return {
                type: STATION_MULTIPLE_DELETE_SUCCESS,
                payload: {success}
            }
    } else {
        swalSuccess('The station is successfully deleted').fire({})
        return {
                type: STATION_MULTIPLE_DELETE_SUCCESS,
                payload: {success}
            }
    }
    // eslint-disable-next-line
};

/**
 * delete multiple stations error - Redux Action
 * @param {boolean} error
 * @returns
 */
export const stationMultipleDeleteFailure = (error) => ({
    type: STATION_MULTIPLE_DELETE_FAILURE,
    payload: {error}
});

/**
 * Edit station by ID
 * @param {number} id
 * @param {json} data
 * @returns
 */
export const editStation = (id, data) => dispatch => {
    dispatch(request());
    return scadaInfraService.editStationService(id, data)
        .then(() => dispatch(stationEditSuccess(true), dispatch(getAllStations())))
        .catch((error) => dispatch(stationEditFailure(error)));
}

/**
 * Edit station by ID success - Redux Action
 * @param {boolean} success
 * @returns
 */
export const stationEditSuccess = (success) => {
    // eslint-disable-next-line
    swalSuccess('The station is successfully updated').fire({})
        return  {
            type: STATION_EDIT_SUCCESS,
            payload: {success}
        }};

/**
 * Edit station by ID error - Redux Action
 * @param {boolean} error
 * @returns
 */
export const stationEditFailure = (error) => ({
    type: STATION_EDIT_FAILURE,
    payload: {error}
});

/**
 * ////////////////////////////////////////////////////////////
 *                  Scada-Infra Rail Services
 * ///////////////////////////////////////////////////////////
 **/

/**
 * Get list of All rails including stations and MP
 *
 * @returns
 */
export const getAllRails = () => dispatch => {
    dispatch(request());
    return scadaInfraService.getAllRailsService()
        .then((json) => dispatch(receiveRail(json)));
}

/**
 * Get list of All rails including stations and MP - Redux Action
 *
 * @returns
 */
export const receiveRail = (json) => ({
    type: RECEIVE_RAIL,
    payload: {data: json}
});

/**
 * Get rail detail by id including stations
 * @param {number} railId
 * @returns
 */
export const getRailbyId = (railId) => dispatch => {
    dispatch(request());
    return scadaInfraService.getRailByIdService(railId)
        .then((json) => dispatch(receiveRailById(json)));
}

/**
 * Get rail detail by id including stations
 * @param {json} json
 * @returns
 */
export const receiveRailById = (json) => ({
    type: RECEIVE_RAIL_BY_ID,
    payload: {data: json}
});

/**
 * Add new rail section to scada Infra
 * @param {json} data
 * @returns
 */
export const postRailSection = (data) => dispatch => {
    dispatch(request());
    return scadaInfraService.addRailSectionService(data)
        .then(() => dispatch(RailSectionPostSuccess(true), dispatch(getAllRails())))
        .catch((error) => dispatch(RailPostFailure(error)));
}

/**
 * Add new rail section to scada Infra success - Redux Action
 * @param {boolean} success
 * @returns
 */
export const RailSectionPostSuccess = (success) => {
    // eslint-disable-next-line
    swalSuccess('The Rail is successfully created').fire({});
        return {
            type: RAIL_SECTION_POST_SUCCESS,
            payload: {success}
        }};

/**
 * Add new rail section to scada Infra error - Redux Action
 * @param {boolean} error
 * @returns
 */
export const RailPostFailure = (error) => ({
    type: RAIL_SECTION_POST_FAILURE,
    payload: {error}
});

/**
 * Edit rail by ID
 * @param {number} id
 * @param {json} data
 * @returns
 */
export const editRail = (id, data) => dispatch => {
    dispatch(request());
    return scadaInfraService.editRailService(id, data)
        .then(() => dispatch(railEditSuccess(true), dispatch(getAllRails())))
        .catch((error) => dispatch(railEditFailure(error)));
}

/**
 * Edit rail by ID success - Redux Action
 * @param {boolean} success
 * @returns
 */
export const railEditSuccess = (success) => {
    // eslint-disable-next-line
    swalSuccess('The rail is successfully updated').fire({});
    return {
            type: RAIL_EDIT_SUCCESS,
            payload: {success}
        }};

/**
 * Edit rail by ID error - Redux Action
 * @param {boolean} error
 * @returns
 */
export const railEditFailure = (error) => ({
    type: RAIL_EDIT_FAILURE,
    payload: {error}
});

/**
 * delete rail by id
 * @param {array} id
 * @returns
 */
export const deleteRail = (id) => dispatch => {
    dispatch(request());
    return scadaInfraService.deleteRailService(id)
        .then(() => dispatch(railDeleteSuccess(true), dispatch(getAllRails()), getAllLine()), dispatch(getAllStations()))
        .catch((error) => dispatch(railDeleteFailure(error)));
}

/**
 * delete rail by id success
 * @param {boolean} success
 * @returns
 */
export const railDeleteSuccess = (success) => {
    // eslint-disable-next-line
    swalSuccess('The rail is successfully deleted').fire({});
        return{
            type: RAIL_DELETE_SUCCESS,
            payload: {success}
        }};

/**
 * delete rail by id error
 * @param {boolean} error
 * @returns
 */
export const railDeleteFailure = (error) => {
    // eslint-disable-next-line
    swalError('An error occured while deleting the rail').fire({});
    return {
        type: RAIL_DELETE_FAILURE,
        payload: {error}
    }};

/**
 * delete multiple rails
 * @param {array} rails array of rails Ids
 * @returns
 */
export const deleteMultipleRails = (rails) => dispatch => {
    dispatch(request());
    return scadaInfraService.deleteMultipleRailsService(rails)
        .then(() => dispatch(railMultipleDeleteSuccess(true, rails.rails.length), dispatch(getAllRails())))
        .catch((error) => dispatch((railsMultipleDeleteFailure(error), dispatch(getAllRails()))))
}

/**
 * delete multiple rails success - Redux Action
 * @param {boolean} success
 * @param {number} length
 * @returns
 */
export const railMultipleDeleteSuccess = (success, length) => {
    // eslint-disable-next-line
    if (length > 1) {
        swalSuccess('The rails are deleted succefully').fire({})
        return {
                type: RAIL_MULTIPLE_DELETE_SUCCESS,
                payload: {success}
            }
    } else {
        // eslint-disable-next-line
        swalSuccess('The rail is deleted succefully').fire({});
        return{
                type: RAIL_MULTIPLE_DELETE_SUCCESS,
                payload: {success}
            }
    }

};

/**
 * delete multiple rails error - Redux Action
 * @param {boolean} error
 * @returns
 */
export const railsMultipleDeleteFailure = (error) => {
    // eslint-disable-next-line
    swalError('An error occured while deleting the rails').fire({});
    return {
        type: RAIL_MULTIPLE_DELETE_FAILURE,
        payload: {error}
    }};
