import {
    SOFTWARE_INFO_FAIL,
    SOFTWARE_INFO_SUCCESS
} from "../constants/SoftwareInfoType";
import SoftwareInfo from "services/SoftwareInfo.service";

/**
 * Get software info
 * @returns {function(*): Promise<void>}
 */
export const getSoftwareInfo = () => (dispatch) => {
    return SoftwareInfo.getSoftwareInfo().then(
        (data) => {
            dispatch({
                type: SOFTWARE_INFO_SUCCESS,
                payload: {softwareInfo: data},
            });
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error?.response?.data?.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: SOFTWARE_INFO_FAIL,
                payload: message,
            });
            return Promise.reject(new Error(message));
        }
    );
};
