// Importing necessary modules and components
import { Form, TimePicker, Select, DatePicker, Checkbox } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import {
  getAllLines,
  getAllMeasurementPoints,
  getAllRailSections,
  getAllStations,
} from "redux/actions/DataAnalysis.action"; // Importing actions
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

const { Option } = Select;

// Definition of the LeftFilter functional component
const LeftFilter = (props) => {
  const timeFrameFormat = "HH:mm";
  const dispatch = useDispatch();

  // Retrieving data from Redux store
  const lines = useSelector((state) => state.dataAnalysis.lines);
  const stations = useSelector((state) => state.dataAnalysis.stations);
  const railSections = useSelector((state) => state.dataAnalysis.railSections);
  const measurementPoints = useSelector(
    (state) => state.dataAnalysis.measurementPoints
  );

  // Fetching data from server on component mount
  useEffect(() => {
    dispatch(getAllLines());
    dispatch(getAllStations());
    dispatch(getAllMeasurementPoints());
    dispatch(getAllRailSections());
    // eslint-disable-next-line
  }, []);

  // Handler for checkbox change
  function handleCheckBoxChange(e) {
    props.setHistoricalValue(e.target.checked);
  }

  // Rendering options for lines
  const renderLinesOptions = () => {
    return lines.map((line) => (
      <Option key={line.id} value={line.id}>
        {line.name}
      </Option>
    ));
  };

  // Rendering options for stations
  const renderStationOptions = () => {
    return stations.map((station) => (
      <Option key={station.id} value={station.id}>
        {station.name}
      </Option>
    ));
  };

  // Rendering options for measurement points
  const renderMeasurementPointOptions = () => {
    return measurementPoints.map((measurementPoint) => (
      <Option key={measurementPoint.id} value={measurementPoint.id}>
        {measurementPoint.device}
      </Option>
    ));
  };

  // Rendering options for rail sections
  const renderRailSectionOptions = () => {
    return railSections.map((railSection) => (
      <Option key={railSection.id} value={railSection.id}>
        {railSection.name}
      </Option>
    ));
  };

  // Return JSX representing the LeftFilter component
  return (
    <div className="left-filter">
      {/* Date range */}
      <Form.Item label="Date range" name={"range_start_date"}>
        <DatePicker />
      </Form.Item>

      <Form.Item name={"range_end_date"}>
        <DatePicker />
      </Form.Item>

      {/* Time frame */}
      <Form.Item label="Time frame" name={"start_time_frame"}>
        <TimePicker
          size="small"
          defaultValue={moment("00:00", timeFrameFormat)}
          format={timeFrameFormat}
        />
      </Form.Item>

      <Form.Item name={"end_time_frame"}>
        <TimePicker
          size="small"
          defaultValue={moment("00:00", timeFrameFormat)}
          format={timeFrameFormat}
        />
      </Form.Item>

      {/* Line */}
      <Form.Item label="Line" name={"line"}>
        <Select size="small" defaultValue="all">
          <Option key={"all"} value={"all"}>
            All
          </Option>
          {renderLinesOptions()}
        </Select>
      </Form.Item>

      {/* Station */}
      <Form.Item label="Station" name={"station"}>
        <Select size="small" defaultValue="all">
          <Option value="all">All</Option>
          {renderStationOptions()}
        </Select>
      </Form.Item>

      {/* Rail Section */}
      <Form.Item label="Rail Section" name={"rail_section"}>
        <Select size="small" defaultValue="all">
          <Option value="all">All</Option>
          {renderRailSectionOptions()}
        </Select>
      </Form.Item>

      {/* Measurement Point */}
      <Form.Item label="Measurement Point" name="measurement_point">
        <Select size="small" defaultValue="all">
          <Option value="all">All</Option>
          {renderMeasurementPointOptions()}
        </Select>
      </Form.Item>

      {/* Checkbox for Historical Values */}
      <Form.Item
        name="historical_values"
        initialValue={false}
        valuePropName="checked"
      >
        <Checkbox
          checked={props.historicalValue}
          onChange={handleCheckBoxChange}
        >
          Historical Values
        </Checkbox>
      </Form.Item>

      {/* Date pickers for historical value start and end dates */}
      <Form.Item name="historical_value_start_date">
        <DatePicker size="small" disabled={!props.historicalValue} />
      </Form.Item>

      <Form.Item name="historical_value_end_date">
        <DatePicker size="small" disabled={!props.historicalValue} />
      </Form.Item>
    </div>
  );
};
LeftFilter.propTypes = {
    historicalValue: PropTypes.bool,
    setHistoricalValue: PropTypes.func,
}
// Exporting the LeftFilter component as default
export default LeftFilter;
