import { Col } from "antd";
import { TableOutlined, WifiOutlined } from "@ant-design/icons";
import { useState } from "react";
// eslint-disable-next-line
import { io } from "socket.io-client";
// eslint-disable-next-line
import config from "../../utils/consts.config.json";
// eslint-disable-next-line
// const API_URL_WS = config.API_URL_WS;
// const socket = io.connect(API_URL_WS);

// SystemStatus component displays icons representing the status of communication and measurement points.
const SystemStatus = () => {
  // Communication status state and setter
  const [communicationStatus, setCommunicationStatus] = useState(false);
  // Measurement point status state and setter
  const [measurePointStatus, setMeasurePointStatus] = useState(false);
  // Measurement point status color state and setter
  const [measurePointStatusColor, setMeasurePointStatusColor] =
    useState("#00ff00");
  // Communication status color state and setter
  const [communicationStatusColor, setCommunicationStatusColor] =
    useState("#00ff00");

  // Socket event listener for status updates
  // eslint-disable-next-line
  // socket.on("update-status", data => {
  //     data = JSON.parse(data);
  //     setCommunicationStatus(data.communicationStatus);
  //     setMeasurePointStatus(data.measurePointStatus);
  //     if (measurePointStatus) setMeasurePointStatusColor("#00ff00");
  //     else setMeasurePointStatusColor("red");
  //     if (communicationStatus) setCommunicationStatusColor("#00ff00");
  //     else setCommunicationStatusColor("red");
  // });

  // Render icons based on current status and color
  return (
    <>
      <Col className="header-status-icon">
        <TableOutlined
          style={{ fontSize: 20, color: measurePointStatusColor }}
        />
      </Col>
      <Col className="header-status-icon">
        <WifiOutlined
          style={{ fontSize: 20, color: communicationStatusColor }}
        />
      </Col>
    </>
  );
};
export default SystemStatus;
