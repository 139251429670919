import {Button, Col, Row, Space, Tabs, Typography, Card} from 'antd';
import {useLocation, useParams,Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Device from "./Device/Device";
import MeasuringPointStatus from "./Status/MeasuringPointStatus";
import {Trans} from "react-i18next";
import MeasuringPointNew from "../New/MeasuringPointNew";
import MpReferenceSCMS from "./ReferenceSCMS/ReferenceSCMS";
import MpReferencePRAS from "./ReferencePRAS/referencePRAS";
import MpReading from "./Reading/Reading";
import Threshold from "./Threshold/Threshold";
import OperatingLog from "./OperatingLog/OperatingLog";
import {useDispatch, useSelector} from "react-redux";
import {getMeasuringPointById} from "redux/actions/MeasuringPoint.action";
import {padLeadingZeros} from "utils/function";

const {TabPane} = Tabs;
const {Text} = Typography;

const MeasuringPointEdit = () => {
    // eslint-disable-next-line
    const {id} = useParams();
    const {state} = useLocation();
    const {tabActive} = state || 1;
    const data = useSelector(state => state.measuringPoint.measuringPointID);
    const [activeTab, setActiveTab] = useState("1");
    const dispatch = useDispatch()
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        setActiveTab(String.valueOf(tabActive));
        dispatch(getMeasuringPointById(id));
        // eslint-disable-next-line
    }, [tabActive]);

    function changeTab(key) {
        setActiveTab(key);
    }

    return (
        <>
            <h1><Trans i18nKey="measuringPoints.edit.title"/></h1>
            <Card>
                <Row align="middle" className="measuring-point-top-bar">

                    <Col className="measuring-point-top-bar-action">
                        <Link
                            to={{pathname: `/scada/measuring-points`}}
                        >
                            <Button htmlType="button">
                                Return
                            </Button>
                        </Link>
                    </Col>

                    <Col className="measuring-point-top-bar-infos">
                        <Row justify="start">
                            <Space size={8}>
                                <Col>
                                    <Text>{data ? "MP " + padLeadingZeros(data.id, 4) + " |" : ''} </Text>
                                </Col>
                                <Col>
                                    <Text>{data ? (data.device_name + '_' + data.protocol_name + '_' + data.device_identification) + " |" : ''}</Text>
                                </Col>
                                <Col>
                                    <Text>{data ? data.installation_location : ''}</Text>
                                </Col>
                            </Space>
                        </Row>
                    </Col>
                </Row>
                <br/>
                <Tabs onChange={changeTab} type="card" activeKey={activeTab}>
                    <TabPane key="1" tab={<Trans i18nKey="measuringPoints.tabs.device"/>}>
                        <Device data={data}/>
                    </TabPane>
                    <TabPane key="2" tab={<Trans i18nKey="measuringPoints.tabs.status"/>}>
                        <MeasuringPointStatus/>
                    </TabPane>
                    <TabPane key="3" tab={<Trans i18nKey="measuringPoints.tabs.referenzSCMS"/>}>
                        <MpReferenceSCMS/>
                    </TabPane>
                    <TabPane key="4" tab={<Trans i18nKey="measuringPoints.tabs.referenzPRAS"/>}>
                        <MpReferencePRAS/>
                    </TabPane>
                    <TabPane key="5" tab={<Trans i18nKey="measuringPoints.tabs.reading"/>}>
                        <MpReading/>
                    </TabPane>
                    <TabPane key="6" tab={<Trans i18nKey="measuringPoints.tabs.threshold"/>}>
                        <Threshold/>
                    </TabPane>
                    <TabPane key="7" tab={<Trans i18nKey="measuringPoints.tabs.operatingLog"/>}>
                        <OperatingLog/>
                    </TabPane>
                </Tabs>

                <MeasuringPointNew
                    visible={visible}
                    setVisible={setVisible}
                />
            </Card>
        </>
    );
}

export default MeasuringPointEdit;
