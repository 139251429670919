// Importing necessary modules and components
import { Form, Input, Checkbox } from "antd";
import React from "react";

// Definition of the LevelsFilter functional component
const LevelsFilter = () => {
  return (
    <div className="pras-right-filter">
      {/* Container for the filter cards */}
      <div className="filter-card">
        {/* Title for threshold setting (Average value) */}
        <span className="filter-card-title">
          Threshold setting (Average value)
        </span>
        {/* Input field for Limit Threshold min */}
        <Form.Item
          name="average_limit_threshold_min"
          label="Limit Threshold min"
          labelAlign="left"
          initialValue={100}
        >
          <Input size="small" />
        </Form.Item>
        {/* Input field for Limit Threshold max */}
        <Form.Item
          name="average_limit_threshold_max"
          initialValue={100}
          label="Limit Threshold max"
          labelAlign="left"
        >
          <Input size="small" />
        </Form.Item>
        {/* Input field for In range */}
        <Form.Item
          name="average_in_range"
          initialValue={"100"}
          label="In range"
          labelAlign="left"
        >
          <Input size="small" />
        </Form.Item>
        {/* Checkbox for average value view */}
        <Form.Item name="average_value_view" valuePropName="checked">
          <Checkbox>View</Checkbox>
        </Form.Item>
      </div>
      {/* Second filter card for threshold setting (Reference) */}
      <div className="filter-card">
        {/* Title for threshold setting (Reference) */}
        <span className="filter-card-title">Threshold setting (Reference)</span>
        {/* Input field for Limit Threshold min */}
        <Form.Item
          name="reference_limit_threshold_min"
          initialValue={"100"}
          label=" Limit Threshold min"
          labelAlign="left"
        >
          <Input size="small" />
        </Form.Item>
        {/* Input field for Limit Threshold max */}
        <Form.Item
          name="reference_limit_threshold_max"
          initialValue={"100"}
          label="Limit Threshold max"
          labelAlign="left"
        >
          <Input size="small" />
        </Form.Item>
        {/* Checkbox for reference view */}
        <Form.Item name="reference_view" valuePropName="checked">
          <Checkbox>View</Checkbox>
        </Form.Item>
      </div>
      {/* Third filter card for Setting Analysis */}
      <div className="filter-card">
        {/* Title for Setting Analysis */}
        <span className="filter-card-title">Setting Analysis</span>
        {/* Input field for Min in Range */}
        <Form.Item
          name="setting_min_in_range"
          label="Min in Range"
          labelAlign="left"
          initialValue={"100"}
        >
          <Input size="small" />
        </Form.Item>
        {/* Checkbox for setting view */}
        <Form.Item name="setting_view" valuePropName="checked">
          <Checkbox>View</Checkbox>
        </Form.Item>
      </div>
      {/* Fourth filter card for Result Analysis */}
      <div className="filter-card">
        {/* Title for Result Analysis */}
        <span className="filter-card-title">Result Analysis</span>
        {/* Input field for Result */}
        <Form.Item
          name="result"
          initialValue={"99.93% > 85% = OK"}
          wrapperCol={{ span: 24 }}
        >
          <Input size="small" />
        </Form.Item>
        {/* Checkbox for result view */}
        <Form.Item name="result_view" valuePropName="checked">
          <Checkbox>View</Checkbox>
        </Form.Item>
      </div>
    </div>
  );
};

// Exporting the LevelsFilter component as default
export default LevelsFilter;
