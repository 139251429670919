/*
**************** IMPORTANT ********************************
*
* FOR ALL CHART COMPONENTS IN THIS PROJECT PLEASE READ OFFICIAL DOCUMENTATION 
* THESE FUNCTIONS ARE UNDER DEVELOPMENT AND NOT FINISHED YET
* TO_DO_: OPTIMIZE CODE + MAKE IT MORE MAINTAINABLE
*  
* docs: https://reactflow.dev/learn 
*
*/

import React, { useState, useRef, useCallback, useEffect } from "react";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  MiniMap,
  Background,
} from "react-flow-renderer";
import Sidebar from "./Sidebar";
import CustomEdge from "./CustomEdge";
import "./index.css";
import SelectorNodeType from "./SelectorNodeType";
import axios from "axios";
import { TagFilled } from "@ant-design/icons";
import { Row, Col } from "antd";
import "./chartFlow.less";
import { useDispatch, useSelector } from "react-redux";
import { getAllStations } from "redux/actions/SCADAInfra.action";
import { getAllRailsCharts } from "redux/actions/chartFlow.action";
import { swalSuccess } from "utils/swal";
import config from "../../utils/consts.config.json";

// API URL for fetching data
const API_URL = config.API_URL;

// Define custom node types
const nodeTypes = {
  selectorNode: SelectorNodeType,
};

// Define custom edge types
const edgeTypes = {
  custom: CustomEdge,
};

// Variable to track first time loading
let firstTime = true;

// ChartFlow component
const ChartFlow = () => {
  // Ref for the ReactFlow wrapper
  const reactFlowWrapper = useRef(null);
  // State and hooks for managing nodes and edges
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const dispatch = useDispatch();

  // Fetch all stations and rails charts data on component mount
  useEffect(() => {
    dispatch(getAllStations());
  }, []);

  // Effect to fetch rails charts data when nodes change
  useEffect(() => {
    if (nodes.length > 0) {
      const nodeelem = nodes?.map((node) => {
        if (typeof node.data.iddata !== "undefined") {
          return `{"id":${node.data.iddata}}`;
        } else {
          return `{"id":${null}}`;
        }
      });
      if (nodeelem.length > 0) {
        setNodesIdList(nodeelem);
        dispatch(getAllRailsCharts(nodesIdList));
      }
    }
  }, [...nodes.map((node) => node.data)]);

  const returnMeasuringPoints=(measuringPoints,station)=>{
    return measuringPoints?.map((measuringpoint) => {
      return {
        cc: nodes.find((node) => node.data.iddata === station.id),
        mp: measuringpoint,
      };
    });
  }
  const returnCc=(rail)=>{
    return rail.stations.map((station) => {
      if (rail.measuringPoints) {
        return returnMeasuringPoints(rail?.measuringPoints,station)
      } else
        return [
          {
            cc: nodes.find((node) => node.data.iddata === station.id),
            mp: {},
          },
        ];
    })
  }

  const returlMp=(rail)=>{
    return rail?.measuringPoints?.map((measuringpoint) => {
      return measuringpoint;
    });
  }
  // Add new rail section list edges
  const AddrailsSectionListEdge = () => {
    const edgeElement = { status: true };
    setEdges([]);
    let stationsReal = [];
    railsSectionList.map((rail) => {
      const cc = returnCc(rail)
      const mp = returlMp(rail)
      if (cc[0]?.[0].cc?.id && cc[1]?.[0].cc?.id) {
        stationsReal.push({
          first: cc[0]?.[0].cc?.id,
          second: cc[1]?.[0].cc?.id,
        });
        setEdges(
          (eds) =>
            addEdge(
              {
                source: cc[0]?.[0].cc?.id,
                target: cc[1]?.[0].cc?.id,
                status: edgeElement.status,
                type: "custom",
                data: { text: "custom edge", elements: { mp: mp, cc: cc } },
                style: edgeElement.status
                  ? { stroke: "black" }
                  : { stroke: "red" },
              },
              eds
            ),
          []
        );

        let occurrence = 0;
        stationsReal.map((item) => {
          if (
            item.first === cc[0]?.[0].cc?.id &&
            item.second === cc[1]?.[0].cc?.id
          ) {
            occurrence++;
          }
          return null
        });
        if (occurrence > 1) {
          setEdges(
            (eds) =>
              addEdge(
                {
                  source: cc[0]?.[0].cc?.id,
                  target: cc[1]?.[0].cc?.id,
                  status: edgeElement.status,
                  type: "custom",
                  data: {
                    text: "custom edge",
                    elements: { mp: mp },
                    left: true,
                  },
                  style: edgeElement.status
                    ? { stroke: "black" }
                    : { stroke: "red" },
                },
                eds
              ),
            []
          );
        }
      }
    });
  };

  // Selector for rails section list
  const railsSectionList = useSelector(
    (state) => state.chartFlow.chartFlowrails
  );
  const [nodesIdList, setNodesIdList] = useState([]);
  // Handle connect event between nodes
  const [idd, setIdd] = useState(null);
  // Handle drag over event
  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  // Handle node id change
  const onChange = (id, nodeId) => {
    setIdd(id);
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) {
          node.data = {
            ...node.data,
            iddata: id,
            id: id,
          };
        }
        return node;
      })
    );
  };

  // Fetch initial nodes and edges data from the API
  useEffect(() => {
    axios.get(API_URL + "nodearrays").then((response) => {
      const res = response.data;
      firstTime = !res.length || !res[0].nodesarray;
      if (res.length && res[0].nodesarray) {
        const x = res[0].nodesarray.map((nodeElement) => {
          const nodeObject = {
            id: nodeElement.id,
            type: "selectorNode",
            position: { x: nodeElement.position.x, y: nodeElement.position.y },
            data: {
              name: nodeElement.dataName,
              label: (
                <img
                  src={nodeElement.img}
                  alt="test"
                  height={"20px"}
                  width={"auto"}
                />
              ),
              status: 0,
              nodeId: nodeElement.nodeId,
              iddata: nodeElement.nodeIdData,
              onChange: onChange,
            },
          };
          return nodeObject;
        });
        setNodes(x);
      }
    });

    axios.get(API_URL + "edgesarrays").then((response) => {
      const res = response.data;
      if (res.length && res[0].edgesArray) {
        setEdges(res[0].edgesArray);
      }
    });
  }, []);

  // Helper functions to generate node and edge IDs
  const lastNodeId = nodes[nodes.length - 1]?.id;
  const lastNodeIdsubString = lastNodeId?.substring(
    lastNodeId.length,
    lastNodeId.indexOf("_") + 1
  );
  let converttoInt = parseInt(lastNodeIdsubString);
  const getLastId = () => {
    if (isNaN(converttoInt)) {
      return `dndnode_0`;
    } else {
      return `dndnode_${converttoInt + 1}`;
    }
  };

  // Handle drop event for adding new nodes
  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData("application/reactflow");
      let icon = event.dataTransfer.getData("application/icon");
      if (typeof type === "undefined" || !type) {
        return;
      }
      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      const nodeId = getLastId();
      const newNode = {
        id: nodeId,
        type: "selectorNode",
        position,
        data: {
          label: (
            <img
              src={`./assets/icons/${icon}.png`}
              alt="test"
              height={"20px"}
              width={"auto"}
            />
          ),
          status: 0,
          id: idd,
          iddata: idd,
          nodeId: nodeId,
          onChange: onChange,
        },
      };
      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance, idd, nodes]
  );

  // Save nodes and edges to the API
  const saveNode = () => {
    const nodesToAdd = nodes.map((node) => {
      return {
        id: node.id,
        nodeId: node.id,
        dataName: node.data.name,
        nodeIdData: node.data.iddata,
        position: {
          x: node.position.x,
          y: node.position.y,
        },
        type: "selectorNode",
        img: node.data.label.props.src,
      };
    });
    const NodeDataToAdd = { nodesarray: nodesToAdd };
    const edgesToAdd = { edgesArray: edges };
    if (firstTime) {
      axios
        .post(API_URL + "nodearrays", NodeDataToAdd)
        .then((response) => {
          if (response.data) {
            return response.data;
          }
          return response;
        })
        .then(
          axios.post(API_URL + "edgesarrays", edgesToAdd).then((response) => {
            if (response.data) {
              return response.data;
            }
            return response;
          })
        )
        .then(swalSuccess("The map is succefully updated").fire({}));
    } else {
      axios
        .patch(API_URL + "nodearrays/1", NodeDataToAdd)
        .then((response) => {
          if (response.data) {
            return response.data;
          }
          return response;
        })
        .then(
          axios
            .patch(API_URL + "edgesarrays/1", edgesToAdd)
            .then((response) => {
              if (response.data) {
                return response.data;
              }
              return response;
            })
        )
        .then(swalSuccess("The map is succefully updated").fire({}));
    }
  };

  return (
    <>
      <Row>
        <div className="dndflow">
          <ReactFlowProvider>
            <div className="reactflow-wrapper" ref={reactFlowWrapper}>
              <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onInit={setReactFlowInstance}
                onDrop={(e) => onDrop(e)}
                onDragOver={onDragOver}
                edgeTypes={edgeTypes}
                nodeTypes={nodeTypes}
                fitView
                connectionMode={"loose"}
                connectionLineType={"step"}
              >
                <Controls />
                <Background />
              </ReactFlow>
            </div>
            <Sidebar
              setEdges={setEdges}
              AddrailsSectionListEdge={AddrailsSectionListEdge}
              nodesIdList={nodesIdList}
              saveNode={saveNode}
            />
            <MiniMap />
          </ReactFlowProvider>
        </div>
      </Row>
      <Row justify="center">
        <Col span={2}>Legend :</Col>
        <Col span={3}>
          <TagFilled className="legend error-tag" /> Error
        </Col>
        <Col span={3}>
          <TagFilled className="legend ok-tag" /> OK
        </Col>
        <Col span={3}>
          <TagFilled className="legend warning-tag" /> Warning
        </Col>
        <Col span={3}>
          <TagFilled className="legend maintenance-tag" /> Maintenance
        </Col>
      </Row>
    </>
  );
};

export default ChartFlow;
