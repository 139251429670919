import {REQUEST} from "../constants/ActionTypes";
import logReportService from "services/LogReport.service";
import {LOG_REPORT_POST_SUCCESS, RECEIVE_LOG_REPORT} from "../constants/LogReportTypes";

export const receiveLogReport = (json) => ({
    type: RECEIVE_LOG_REPORT,
    payload: {data: json}
})

export const postsLogReport = data => dispatch => {
    dispatch(request());
    return logReportService.addLogReport(data)
        .then(() => dispatch(logReportPostSuccess(true)));
}

export const logReportPostSuccess = (json) => ({
    type: LOG_REPORT_POST_SUCCESS,
    data: json,
    payload: {postLogReport: true}
})

export const request = () => ({
    type: REQUEST
})

export const getLogReport = () => dispatch => {
    dispatch(request());
    return logReportService.getLogReport()
        .then((json) => dispatch(receiveLogReport(json)));
}
