import { Button, Col, Form, InputNumber, Row, Table, Typography } from 'antd';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getMeasuringPointRefPRAS,
    saveMeasuringPointRefPRAS,
    startMeasuringPointRefPRAS,
} from "redux/actions/MeasuringPoint.action";

import { useParams } from "react-router-dom";
import { Trans } from "react-i18next";
import Moment from 'moment';

const { Text } = Typography;

const MpReferencePRAS = () => {

    /**
     * Initial variable
     */
    const [componentSize, setComponentSize] = useState('default');
    const [form] = Form.useForm();
    const { id } = useParams();
    const { referencePRAS } = useSelector(state => state.measuringPoint);
    const dispatch = useDispatch();
    const [dataReference, setDataReference] = useState(referencePRAS);
    const columns = [
        {
            title: <Trans i18nKey="measuringPoints.referencePRAS.date" />,
            dataIndex: 'date',
            width: 100,
            key: 'date',
            render: (date) =>
                <Text>
                    {Moment(date).format('DD.MM.YYYY')}
                </Text>
        },
        {
            title: <Trans i18nKey="measuringPoints.referencePRAS.voltage" />,
            dataIndex: 'voltage',
            width: 100,
            key: 'voltage',
        },
        {
            title: <Trans i18nKey="measuringPoints.referencePRAS.current" />,
            dataIndex: 'current',
            width: 100,
            key: 'current'
        }
    ];

    /**
     * Functions
     */
    // eslint-disable-next-line
    useEffect(() => {
        if (id) {
            const initPage = () => {
                dispatch(getMeasuringPointRefPRAS(id));
            };
            initPage();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (referencePRAS) {
            setDataReference(referencePRAS);
        }
    }, [referencePRAS]);

    const onFormLayoutChange = ({ size }) => {
        setComponentSize(size);
    };

    function handleForm(values) {
        dispatch(saveMeasuringPointRefPRAS(id, values,form));
    }

    function handleStartReference() {
        dispatch(startMeasuringPointRefPRAS());
    }

    /**
     * DOM of this component
     */
    return (
        <div className='mp-status-ref-pras'>
            <Row>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="ant-col-padded">
                    <h3><Trans i18nKey="measuringPoints.referencePRAS.tableSubTitle" /></h3>
                    <Table
                        bordered
                        columns={columns}
                        dataSource={dataReference}
                        pagination={true}
                        rowKey="id"
                        /*scroll={{ y: 500 }}*/ />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="ant-col-padded">
                    <Row className="mp-status-ref-pras-start-col">
                        <Col xs={24} sm={24} md={16} lg={16} xl={16}>

                            <Row className='mp-status-ref-pras-start-btn-container'>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Button htmlType="button" onClick={handleStartReference}>
                                        <Trans i18nKey="measuringPoints.referencePRAS.startReferenceMeasurement" />
                                    </Button>
                                </Col>
                            </Row>

                            <Form
                                labelCol={{
                                    xs: { span: 24 },
                                    sm: { span: 24 },
                                    md: { span: 16 },
                                    lg: { span: 16 },
                                    xl: { span: 16 },
                                }}
                                wrapperCol={{
                                    xs: { span: 24 },
                                    sm: { span: 24 },
                                    md: { span: 8 },
                                    lg: { span: 8 },
                                    xl: { span: 8 },
                                }}

                                layout="horizontal"
                                initialValues={{
                                    size: componentSize,
                                }}
                                form={form}
                                onValuesChange={onFormLayoutChange}
                                onFinish={handleForm}
                                size={componentSize}>

                                <Form.Item
                                    label={<Trans i18nKey="measuringPoints.referencePRAS.current" />}
                                    labelAlign="left"
                                    name="current"
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: 'Please input the current value',
                                        },
                                    ]}>
                                    <InputNumber stringMode />
                                </Form.Item>

                                <Form.Item
                                    label={<Trans i18nKey="measuringPoints.referencePRAS.voltage" />}
                                    labelAlign="left"
                                    name="voltage"
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: 'Please input the voltage value',
                                        },
                                    ]}>
                                    <InputNumber stringMode />
                                </Form.Item>

                                <Form.Item>
                                    <Button htmlType="submit" type={"primary"}>
                                        <Trans i18nKey="measuringPoints.referencePRAS.saveAsReference" />
                                    </Button>
                                </Form.Item>

                            </Form>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

export default MpReferencePRAS;
