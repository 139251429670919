/*
**************** IMPORTANT ********************************
*
* FOR ALL CHART COMPONENTS IN THIS PROJECT PLEASE READ OFFICIAL DOCUMENTATION 
* THESE FUNCTIONS ARE UNDER DEVELOPMENT AND NOT FINISHED YET
* TO_DO_: OPTIMIZE CODE + MAKE IT MORE MAINTAINABLE
*  
* docs: https://reactflow.dev/learn 
*
*/

import { Button } from "antd";
import React from "react";
import ic_train from "../../assets/icons/ic_train.png";
import "./chartFlow.less";
import PropTypes from "prop-types";

// Define the Sidebar component
function Sidebar({ AddrailsSectionListEdge, nodesIdList, saveNode }) {
  // Function to handle drag start
  const onDragStart = (event, nodeType, icon) => {
    // Set data for drag and drop
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.setData("application/icon", icon);
    event.dataTransfer.effectAllowed = "move";
  };

  return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        {/* Element for dragging */}
        <button style={{all:'inherit'}}
          onDragStart={(event) =>
            onDragStart(event, "selectorNode", "ic_train")
          }
          draggable
        >
          <img src={ic_train} alt="test" width={50} height={50} />
        </button>
        {/* Buttons for updating rails and saving overview map */}
        <div style={{ marginTop: "10px" }}>
          <Button onClick={() => AddrailsSectionListEdge(nodesIdList)}>
            Update rails
          </Button>
          <Button onClick={() => saveNode()}>Save Overview Map</Button>
        </div>
      </div>
  );
}
Sidebar.propTypes = {
  AddrailsSectionListEdge: PropTypes.func,
  nodesIdList: PropTypes.array,
  saveNode: PropTypes.func,
}
export default Sidebar;
