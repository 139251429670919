import React, {useEffect, useState} from 'react';
import {
    Row, Col, Table, Button, Card, Typography
} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import MeasuringPointNew from "./New/MeasuringPointNew";
import {Trans} from "react-i18next";
import {deleteMeasuringPoint, getMeasuringPointsList} from "redux/actions/MeasuringPoint.action";
import {getAllLine} from 'redux/actions/SCADAInfra.action';
import {getAllSubstations} from 'redux/actions/SCADAEnergy.action';
import {swalDelete} from "utils/swal"
import EditStatus from "./Edit/EditStatus/EditStatus";
import {EditOutlined} from "@ant-design/icons";
import {padLeadingZeros, removeDuplicates} from "utils/function";
import i18n from "i18next";
const t = i18n.t;

const {Text} = Typography;

const MeasuringPointList = () => {
        const [modalNewVisible, setModalNewVisible] = useState(false);
        const showModalNew = () => {
            setModalNewVisible(true);
        }
        const [modalStatusVisible, setModalStatusVisible] = useState(false);
        const [dataMp, setDataMp] = useState();

        const showModalStatus = (record) => {
            setModalStatusVisible(true);
            if (record) setDataMp(record);
        }

        const {error} = useSelector(state => state.measuringPoint);
        const dispatch = useDispatch();

        const selectionType = 'checkbox';
        // eslint-disable-next-line
        const [selectedRow, setSelectedRow] = useState();
        const [selectedRows, setSelectedRows] = useState();

        useEffect(() => {
            const initPage = () => {
                dispatch(getMeasuringPointsList());
            };
            initPage();
            dispatch(getAllSubstations());
            dispatch(getAllLine());
            // eslint-disable-next-line
        }, []);

        useEffect(() => {
        }, [error]);
        const measurigpointList = useSelector(state => state.measuringPoint.measuringPoint);
        const columnsLine = [
            {
                title: <Trans i18nKey="measuringPoints.list.MP-ID"/>,
                dataIndex: 'id',
                width: 60,
                key: 'id',
                render: (id, record) => {
                    return (
                        <Link to={{pathname: `/scada/measuring-points/${id}`}}
                              state={{data: record, tabActive: "1"}}>
                            {padLeadingZeros(id, 4)}
                        </Link>
                    )
                }
            },
            {
                title: <Trans i18nKey="measuringPoints.list.line"/>,
                dataIndex: ['railSections', 'id'],
                width: 150,
                render: (id, record) => {
                    let lines = record.railSections ? record.railSections.map((r) => {
                        if (r.line) {
                            return r.line.name;
                        } else return '';
                    }) : [];
                    // remove duplicates lines
                    lines = [].concat(...lines);
                    lines = removeDuplicates(lines);
                    // remove list of line
                    return lines.map((l) => {
                        return <Text key={l}>{l}<br/></Text>;
                    });
                }
            },
            {
                title: <Trans i18nKey="measuringPoints.list.station"/>,
                dataIndex: ['railSections', 'id'],
                width: 150,
                render: (id, record) => {
                    let stations = record.railSections ? record.railSections.map((r) => {
                        if (r.stations) {
                            return r.stations.map((s) => {
                                return s.name;
                            });
                        } else return '';
                    }) : [];
                    // remove duplicates stations
                    stations = [].concat(...stations);
                    stations = removeDuplicates(stations);

                    return stations.map((s) => {
                        return <Text key={s}>{s}<br/></Text>;
                    });
                }
            },
            {
                title: <Trans i18nKey="measuringPoints.list.measuringPoint"/>,
                dataIndex: 'device_identification',
                width: 200,
                key: 'device_identification',
                render: (device_identification, record) =>
                    <Text>
                        {record ? (record.device_name + '_' + record.protocol_name + '_' + record.device_identification) : device_identification}
                    </Text>
            },
            {
                title: <Trans i18nKey="measuringPoints.list.device"/>,
                dataIndex: 'device_name',
                width: 150,
                key: 'device_name',
            },
            {
                title: <Trans i18nKey="measuringPoints.list.description"/>,
                dataIndex: 'device_description',
                width: 200,
                key: 'device_description',
            },
            {
                title: <Trans i18nKey="measuringPoints.list.techID"/>,
                dataIndex: 'tech_id',
                width: 150,
                key: 'tech_id',
            },
            {
                title: <Trans i18nKey="measuringPoints.list.maintenance"/>,
                dataIndex: 'maintenance',
                width: 150,
                key: 'maintenance',
            },
            {
                title: <Trans i18nKey="measuringPoints.list.status"/>,
                dataIndex: 'status',
                width: 150,
                key: 'status',
                render: (status, record) =>
                    <Row><Col span={20}>{status || 'Unknown'}</Col>
                        <Typography.Link data-testid='button-edit-status' onClick={() => showModalStatus(record)}>
                            <Col><EditOutlined/></Col>
                        </Typography.Link>
                    </Row>
            }
        ];

        const rowSelectionLine = {
            onChange: (selectedRowKeys, selectedRows) => {
                if (selectedRows.length > 0) {
                    setSelectedRow(selectedRows[0])
                    setSelectedRows(selectedRows);
                } else {
                    setSelectedRows();
                }
            },
            getCheckboxProps: (record) => ({
                disabled: record.name === 'Disabled User',
                // Column configuration not to be checked
                name: record.name,
            }),
        };

        const showConfirm = () => {
            if (selectedRows && selectedRows?.length > 1) {
                swalDelete(t('confirm.delete-measuring-points')).fire({})
                    .then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            selectedRows.map((measuringPointElement) => {
                                return (dispatch(deleteMeasuringPoint(measuringPointElement.id)), setSelectedRows());

                            })
                        }
                    })
            } else if (selectedRows && selectedRows?.length === 1) {
                swalDelete(t('confirm.delete-measuring-point')).fire({})
                    .then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            selectedRows.map((measuringPointElement) => {
                                return (dispatch(deleteMeasuringPoint(measuringPointElement.id)), setSelectedRows());

                            })
                        }
                    })
            }
        };

        return (
            <div className='measuring-points-list'>
                <h1><Trans i18nKey="measuringPoints.list.title"/></h1>
                <Card>
                    <Row className='measuring-points-list-top-bar'>
                        <Col>
                            <Button htmlType="button" data-testid='button-new' onClick={showModalNew}>
                                <Trans i18nKey="button.new"/>
                            </Button>
                        </Col>
                        <Col>
                            {selectedRows ? <Button htmlType="button" onClick={showConfirm}>
                                <Trans i18nKey="button.delete"/>
                            </Button> : <Button htmlType="button" onClick={showConfirm} disabled>
                                <Trans i18nKey="button.delete"/>
                            </Button>}
                        </Col>
                        <Col>
                            <Link to={{pathname: "/system/log-report"}}>
                                <Button htmlType="button">
                                    <Trans i18nKey="button.logReport"/>
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Table
                                rowSelection={{
                                    columnTitle: "Select",
                                    columnWidth: 40,
                                    type: selectionType,
                                    ...rowSelectionLine,
                                }}
                                bordered
                                columns={columnsLine}
                                dataSource={measurigpointList}
                                pagination={true}
                                scroll={{x: 800}}
                                rowKey='id'
                                className="measuring-points-list-table"/>
                        </Col>
                    </Row>
                    <MeasuringPointNew
                        visible={modalNewVisible}
                        setVisible={setModalNewVisible}
                    />
                    <EditStatus
                        visible={modalStatusVisible}
                        setVisible={setModalStatusVisible}
                        data={dataMp}
                    />
                </Card>
            </div>
        );
    }
;
export default MeasuringPointList;