import React, {useEffect, useState} from 'react';
import {Card, Tabs} from 'antd';
import {Trans} from "react-i18next";
import RailsDevices from "./RailsDevices/RailsDevices";
import RectifiersSubstations from "./RectifiersSubstations/RectifiersSubstations";
import {getMeasuringPointsMinimalList} from "redux/actions/MeasuringPoint.action";
import {useDispatch} from "react-redux";


const ScadaEnergy = () => {
    const [activeTab, setActiveTab] = useState("1");
    const {TabPane} = Tabs;
    const dispatch = useDispatch();

    useEffect(() => {
        let previousTab = activeTab;
        setActiveTab(previousTab);
    }, [activeTab]);

    function changeTab(key) {
        if (key === "1") dispatch(getMeasuringPointsMinimalList());
        if (key === "2") dispatch(getMeasuringPointsMinimalList('?filter=%7B%22where%22%3A%20%7B%22device_type%22%3A%22VLD%22%7D%7D'));
        setActiveTab(key);
    }

    return (
        <>
            <h1>SCADA-Energy</h1>
            <Card>

                <Tabs onChange={changeTab} type="card" activeKey={activeTab}>
                    <TabPane data-testid={'rail-device-tab'} key="1" tab={<Trans i18nKey="setup.ScadaEnergy.RailDevicesTabTitle"/>}>
                        <RailsDevices/>
                    </TabPane>
                    <TabPane  data-testid={'rectifiers-substations-tab'} key="2" tab={<Trans i18nKey="setup.ScadaEnergy.RectifiersSubstation"/>}>
                        <RectifiersSubstations/>
                    </TabPane>
                </Tabs>


            </Card>
        </>
    );
};
export default ScadaEnergy;
