import axios from "axios";
import config from "utils/consts.config.json";
import authHeader from "./AuthHeader";

const API_URL = config.API_URL;

/**
 * Add new measuring point
 * @param {*} data
 * @returns
 */
const addMeasuringPointService = (data) => {
    return axios
        .post(API_URL + `measuring-points`, data,{headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * Get measuring point by ID
 * @param {*} id
 * @returns
 */
const getMeasuringPointByIdService = (id) => {
    return axios
        .get(API_URL + `measuring-points/${id}?filter[include][0][relation]=refereceScms&filter[include][1][relation]=referencePras&filter[include][2][relation]=railSections`, {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * Get measuring points list
 * @returns
 */
const getMeasuringPointsListService = () => {
    return axios
        .get(API_URL + "measuring-points/?filter={\"include\": [{\"relation\": \"railSections\",\"scope\": {\"include\": [{\"relation\": \"line\"},{\"relation\": \"stations\"}]}}]}", {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * Get "Reference SCMS" list of a measuring point
 * @param {*} id
 * @returns
 */
const getMeasuringPointRefSCMSService = (id) => {
    return axios
        .get(`${API_URL}measuring-points/${id}/referece-scms`, {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * Save "Reference SCMS" of a measuring point
 * @param {*} id
 * @param {*} data
 * @returns
 */
const saveMeasuringPointRefSCMSService = (id, data) => {
    return axios
        .post(`${API_URL}measuring-points/${id}/referece-scms`, data, {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * Get "Reference PRAS" list of a measuring point
 * @param {*} id
 * @returns
 */
const getMeasuringPointRefPRASService = (id) => {
    return axios
        .get(`${API_URL}measuring-points/${id}/reference-pras`, {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * Save "Reference PRAS" of a measuring point
 * @param {*} id
 * @param {*} data
 * @returns
 */
const saveMeasuringPointRefPRASService = (id, data) => {
    return axios
        .post(`${API_URL}measuring-points/${id}/reference-pras`, data, {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * Get measuring point "Reading" list
 * @param {*} id
 * @returns
 */
const getMeasuringPointReadingService = (id) => {
    return axios
        .get(`${API_URL}measuringPoint/reading/${id}`, {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * Get measuring point "Threshold"
 * @param {*} id
 * @returns
 */
const getMeasuringPointThresholdService = (id) => {
    return axios
        .get(`${API_URL}measuringPoint/threshold/${id}`, {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * Get measuring point "OperatingLog" list
 * @param {*} id
 * @param {*} filter
 * @returns
 */
const getMeasuringPointOperatingLogService = (id, filter) => {
    return axios
        .get(`${API_URL}measuringPoint/operatingLog/${id}`, {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * Delete measuring point
 * @param {*} id
 * @returns
 */
const deleteMeasuringPointService = (id) => {
    return axios
        .delete(API_URL + `measuring-points/${id}`, {headers: authHeader()})
        .then((response) => {
            if (response.data) {

                return response.data;
            }
            return response;
        });
};

/**
 * Edit measuring point
 * @param {*} id
 * @param {*} data
 * @returns
 */
const editMeasuringPointService = (id, data) => {
    return axios
        .patch(API_URL + `measuring-points/${id}`, data, {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * Get measuring point minimal list
 * @param {*} filter
 * @returns
 */
const getMeasuringPointsMinimalListService = (filter) => {
    const suffix = filter || '';
    return axios
        .get(API_URL + "measuring-points" + suffix, {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * Get devices types from json folders
 * @returns
 */
const getDeviceTypesService = () => {
    return axios
        .get(API_URL + "measuring-points/all/device", {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * Get devices protocols from json folders
 * @returns
 */
const getDeviceProtocolsService = () => {
    return axios
        .get(API_URL + "measuring-points/all/protokoll", {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * Get device from json by its name
 * @param {*} name
 * @returns
 */
const getDeviceByNameService = (name) => {
    return axios
        .get(API_URL + `measuring-points/device/${name}`, {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * Get protocol from json by its name
 * @param {*} name
 * @returns
 */
const getProtocolByNameService = (name) => {
    return axios
        .get(API_URL + `measuring-points/protokoll/${name}`, {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * Get "Rectifiers Substations" list
 * @returns
 */
const getRectifiersSubstationsListService = () => {
    const suffix = '?filter=%7B%0A%20%20%20%20%22where%22%3A%20%7B%0A%20%20%20%20%20%20%20%20%22device_type%22%3A%20%22RSS%22%0A%20%20%20%20%7D%2C%0A%20%20%20%20%22include%22%3A%20%5B%7B%0A%20%20%20%20%20%20%20%20%22relation%22%3A%20%22railSections%22%2C%22scope%22%3A%7B%22include%22%3A%5B%7B%22relation%22%3A%22line%22%7D%2C%7B%22relation%22%3A%22stations%22%7D%5D%7D%0A%20%20%20%20%7D%5D%0A%7D'
    return axios
        .get(API_URL + "measuring-points" + suffix, {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

/**
 * Get "Rectifier Substation" by ID
 * @param {*} id
 * @returns
 */
const getRectifierSubstationByIdService = (id) => {
    const suffix = '?filter=%7B%22include%22%3A%20%5B%0A%20%20%20%20%7B%0A%20%20%20%20%20%20%22relation%22%3A%20%22measuringPoints%22%20%20%20%20%20%20%20%20%0A%20%20%20%20%7D%0A%20%20%5D%0A%7D'
    return axios
        .get(API_URL + `measuring-points/${id}/${suffix}`, {headers: authHeader()})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response;
        });
};

const measuringPointService = {
    getMeasuringPointsListService,
    getMeasuringPointRefSCMSService,
    saveMeasuringPointRefSCMSService,
    getMeasuringPointRefPRASService,
    saveMeasuringPointRefPRASService,
    getMeasuringPointReadingService,
    getMeasuringPointThresholdService,
    getMeasuringPointOperatingLogService,
    deleteMeasuringPointService,
    getMeasuringPointByIdService,
    editMeasuringPointService,
    addMeasuringPointService,
    getMeasuringPointsMinimalListService,
    getRectifiersSubstationsListService,
    getRectifierSubstationByIdService,
    getDeviceTypesService,
    getDeviceProtocolsService,
    getDeviceByNameService,
    getProtocolByNameService
};

export default measuringPointService;
