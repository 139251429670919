import React, {useCallback, useEffect, useState} from 'react';
import {Modal, Form, Input, Select, Checkbox, Row, Col, Tag, Typography} from 'antd';
import {Trans} from "react-i18next";
import {ApiFilled, RightSquareFilled, RightSquareOutlined, WifiOutlined} from "@ant-design/icons";
import {
    addMeasuringPoint,
    getDeviceTypes, getDeviceProtocols, getDeviceByName, getProtocolByName,
} from "redux/actions/MeasuringPoint.action";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";
const { TextArea } = Input;

const {Text} = Typography;

const MeasuringPointNew = (props) => {
    const {visible, setVisible} = props;
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const devices = useSelector(state => state.measuringPoint.devices);
    const [listDevice, setListDevice] = useState([]);
    const protokolls = useSelector(state => state.measuringPoint.protokolls);
    const [listProtokoll, setListProtokoll] = useState([]);
    const dataDevice = useSelector(state => state.measuringPoint.dataDevice);
    const dataProtocol = useSelector(state => state.measuringPoint.dataProtocol);
    const [device, setDevice] = useState({
        DeviceName: '',
        DeviceType: '',
        Version: '',
        SerialNumber: ''
    });

    // eslint-disable-next-line
    useEffect(() => {
        setVisible(false);
        dispatch(getDeviceTypes());
        dispatch(getDeviceProtocols());
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (devices) {
            const options = devices.map((element) => {
                return {
                    label: element.DeviceName,
                    id: element.folder,
                    value: element.folder,
                }
            });
            setListDevice(options);
        }
    }, [devices]);

    useEffect(() => {
        if (protokolls) {
            const options = protokolls.map((element) => {
                return {
                    label: element.ProtocolName,
                    id: element.folder,
                    value: element.folder,
                }
            });
            setListProtokoll(options);
        }
    }, [protokolls]);

    useEffect(() => {
        if (dataDevice) {
            form.setFieldsValue({device_name: dataDevice.DeviceName});
            form.setFieldsValue({device_type: dataDevice.DeviceType});
            form.setFieldsValue({device_version: dataDevice.Version});
            form.setFieldsValue({device_serial_number: dataDevice.SerialNumber});
            setDevice(dataDevice);
        }
    }, [form, dataDevice]);

    useEffect(() => {
        if (dataProtocol) {
            form.setFieldsValue({protocol_name: dataProtocol.ProtocolName});
        }
    }, [form, dataProtocol]);

    // eslint-disable-next-line
    const onSubmit = useCallback((values) => {
        dispatch(addMeasuringPoint(values));
        form.resetFields();
        setDevice({
            DeviceName: '',
            DeviceType: '',
            Version: '',
            SerialNumber: ''
        });
        setVisible(false);
    });
    // eslint-disable-next-line
    const closePopup = useCallback(() => {
        form.resetFields();
        setDevice({
            DeviceName: '',
            DeviceType: '',
            Version: '',
            SerialNumber: ''
        });
        setVisible(false);
    });

    const handleChangeDevice = (value, option) => {
        dispatch(getDeviceByName(option.id));
    }
    const handleChangeProtocol = (value, option) => {
        dispatch(getProtocolByName(option.id));
    }

    return (
        <Modal
            open={visible}
            title={<Trans i18nKey="measuringPoints.new.title"/>}
            okText="Save"
            onOk={form.submit}
            onCancel={closePopup}
            width={1000}>
            <Row gutter={16}>
                <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
                    <Form form={form}
                          labelCol={{
                              xs: {span: 24},
                              sm: {span: 24},
                              md: {span: 8},
                              lg: {span: 8},
                              xl: {span: 8}
                          }}
                          wrapperCol={{
                              xs: {span: 24},
                              sm: {span: 24},
                              md: {span: 14},
                              lg: {span: 14},
                              xl: {span: 14}
                          }}
                          onFinish={onSubmit}>
                        <Form.Item label={<Trans i18nKey="measuringPoints.new.device"/>}
                                   labelAlign="left"
                                   name="device"
                                   rules={[
                                       {
                                           required: true,
                                           message: 'Please select a device',
                                       },
                                   ]}>
                            <Select
                                placeholder="Select a device"
                                options={listDevice}
                                onSelect={handleChangeDevice}
                            />
                        </Form.Item>
                        <Form.Item hidden={true}
                                   label={<Trans i18nKey="measuringPoints.new.device_type"/>}
                                   labelAlign="left"
                                   name="device_type">
                            <Input/>
                        </Form.Item>
                        <Form.Item hidden={true}
                                   label={"Version"}
                                   labelAlign="left"
                                   name="device_version">
                            <Input/>
                        </Form.Item>
                        <Form.Item hidden={true}
                                   label={"SerialNumber"}
                                   labelAlign="left"
                                   name="device_serial_number">
                            <Input/>
                        </Form.Item>
                        <Form.Item hidden={true}
                                   label={"DeviceName"}
                                   labelAlign="left"
                                   name="device_name">
                            <Input/>
                        </Form.Item>
                        <Form.Item hidden={true}
                                   label={"ProtocolName"}
                                   labelAlign="left"
                                   name="protocol_name">
                            <Input/>
                        </Form.Item>
                        <Form.Item label={<Trans i18nKey="measuringPoints.new.device_protocol"/>}
                                   labelAlign="left"
                                   name="device_protocol" rules={[
                            {
                                required: true,
                                message: 'Please input the protocol',
                            },
                        ]}>
                            <Select
                                placeholder="Select a protocol"
                                options={listProtokoll}
                                onSelect={handleChangeProtocol}
                            />
                        </Form.Item>
                        <Form.Item label={<Trans i18nKey="measuringPoints.new.device_identification"/>}
                                   labelAlign="left"
                                   name="device_identification"
                                   rules={[
                                       {
                                           required: true,
                                           whitespace:true,
                                           message: 'Please input the identification',
                                       },
                                   ]}>
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label={<Trans i18nKey="measuringPoints.device.description"/>}
                            labelAlign="left"
                            name="device_description"
                            rules={[
                                {
                                    required: true,
                                    whitespace:true,
                                    message: 'Please input the description',
                                },
                            ]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label={<Trans i18nKey="measuringPoints.device.TechID"/>}
                            labelAlign="left"
                            name="tech_id"
                            rules={[
                                {
                                    required: true,
                                    whitespace:true,
                                    message: 'Please input the TechID',
                                },
                            ]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label={<Trans i18nKey="measuringPoints.device.maintenance"/>}
                            labelAlign="left"
                            name="maintenance"
                            rules={[
                                {
                                    required: true,
                                    whitespace:true,
                                    message: 'Please input the maintenance',
                                },
                            ]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            name="device_use_and_display"
                            valuePropName="checked"
                            className={"mp-checkbook-margin"}>
                            <Checkbox>
                                <Trans i18nKey="measuringPoints.device.useAndDisplay"/>
                            </Checkbox>
                        </Form.Item>

                        <Form.Item
                            name="collecting_measurement_data"
                            valuePropName="checked"
                            className={"mp-checkbook-margin"}>
                            <Checkbox size="small">
                                <Trans i18nKey="measuringPoints.device.collectingMeasurementData"/>
                            </Checkbox>
                        </Form.Item>

                        <Form.Item
                            name="collecting_status"
                            valuePropName="checked"
                            className={"mp-checkbook-margin"}>
                            <Checkbox>
                                <Trans i18nKey="measuringPoints.device.collectingStatus"/>
                            </Checkbox>
                        </Form.Item>

                        <Form.Item
                            name="keep_central_operating_log"
                            valuePropName="checked">
                            <Checkbox>
                                <Trans i18nKey="measuringPoints.device.keepCentralOperatingLog"/>
                            </Checkbox>
                        </Form.Item>
                        <Form.Item label={<Trans i18nKey="measuringPoints.new.installation_location"/>}
                                   labelAlign="left"
                                   name="installation_location"
                                   rules={[
                                       {
                                           required: true,
                                           whitespace:true,
                                           message: 'Please input the location',
                                       },
                                   ]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item label={<Trans i18nKey="measuringPoints.location.icon"/>}
                                   labelAlign="left"
                                   name="icon" rules={[
                            {
                                required: true,
                                message: 'Please select the icon',
                            },
                        ]}>
                            <Select>
                                <Select.Option value="1"><RightSquareFilled/></Select.Option>
                                <Select.Option value="2"><RightSquareOutlined/></Select.Option>
                                <Select.Option value="3"><ApiFilled/></Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="installation_location_use_and_display"
                            valuePropName="checked"
                            className={"mp-checkbook-margin"}>
                            <Checkbox>
                                <Trans i18nKey="measuringPoints.device.useAndDisplay"/>
                            </Checkbox>
                        </Form.Item>
                        <Form.Item
                            wrapperCol={{
                                xs: {span: 24},
                                sm: {span: 24},
                                md: {span: 24},
                                lg: {span: 22},
                                xl: {span: 22}
                            }}
                            labelCol={{span: 24}}
                            label={<Trans i18nKey="measuringPoints.location.specialNotes"/>}
                            labelAlign="left"
                            name="note">
                            <TextArea rows={3}/>
                        </Form.Item>
                    </Form>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                    <Row gutter={16}>
                        <Col>
                            <Tag color="default">{device?.DeviceType||'-'}</Tag>
                        </Col>

                        <Col><Text>Version</Text></Col>

                        <Col>
                            <Tag color="default">{device?.Version||'-'}</Tag>
                        </Col>

                        <Col>
                            <WifiOutlined style={{fontSize: 20, color: "#52c41a"}}/>
                        </Col>
                    </Row>
                    <br/>
                    <Row gutter={16}>
                        <Col>
                            <Text><Trans i18nKey="measuringPoints.device.serialNumber"/></Text>
                        </Col>
                        <Col>
                            <Text>{device?.SerialNumber}</Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Modal>
    );
}
MeasuringPointNew.propTypes = {
    visible: PropTypes.bool.isRequired,
    setVisible: PropTypes.func.isRequired
};
export default MeasuringPointNew;
